import { authProvider } from "../providers/authProvider";
import { dataProvider } from "../providers/dataProvider";
import { i18nProvider } from "../providers/i18nProvider";
import queryClient from "../providers/queryConfig";
import { CustomLayout } from "../layouts/CustomLayout";
import Login from "../components/CustomLogin";
import { customTheme } from '../themes/customTheme';

const adminProps = {
  layout: CustomLayout,
  loginPage: Login,
  dataProvider,
  authProvider,
  queryClient,
  i18nProvider,
  theme: customTheme,
}

export default adminProps;
