import { Add } from "@mui/icons-material";
import { Button, Typography, Box } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { Confirm, useRecordContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { DialogCollaborators } from "./DialogCollaborators";
import { TableCollaborators } from "./TableCollaborators";

const useStyles = makeStyles({
  title: {
    fontWeight: "500!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
  },
});

export const Collaborators = () => {
  const classes = useStyles();
  const { veterinary_fees, status } = useRecordContext();
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { setValue } = useFormContext();
  const total = useWatch({name:'total'});

  useEffect(()=>{
    let copy = [];
    if (total > 0 && values){
      values?.forEach((item)=>{
        let res = total * (item?.percentage / 100);
        item.amount = res?.toFixed(2);
        copy.push(item);
      });
      setData(copy);
      setValue("veterinary_fees", copy);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[total])

  useEffect(()=>{
    if (veterinary_fees){
      setValues(veterinary_fees);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  },[veterinary_fees])



  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  const handleSave = (newitem) => {
    let copy = [...values];
    if (index != null) {
      copy[index] = newitem;
    } else {
      copy.push(newitem);
    }
    setValues(copy);
    setValue("veterinary_fees", copy);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    setData(values[index]);
    setIndex(index);
    setOpen(true);
  };
  const handleDelete = () => {
    let copy = [...values];
    copy.splice(index, 1);
    setValues(copy);
    setIndex(null);
    setOpenConfirm(false);
    handleClose();
    setValue("veterinary_fees", copy);
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <Box display="flex" mb="16px" mt="32px">
        <Typography className={classes.title} sx={{ marginRight: "24px" }}>
          Colaboradores
        </Typography>
        {status == "en_proceso" && <Button
          variant="outlined"
          size="small"
          sx={{ fontWeight: 500, fontSize: "12.3px", lineHeight: "14px" }}
          startIcon={<Add />}
          onClick={() => setOpen(true)}
        >
          Agregar Colaborador
        </Button>}
      </Box>
      <TableCollaborators
        rows={values}
        handleEdit={handleEdit}
        handleConfirm={handleConfirm}
      />
      <DialogCollaborators
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
      />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el colaborador?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};
