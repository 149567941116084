import { useEffect, useState } from "react";
import {
  Typography,
  Card,
  Button,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  Create,
  Form,
  Labeled,
  Loading,
  SimpleShowLayout,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
} from "react-admin";
import { dataProvider } from "../../../providers/dataProvider";
import { Close, Edit, Save } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { PopUpPassword } from "../../users/PopUpPassword";
import { validateCUITCUIL } from "../../../utils/Validations";
import CustomNumberInput from "../../../components/CustomNumberInput";

const useStyles = makeStyles({
  input: {
    maxWidth: "220px",
  },
  inputLong: {
    maxWidth: "300px",
  },
  create: {
    "& .RaCreate-main.RaCreate-noActions": {
      marginTop: 0,
    },
  },
});

const Header = ({ record, setEdit }) => {
  return (
    <Box mb="1.5em">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1" sx={{ fontSize: "2em", fontWeight: 700 }}>
          {record?.user?.name}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<Edit />}
          onClick={() => setEdit(true)}
        >
          Editar
        </Button>
      </Box>
      <Typography sx={{ color: "#5D5FEF", fontSize: "1.5em" }}>
        Cuit {record?.cuil_cuit}
      </Typography>
    </Box>
  );
};

const CustomToolbar = ({ setEdit }) => {
  return (
    <Toolbar
      sx={{
        padding: "16px 24px",
        boxSizing: "border-box",
        background: "#F0F0F0",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button startIcon={<Close />} onClick={() => setEdit(false)}>
        Cancelar
      </Button>
      <Button type="submit" variant="contained" startIcon={<Save />}>Guardar</Button>
    </Toolbar>
  );
};

const cuilCuitErrorMessage = "El cuil/cuit ingresado no es correcto";

const validate = (values) =>{
  let errors = {};
  if (values.cuil_cuit.length < 11) 
    errors.cuil_cuit = "Los códigos deben tener al menos 11 dígitos";
  else if (values.cuil_cuit && !validateCUITCUIL(values.cuil_cuit)){
    errors.cuil_cuit = cuilCuitErrorMessage;
  }
  if (values?.password?.length > 6){
    errors.password = "la longitud de la contraseña debe ser de al menos 6 caracteres";
  }
  return errors;
}

export const SettingsShow = () => {
  const classes = useStyles();
  const [record, setRecord] = useState();
  const [edit, setEdit] = useState();
  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  async function getRecord() {
    dataProvider.getSettings().then(({ data: res }) => setRecord(res));
  }
  const postSave = (data) => {
    create(
      "settings",
      { data },
      {
        onSuccess: (data) => {
          notify("ra.resources.settings.create", { type: "info" });
          setEdit(false);
          setRecord(data);
          refresh();
        },
        onError:(data) =>{
          notify(data?.message, { type: "error" });
        }
      }
    );
  };

  useEffect(() => {
    getRecord();
  }, []);


  if (!record) return <Loading />;
  return (
    <Card>
      {!edit ? (
        <SimpleShowLayout record={record}>
          <Header record={record} setEdit={setEdit} />
          <Typography
            sx={{
              color: "#343434",
              fontSize: "1.2em",
              fontWeight: 600,
            }}
          >
            Mis datos
          </Typography>
          <Box display="flex">
            <Labeled label="TELÉFONO" sx={{ marginRight: "3em" }}>
              <TextField source="user.phone" />
            </Labeled>
            <Labeled label="EMAIL" sx={{ marginRight: "3em" }}>
              <TextField source="user.email" />
            </Labeled>
            <Labeled label="DIRECCIÓN">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  lineHeight: "1.43",
                }}
              >
                {record?.address_name} {record?.address_number}{" "}
                {record?.address_floor} {record?.address_door},{" "}
                {record?.location}
              </Typography>
            </Labeled>
          </Box>
        </SimpleShowLayout>
      ) : (
        <Create
          title=" "
          component="div"
          sx={{ flexDirection: "column" }}
          className={classes.create}
          validate={validate}
        >
          <Form title=" " record={record} onSubmit={postSave}>
            <Box padding="24px">
              <Typography
                variant="h6"
                sx={{
                  color: "#343434",
                  fontSize: "1.4em",
                  marginBottom: "1em",
                  fontWeight: 600,
                }}
              >
                Editar
              </Typography>
              <Box display="flex" flexDirection="column">
                <TextInput
                  source="user.name"
                  label="Nombre y Apellido"
                  variant="filled"
                  className={classes.inputLong}
                />
                <TextInput
                  source="cuil_cuit"
                  label="CUIL/CUIT"
                  className={classes.input}
                  variant="filled"
                  helperText={"Solo números"}
                />
                <Typography
                  sx={{
                    color: "#343434",
                    fontSize: "1.2em",
                    marginTop: "1em",
                    marginBottom: ".8em",
                    fontWeight: 600,
                  }}
                >
                  Mis datos
                </Typography>
                <TextInput
                  source="user.email"
                  label="Email"
                  variant="filled"
                  className={classes.input}
                />
                <CustomNumberInput
                  source="user.phone"
                  label="Teléfono"
                  variant="filled"
                  className={classes.input}
                />
                <Box display="flex">
                  <TextInput
                    source="address_name"
                    label="Calle"
                    variant="filled"
                    sx={{ marginRight: "2em" }}
                    className={classes.input}
                  />
                  <CustomNumberInput
                    source="address_number"
                    label="N°"
                    variant="filled"
                    sx={{ marginRight: "2em", maxWidth: "100px" }}
                  />
                  <TextInput
                    source="address_floor"
                    label="Piso"
                    variant="filled"
                    sx={{ marginRight: "2em", maxWidth: "80px" }}
                  />
                  <TextInput
                    source="address_door"
                    label="Dpto."
                    variant="filled"
                    sx={{ maxWidth: "80px" }}
                  />
                </Box>
                <TextInput
                  source="location"
                  label="Localidad"
                  variant="filled"
                  className={classes.input}
                  sx={{ marginBottom: "1em" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "#343434",
                  fontSize: "1.2em",
                  marginTop: "1em",
                  marginBottom: ".8em",
                  fontWeight: 600,
                }}
              >
                Contraseñas
              </Typography>
              <PopUpPassword />
            </Box>
            <CustomToolbar setEdit={setEdit} />
          </Form>
        </Create>
      )}
    </Card>
  );
};
