import * as React from "react";
import { createElement } from "react";
import { Card, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { customPalette } from "../../themes/customTheme";

const useStyles = makeStyles({
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: "1",
    "& a": {
      textDecoration: "none",
    },
  },
  main:{
    overflow: "inherit",
    padding: "16px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      fontSize: '0.9em',
      color: `${customPalette.main}`,
    },
  },
  title: {
    color: "#818181",
    fontSize: "1.2em",
  },
  subtitleWarning: {
    color: "#E59800",
    "&:hover": {
      color: "#E59800",
    },
  },
  subtitleNormal: {
    color: "#343434",
    "&:hover": {
      color: "#343434",
    },
  },
  link: {
   textDecoration:'none'
  },
});

const CardWithIcon = ({
  icon,
  title,
  subtitle ="",
  to,
  color,
  description = "",
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <Link to={to} className={classes.link}>
      <Card className={classes.card}>
        <div className={classes.main}>
          <Box p="8px" className="icon" display="flex" alignSelf="flex-start">
            {createElement(icon, { sx: { fontSize: "50px" } })}
          </Box>
          <Box textAlign="right">
            <Typography className={classes.title} color="textSecondary">
              {title}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontSize: "1.6em",
                fontWeight: "600",
                marginBottom: "0.4em",
              }}
              className={
                color ? classes.subtitleWarning : classes.subtitleNormal
              }
            >
              {subtitle}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "18px",
                color: "#BFBFBF"
              }}
            >
              {description}
            </Typography>
          </Box>
        </div>
      </Card>
    </Link>
  );
};

export default CardWithIcon;
