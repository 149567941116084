import React, { Fragment } from "react";
import logo from "../../assets/logo_export.png";
import { makeStyles } from "@mui/styles";
import { Labeled } from "react-admin";
import { Typography, Box } from "@mui/material";
import { customPalette } from "../../themes/customTheme";
import { TablePayments } from "./TablePayments";
import { formatter } from "../../utils/NumberFormatter";

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "32px",
    boxSizing: "border-box",
    textAlign: "left",
    position: "relative",
  },
  logo: {
    with: "100%",
    display: " flex",
    justifyContent: "center",
    marginTop: "8px",
    marginBottom: "16px",
    "& img": {
      width: "80px",
      Height: "auto",
    },
  },
  title: {
    color: `${customPalette.main}`,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    marginTop: "16px",
  },
  subtitleText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#232323",
    marginTop: "8px",
  },
  cuil: {
    fontSize: "12px",
    fonstWeight: 400,
    lineHeight: "12px",
    marginTop: "3px",
    marginBottom: "16px",
    color: "#343434",
  },
  total: {
    color: "#343434!important",
    fontWeight: "700!important",
    fontSize: "14px!important",
    lineHeight: "18px!important",
  },
  text: {
    fontSize: "12px!important",
    fontWeight: "400!important",
    lineHeight: "14px!important",
    color: "#000000!important",
  },
  subtitle: {
    fontSize: "12px!important",
    lineHeight: "16px!important",
    fontWeight: "500!important",
    margin: "16px 0 8px 0!important",
  },
  observations: {
    color: "#818181!important",
    maxWidth: "650px!important",
    fontSize: "14px!important",
    fontWeight: "400!important",
    lineHeight: "20px!important",
  },
});

const Client = ({ data }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <span className={classes.title}>CLIENTE</span>
      <Box marginBottom="8px" display="flex" flexDirection="column">
        <span className={classes.subtitleText}>{data?.name || ""}</span>
        <span className={classes.cuil} style={{marginBottom: "8px"}}>{data?.cuil_cuit || ""}</span>
        <span className={classes.subtitleText} style={{marginBottom: "8px"}}>Estado actual: ${formatter.format(data?.current_account || 0)}</span>
      </Box>
    </Box>
  );
};

const Veterinarian = ({ veterinarian, setting }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <span className={classes.title}>VETERINARIO</span>
      <Box display="flex" flexDirection="column">
        <span className={classes.subtitleText}>{veterinarian?.name || ""}</span>
        <span className={classes.cuil}>Cuit {setting?.cuil_cuit || ""}</span>
      </Box>
      <Box display="flex" borderBottom="1px solid #C4C4C4" maxWidth="650px">
        <Labeled
          label="TELÉFONO"
          sx={{
            marginBottom: "16px",
            marginRight: "24px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {setting?.user?.phone || ""}
          </Typography>
        </Labeled>
        <Labeled
          label="EMAIL"
          sx={{
            marginBottom: "16px",
            marginRight: "24px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {setting?.user?.email || ""}
          </Typography>
        </Labeled>
        <Labeled
          label="DIRECCIÓN"
          sx={{
            marginBottom: "16px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {setting?.address_name || ""} {setting?.address_number || ""}{" "}
            {setting?.address_floor || ""} {setting?.address_door || ""},
            {setting?.location || ""}
          </Typography>
        </Labeled>
      </Box>
    </Fragment>
  );
};


const PrintComponentClient = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { veterinarian, setting, data, payments } = props;
  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.logo}>
        <img src={logo} alt="LOGO GESVET" />
      </div>
      <Veterinarian veterinarian={veterinarian} setting={setting} />
      <Client data={data}/>
      <span className={classes.title}>DETALLE</span>
      <TablePayments rows={payments}/>
      <Typography className={classes.subtitle}>Observaciones</Typography>
      <Typography className={classes.observations}>
        {data?.observations || ""}
      </Typography>
    </div>
  );
});
export default PrintComponentClient;
