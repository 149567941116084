import {
  Button,
  Typography,
  Dialog,
  IconButton,
  Box,
  Toolbar as MuiToolbar,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Save from "@mui/icons-material/Save";
import { useState } from "react";
import { useRedirect, useNotify, useCreate, useUpdate } from "react-admin";
import { customPalette } from "../../themes/customTheme";
import { useWatch } from "react-hook-form";
import CustomNumberInput from "../../components/CustomNumberInput";

const transform = (data, overdue) => {
  return { ...data, closeJob: true, status: "no_cobrado", overdue_in: overdue };
};

export const SaveAndCloseButton = ({
  label = "Cerrar Trabajo",
  variant = "contained",
  size = "large",
  isEdit = false,
  disabledByForm = false
}) => {
  const formvalues = useWatch();
  const [open, setOpen] = useState(false);
  const [overdue, setOverdue] = useState(30);
  const [loading, setLoading] = useState(false);
  const [create] = useCreate();
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleClose = () => {
    setOpen(false);
  };
  const saveAndClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    let body = transform(formvalues, overdue);
    if (!isEdit) {
      create(
        "jobs",
        { data: body },
        {
          onSuccess: () => {
            notify("ra.resources.jobs.create", { type: "info" });
            redirect("/jobs");
          },
          onError: (e) => {
            notify(e?.message, { type: "error" });
            setLoading(false);
          },
        }
      );
    } else {
      update(
        "jobs",
        { id: formvalues.id, data: body },
        {
          onSuccess: () => {
            notify("ra.resources.jobs.edit", { type: "info" });
            redirect("/jobs");
          },
          onError: (e) => {
            notify(e?.message, { type: "error" });
            setLoading(false);
          },
        }
      );
    }
  };
  const handleChange = event => {
    setOverdue(event.target.value);
  };

  const color = variant == "outlined" ? customPalette.main : "white";
  return (
    <>
      <Button
        sx={{ color: `${color}`, marginRight: "1em" }}
        variant={variant}
        onClick={() => setOpen(true)}
        startIcon={<Save />}
        disabled={disabledByForm}
      >
        {label}
      </Button>
      <Dialog open={open} maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
          minWidth="300px"
          sx={{
            padding: "16px 16px 0 16px",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Fecha estimada de cobro
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              sx={{ padding: 0 }}
              aria-label="close"
              disabled={loading}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="flex-start" width="100%" mt="1em">
            <FormControl fullWidth>
              <CustomNumberInput
                source="overdue-select"
                label="Plazo en dias"
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <MuiToolbar
          sx={{
            background: "#F0F0F0",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={(e) => saveAndClose(e)}
            size={size}
            sx={{ "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 } }}
            disabled={loading}
          >
            Guardar
          </Button>
        </MuiToolbar>
      </Dialog>
    </>
  );
};
