import { Fragment, useState } from "react";
import {
  EditButton,
  Show,
  SimpleShowLayout,
  useRecordContext,
  TextField,
  DateField,
  Labeled,
  SelectField,
  FunctionField,
} from "react-admin";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import { ShowEstablishment } from "../ShowEstablishment";
import { customPalette } from "../../../themes/customTheme";
import { FISCALCONDITION, PAYMENTMETHOD } from "../../constants";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import LaunchIcon from "@mui/icons-material/Launch";
import moment from "moment";
import { formatter } from "../../../utils/NumberFormatter";
import { Link as LinkRouter } from "react-router-dom";
import { useEffect } from "react";
import { dataProvider } from "../../../providers/dataProvider";
import PrintClient from "../Print";

const useStyles = makeStyles({
  text: {
    fontWeight: "600!important",
  },
  grid:{
    display: "grid",
    gridTemplateColumns: 'repeat(3,1fr)',
    GridTemplateAreas: '. . .',
    gap:'1em',
  }
});

const Cards = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(null);
  const record = useRecordContext();

  const handleOpen = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setData(record.establishments[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  return (
    <Fragment>
      <Grid container spacing={2}>
        {record?.establishments?.length > 0 &&
          record?.establishments?.map((item, index) => (
            <Grid item key={index}>
              <Card
                onClick={(e) => handleOpen(e, index)}
                sx={{
                  width: "100%",
                  minWidth: "300px",
                  margin: ".5em 0",
                  "&:hover": { cursor: "pointer" },
                }}
                elevation={2}
              >
                <CardContent>
                  <Typography
                    sx={{
                      color: `${customPalette.main}`,
                      fontSize: "1.2em",
                      fontWeight: 500,
                    }}
                  >
                    {item?.name}
                    {item?.hectares ? ` - ${item.hectares}Hc` : ""}
                  </Typography>
                  <Typography sx={{ color: "#000", fontWeight: 500 }}>
                    {item?.location}
                    {item?.distance ? ` - ${item.distance} km` : ""}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      <ShowEstablishment
        open={open}
        index={index}
        data={data}
        handleClose={handleClose}
      />
    </Fragment>
  );
};

const columns = [
  {
    field: "date",
    headerName: "Fecha",
    width: 140,
    type: "date",
    valueGetter: (params) =>
      `${moment(params.row.date).format("DD/MM/YYYY") || ""}`,
  },
  {
    field: "concept",
    headerName: "Concepto",
    width: 120,
    valueGetter: (params) =>
      `${
        params.row.concept == "job"
          ? "Trabajo"
          : params.row.concept == "payment"
          ? "Cobro"
          : "Resto Cobro"
      }`,
  },
  {
    field: "payment_method",
    headerName: "Forma pago",
    width: 180,
    valueGetter: (params) =>
      `${
        PAYMENTMETHOD.find((i) => i.id == params.row?.payment_method)?.name ||
        ""
      }`,
  },
  {
    field: "amount",
    headerName: "Monto",
    width: 120,
    valueGetter: (params) =>
      `$${params.row.concept == "job" ? "" : "-"}${formatter.format(
        params.row.amount || 0
      )}`,
  },
  {
    field: "subtotal",
    headerName: "Subtotal",
    width: 120,
    valueGetter: (params) => `$${formatter.format(params.row.subtotal || 0)}`,
  },
  {
    field: "actions",
    headerName: " ",
    sortable: false,
    width: 90,
    renderCell: (params) => {
      const url =
        params.row.concept == "job"
          ? `/jobs/${params.row.job_id}/show`
          : {
              pathname: "/payments",
              search: `filter=${JSON.stringify({
                ids: [params.row.payment_id],
              })}`,
            };
      return (
        <Button component={LinkRouter} to={url}>
          <LaunchIcon />
        </Button>
      );
    },
  },
];
const Payments = ({ payments }) => {
  const record = useRecordContext();

  if (!payments) return null;
  return (
    <Fragment>
      <Box
        maxWidth="800px"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{ fontWeight: 400, fontSize: "20px", lineheight: "24px" }}
        >
          Cuenta Corriente
        </Typography>
        <Button
          component={LinkRouter}
          to={`/payments/create`}
          state={{ client_id: record?.id }}
          variant="outlined"
        >
          Nuevo Cobro
        </Button>
      </Box>
      <Box
        sx={{
          height: 400,
          width: "100%",
          maxWidth: "800px",
          marginTop: "16px",
          marginBottom: "32px",
        }}
      >
        <DataGrid
          rows={payments}
          columns={columns}
          pageSize={50}
          density="compact"
          rowsPerPageOptions={[25, 50, 75, 100]}
          disableSelectionOnClick={true}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#FBFBFF",
              color: `${customPalette.main}`,
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600!important",
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
            },
          }}
        />
      </Box>
    </Fragment>
  );
};

function compare_dates(a, b) {
  let c = new Date(a.date);
  let d = new Date(b.date);
  if (a.concept == "payment_rest") return true;

  return c - d;
}

const transform = (values) => {
  let result = [];
  result = [...result, ...values?.jobs];
  result = [...result, ...values?.payments];
  result = result.sort(compare_dates);
  let prev = 0;
  result.forEach((item, index) => {
    item.id = index;
    if (item.concept == "job") {
      prev = prev + item.amount;
    } else {
      prev = prev - item.amount;
    }
    item.subtotal = prev;
  });
  result = result.reverse();
  return result;
};

const Fields = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const [payments, setPayments] = useState();

  const getPayments = async () => {
    let response = await dataProvider.getClientJobsPayments(record?.id);
    if (response?.data) {
      response = transform(response.data);
      setPayments(response);
    }
  };

  useEffect(() => {
    getPayments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box>
          <Typography
            sx={{
              fontSize: "32px",
              lineHeight: "30px",
              fontWeight: 400,
              marginBottom: "8px",
            }}
          >
            {record?.name}
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}
          >
            {record?.cuil_cuit}
          </Typography>
        </Box>
        <Box>
          <PrintClient payments={payments} />
          <EditButton
            label="Editar Cliente"
            startIcon={null}
            variant="outlined"
            size="medium"
            sx={{ marginLeft: "16px" }}
          />
          <Button
            component={LinkRouter}
            to={`/payments/create`}
            state={{ client_id: record?.id }}
            variant="contained"
            sx={{ marginLeft: "16px" }}
          >
            Nuevo Cobro
          </Button>
        </Box>
      </Box>
      <Box
        maxWidth="800px"
        m="22px 0"
        className={classes.grid}
      >
        <Labeled label="ESTADO ACTUAL">
          <FunctionField
            className={classes.text}
            sx={{
              color:
                record?.current_account > 0
                  ? "#FF0000"
                  : record?.current_account == 0
                  ? "#000000"
                  : "#0d8708",
              fontSize: "22px",
              lineHeight: "26px",
              fontWeight: 500,
            }}
            render={(record) =>
              `$${formatter.format(record?.current_account || 0)}`
            }
          />
        </Labeled>
        <Labeled>
          <SelectField
            source="fiscal_condition"
            label="CONDICIÓN FISCAL"
            choices={FISCALCONDITION}
          />
        </Labeled>
        <Labeled label="TELÉFONO">
          <TextField source="phone" className={classes.text} />
        </Labeled>
        <Labeled label="FECHA NAC.">
          <DateField
            source="birth_date"
            locales={"es-AR"}
            className={classes.text}
          />
        </Labeled>
        <Labeled label="E-MAIL">
          <TextField source="email" className={classes.text} />
        </Labeled>
      </Box>
      <Cards />
      <Divider flexItem sx={{ margin: "22px 0", color: "#C4C4C4" }} />
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "20px",
          lineheight: "24px",
          marginBottom: "16px",
        }}
      >
        Observaciones
      </Typography>
      <TextField source="observations" sx={{ width: "520px" }} />
      <Divider flexItem sx={{ margin: "22px 0", color: "#C4C4C4" }} />
      <Payments payments={payments} />
    </Fragment>
  );
};

export const ClientShow = () => {
  return (
    <Show actions={null} title=" ">
      <SimpleShowLayout>
        <Fields />
      </SimpleShowLayout>
    </Show>
  );
};
