import { Fragment, useState } from "react";
import {
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Input,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNotify } from "react-admin";
import { Close, Edit, Save } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormContext } from "react-hook-form";

export const PopUpPassword = () => {
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const { setValue } = useFormContext();
  const notify = useNotify();

  const handleChange = (value, source) => {
    let copy = { ...values };
    if (value != null) {
      copy[`${source}`] = value;
    }
    setValues(copy);
  };

  const handleSave = () => {
    if (values?.password == values?.confirmation_password) {
      if (values?.password?.length >= 6){
        Object.keys(values).forEach((key) => {
          setValue(`user.${key}`, values[`${key}`]);
        });
        setOpen(false);
      }
      else{
        notify("ra.resources.users.errorPasswordLength", { type: "error" });
      }
    } else {
      notify("ra.resources.users.errorPassword", { type: "error" });
    }
  };

  const handleClickShowPassword = (source) => {
    setValues({
      ...values,
      [`${source}`]: !values[`${source}`],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        startIcon={<Edit />}
        sx={{ marginBottom: "3em" }}
      >
        Editar Contraseña
      </Button>
      <Dialog open={open}>
        <DialogTitle sx={{padding:'24px 24px 16px 24px', minWidth:'400px'}}>Editar Contraseña</DialogTitle>
        <DialogContent sx={{padding:'0 24px'}}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl
              sx={{
                marginBottom: "32px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                padding: "0px 2px 0px 2px",
              }}
              size="small"
              variant="filled"
            >
              <InputLabel htmlFor="old-password">Contraseña actual</InputLabel>
              <Input
                id="old-password"
                value={values?.old_password}
                onChange={(e) => handleChange(e.target.value, "old_password")}
                type={values?.old ? "text" : "password"}
                sx={{ paddingLeft: "6px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("old")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{left:'-8px',top:'-4px'}}
                    >
                      {values?.old ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              sx={{
                marginBottom: "32px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                padding: "0px 2px 0px 2px",
              }}
              size="small"
              variant="filled"
            >
              <InputLabel htmlFor="password">Nueva contraseña</InputLabel>
              <Input
                id="password"
                value={values?.password}
                onChange={(e) => handleChange(e.target.value, "password")}
                type={values?.new ? "text" : "password"}
                sx={{ paddingLeft: "6px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("new")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{left:'-8px',top:'-4px'}}
                    >
                      {values?.new ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              sx={{
                marginBottom: "4px",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                padding: "0px 2px 0px 2px",
              }}
              size="small"
              variant="filled"
            >
              <InputLabel htmlFor="confirmation-password">
                Confirmar nueva contraseña
              </InputLabel>
              <Input
                id="confirmation-password"
                value={values?.confirmation_password}
                onChange={(e) =>
                  handleChange(e.target.value, "confirmation_password")
                }
                sx={{ paddingLeft: "6px" }}
                type={values?.confirm ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confirm")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{left:'-8px',top:'-4px'}}
                    >
                      {values?.confirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{padding:'32px 24px 24px 24px', display:'flex', justifyContent:'flex-end'}}>
          <Button startIcon={<Close />} onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button
            startIcon={<Save />}
            variant="contained"
            sx={{marginLeft:'24px'}}
            onClick={() => handleSave()}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
