import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
import React, { useRef, Fragment, useEffect, useState } from "react";
import PrintComponent from "./PrintComponentClient";
import { dataProvider } from "../../providers/dataProvider";
import { useRecordContext } from "react-admin";

const PrintClient = ({ payments }) => {
  const componentRef = useRef();
  const record = useRecordContext();
  const user = JSON.parse(localStorage.getItem("user"));
  const [setting, setSetting] = useState();
  async function getSettings() {
    dataProvider.getSettings().then(({ data: res }) => setSetting(res));
  }

  useEffect(() => {
    getSettings();
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Fragment>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="outlined"
            size="medium"
            onClick={(e) => {
              handleClick(e);
            }}
            sx={{
              "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 },
            }}
          >
            Exportar
          </Button>
        )}
        content={() => componentRef.current}
      ></ReactToPrint>
      <div style={{ display: "none" }}>
        <PrintComponent
          data={record}
          veterinarian={user}
          setting={setting}
          ref={componentRef}
          payments={payments?.slice(0, 20)}
        />
      </div>
    </Fragment>
  );
};

export default PrintClient;
