import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { Form, Create, TextInput } from "react-admin";
import { customPalette } from "../../themes/customTheme";
import { Save } from "@mui/icons-material";
import {
  validateRequiredNumbers,
  validateRequired,
} from "../../utils/Validations";
import { useWatch, useFormContext } from "react-hook-form";
import { Transition } from "../../components/Transition";
import CustomNumberInput from "../../components/CustomNumberInput";

const useStyles = makeStyles({
  title: {
    fontWeight: "700!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
    flex: 1,
  },
  buttonSave: {
    backgroundColor: "#fff!important",
    color: `${customPalette.main}!important`,
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#fff!important",
      color: `${customPalette.main}!important`,
      opacity: 1,
    },
  },
  form: {
    width: "100%",
    maxWidth: "503px",
  },
});

const Fields = ({ total }) => {
  const { setValue } = useFormContext();
  const percentage = useWatch({ name: "percentage" });
  const amount = useWatch({ name: "amount"});
  const [edit, setEdit] = useState();

  const handleChange = (string) => {
    setEdit(string);
  };

  useEffect(() => {
    if (edit == "percentage" && percentage) {
      if (percentage > 0) {
        let res = total * (percentage / 100);
        setValue("amount", res?.toFixed(2));
      } else {
        setValue("amount", undefined);
      }
    }
    if (edit == "amount" && total) {
      if (amount) {
        let res = (amount * 100) / total;
        setValue("percentage", res?.toFixed(2));
      } else {
        setValue("percentage", undefined);
      }
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage, amount, total, edit]); 

  return (
    <Box display="flex">
      <CustomNumberInput
        source="percentage"
        label="Porcentaje %"
        onChange={() => handleChange("percentage")}
        sx={{ marginTop: 0, marginBottom: 0, maxWidth: "120px" }}
      />
      <CustomNumberInput
        source="amount"
        label="Monto"
        validate={validateRequiredNumbers}
        onChange={() => handleChange("amount")}
        sx={{
          marginTop: 0,
          marginBottom: 0,
          maxWidth: "120px",
          marginLeft: "32px",
        }}
      />
    </Box>
  );
};

const FormCollaborator = ({ handleSave, handleClose, data }) => {
  const classes = useStyles();
  const total = useWatch({ name: "total" });

  const handleSubmit = (values) => {
    handleSave(values);
    handleClose();
  };

  return (
    <Create sx={{ ".RaCreate-noActions": { marginTop: 0 } }}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={data}
        className={classes.form}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{
            padding: "16px 16px 16px 16px",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" className={classes.title}>
            {!data ? "Agregar colaborador" : "Editar colaborador"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={"0 16px"} boxSizing="border-box">
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "0 auto", maxWidth: "575px" }}
          >
            <TextInput
              source="name"
              label="Nombre profesional"
              validate={validateRequired}
              sx={{
                marginRight: "24px",
                marginTop: 0,
                marginBottom: 0,
                width: "100%",
              }}
            />
            <Fields total={total} />
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          sx={{ padding: "16px", boxSizing: "border-box" }}
        >
          <Button
            onClick={handleClose}
            sx={{ marginRight: "16px" }}
            startIcon={<CloseIcon sx={{ color: `${customPalette.main}` }} />}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="contained" startIcon={<Save />}>
            {!data ? "Agregar Colaborador" : "Guardar Cambios"}
          </Button>
        </Box>
      </Form>
    </Create>
  );
};

export const DialogCollaborators = ({
  open,
  handleClose,
  handleSave,
  data,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      sx={{ "& .MuiDialog-paper": { maxWidth: "503px" } }}
    >
      <FormCollaborator
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
      />
    </Dialog>
  );
};
