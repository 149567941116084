import { Toolbar,SaveButton } from "react-admin";
import { Button, Box } from "@mui/material";
import  Close  from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ConfirmDeleteButton } from "./ConfirmDeleteButton";

export const CustomToolbar = ({labelBack = "CANCELAR",labelSave = "GUARDAR", alwaysEnable = false, allowDelete = false}) => {
  const navigate = useNavigate();
  return (
    <Toolbar
      sx={{
        padding: "16px 24px",
        boxSizing: "border-box",
        background: "#F0F0F0",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button startIcon={<Close />} onClick={() => navigate(-1)}>
        {labelBack}
      </Button>
      <Box display='flex' sx={{gap:'16px'}}>
        {allowDelete && <ConfirmDeleteButton resource='users' element='el usuario' label='ELIMINAR' warnDangerousMove={allowDelete}/>}
        <SaveButton alwaysEnable={alwaysEnable} label={labelSave} />
      </Box>
    </Toolbar>
  );
};
