import { Card, Typography, Box } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";
import { useState, useEffect, useRef } from "react";
import { getElementDimensions } from "../../utils/utils";
import { Error, Loading, useDataProvider } from "react-admin";
import { useQuery } from "react-query";

const useStyles = makeStyles({
  container: {
    boxSizing: "border-box",
    display: "flex",
    flex: 1,
  },
  card: {
    width: "100%",
    padding: "16px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    "& .recharts-legend-item-text":{
      color: "black!important",
    }
  },
  title: {
    margin: 0,
    fontWeight: 400,
    fontSize: "24px!important",
    lineHeight: "1.5!important",
    color: "#343434",
    marginBottom: "8px",
  },
});

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Fragment>
      <text
        x={x}
        y={y}
        fill="white"
        fontSize={"16px"}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </Fragment>
  );
};


const style = {
  top: '50%',
  right: 0,
  maxWidth: '120px',
  transform: 'translate(0, -50%)',
  lineHeight: '24px',
};

const CustomTooltip = ({active, payload}) => {
  if (active && payload && payload.length) {
    let payloadValue = payload[0].value;
    let payloadLabel = payload[0].name;
    return (
      <span>
        {`${payloadLabel}: ${Number(payloadValue).toLocaleString("es-ar", {
          style: "currency",
          currency: "ARS",
          minimumFractionDigits: 2,
        })}`}</span>
    );
  }
  return null;
};

export const CircularGraph = () => {
  const classes = useStyles();
  const targetRef = useRef();
  const [width, setWidth] = useState(250);
  const [rezise, setResize] = useState(false);

  useEffect(() => {
    // set width if window resizes
    const handleResize = () => {
      setResize(true);
      const { width } = getElementDimensions(targetRef);
      setWidth(width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const dataProvider = useDataProvider();
  const { data: elements, isLoading, error } = useQuery(
    [ 'getDashboard', { method:  'lastyear' }],
    () => dataProvider.getDashboard('lastyear')
  );
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!elements) return null;

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={classes.container}
    >
      <Card className={classes.card} ref={targetRef}>
        <Box mb=".5em" alignSelf="flex-start">
          <Typography className={classes.title}>
            Servicios del último año
          </Typography>
        </Box>
        {targetRef?.current != undefined && (
          <PieChart
            width={!rezise ? targetRef.current.clientWidth - 32 : width - 32}
            height={250}
          >
            <Legend layout="vertical" iconSize={10} verticalAlign="middle" wrapperStyle={style}/>
            <Pie
              data={elements?.data}
              cx="30%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={90}
              fill="#8884d8"
              dataKey="value"
            >
              {elements?.data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip 
              content={<CustomTooltip />}
              wrapperStyle={{padding: "4px", boxSizing: "border-box", background: "#fff"}}
            />
          </PieChart>
        )}
      </Card>
    </ResponsiveContainer>
  );
};
