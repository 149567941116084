import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { customPalette } from "../../themes/customTheme";
import { IconButton, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatter } from "../../utils/NumberFormatter";
import { useRecordContext } from "react-admin";

export const TableExpense = ({
  rows,
  handleConfirm,
  handleEdit,
  hiddeAction = false,
}) => {
  const [total, setTotal] = useState();
  const { status } = useRecordContext();
  const getTotal = () => {
    let res = rows?.reduce((acc, i) => acc + i?.amount || 0, 0);
    return res;
  };
  useEffect(() => {
    setTotal(getTotal());
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!rows || rows?.length == 0) return null;
  return (
    <TableContainer
      sx={{
        "& .MuiTable-root": {
          "& .MuiTableHead-root": {
            backgroundColor: "#FBFBFF",
            "& .MuiTableCell-root": {
              color: `${customPalette.main}`,
              fontWeight: "600!important",
            },
          },
          "& .MuiTableRow-root:last-child > .MuiTableCell-root": {
            border: "none!important",
          },
          "& .MuiTableCell-root": {
            color: `${customPalette.main}`,
            fontSize: hiddeAction ? "12px!important" : "inherit",
            lineHeight: hiddeAction ? "14px!important" : "inherit",
          },
        },
        marginBottom: "16px",
      }}
    >
      <Table
        sx={{ minWidth: "582px", maxWidth: "582px" }}
        size="small"
        aria-label="Tabla servicios"
      >
        <TableHead>
          <TableRow>
            <TableCell>Tipo de gasto</TableCell>
            <TableCell align="left">Monto</TableCell>
            {!hiddeAction && status == "en_proceso" && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index = 0) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row?.name || " - "}</TableCell>
              <TableCell align="left">{`$${formatter.format(
                row.amount || 0
              )}`}</TableCell>
              {!hiddeAction && status == "en_proceso" && (
                <TableCell align="left">
                  {" "}
                  <Box>
                    <IconButton
                      sx={{
                        color: `${customPalette.main}`,
                        padding: 0,
                        marginRight: "8px",
                      }}
                      onClick={(e) => handleEdit(e, index)}
                      aria-label="Editar"
                    >
                      <Edit sx={{ width: "18px", height: "18px" }} />
                    </IconButton>
                    <IconButton
                      sx={{ color: `${customPalette.main}`, padding: 0 }}
                      onClick={(e) => handleConfirm(e, index)}
                      aria-label="Eliminar"
                    >
                      <DeleteIcon sx={{ width: "18px", height: "18px" }} />
                    </IconButton>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "18px",
                color: `${customPalette.main}`,
              }}
            >
              TOTAL
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "18px",
                color: `${customPalette.main}`,
              }}
            >{`$${formatter.format(total)}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
