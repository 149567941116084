import {
  required,
  number,
  email,
} from "react-admin";
import moment from "moment";

export const isValidDate = (value) =>{
  let isvalid = moment(value, "YYYY-MM-DD", true).isValid();
  return isvalid;
}

const confirmedPassword = (value, allValues) => {
  if (value != allValues['password']) return 'Las contraseñas no coinciden';
  return undefined;
}

function CUITCUIL(cuit)
  {
    let cuit_nro = cuit.replaceAll("-", "");
    if (cuit_nro?.length != 11) return false;

    let rv = false;
    let resultado = 0;
    let codes = "6789456789";
    let verificador = parseInt(cuit_nro[cuit_nro.length-1]);
    var x = 0;

    while (x < 10)
    {
      let digitoValidador = parseInt(codes.substring(x, x+1));
      if (isNaN(digitoValidador)) digitoValidador = 0;
      let digito = parseInt(cuit_nro.substring(x, x+1));
      if (isNaN(digito)) digito = 0;
      let digitoValidacion = digitoValidador * digito;
      resultado += digitoValidacion;
      x++;
    }
    resultado = resultado % 11;
    rv = (resultado == verificador);
    return rv;
  }

export const validateCUITCUIL = CUITCUIL;
export const validateRequiredNumbers = [required(), number()];
export const validateNumbers = [number()];
export const validateDate = [isValidDate()];
export const validateRequiredDate = [isValidDate(),required()];
export const validateRequired = [required()];
export const validateEmail = [required(), email()];
export const validateConfirmationPassword = [required(), confirmedPassword];
