import { makeStyles } from "@mui/styles";
import { TopToolbar, CreateButton, FilterButton } from "react-admin";
import ExportExcelButton from "./ExportExcelButton";

const useStyles = makeStyles({
  btn: {
    "& .MuiButton-root": {
      border: "1px solid grey",
      borderRadius: "4px",
      padding: "5px 15px",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.75",
    },
  },
});

export const ActionsCreate = ({ label, filter = false, exportExcel = false }) => {
  const classes = useStyles();
  return (
    <TopToolbar>
      {filter && <FilterButton className={classes.btn} />}

      {exportExcel && <ExportExcelButton className={classes.btn} />}
      <CreateButton variant="contained" size="medium" label={label} />
    </TopToolbar>
  );
};
