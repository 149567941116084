import { Fragment, useState } from "react";
import { MenuItemLink, useTranslate, useSidebarState } from "react-admin";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { hasPermission, hasRole } from "../utils/permissions";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { UserIcon } from "../resources/users";
import { RoleIcon } from "../resources/roles";
import { ClientIcon } from "../resources/clients";
import { PaymentIcon } from "../resources/payments";

import SubMenu from "./SubMenu";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  submenuopen: {
    paddingLeft: '2em!important',
  },

})

export const Menu = ({ onMenuClick, dense = false }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [state, setState] = useState({
    usersMenu: false,
  });

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Fragment>
      {hasPermission("view-dashboard") && (
        <MenuItemLink
          to={`/`}
          primaryText={translate("ra.home.title")}
          leftIcon={<HomeRoundedIcon />}
          onClick={onMenuClick}
          dense={dense}
          sx={{ marginTop: '2em' }}
        />
      )}

      {hasPermission("list-jobs") && (
        <MenuItemLink
          to={`/jobs`}
          primaryText={translate("ra.resources.jobs.name")}
          leftIcon={<AssignmentTurnedInIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
      )}
      {hasPermission("list-clients") && (
        <MenuItemLink
          to={`/clients`}
          primaryText={translate("ra.resources.clients.name")}
          leftIcon={<ClientIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
      )}
      {hasPermission("list-clients") && (
        <MenuItemLink
          to={`/payments`}
          primaryText={translate("ra.resources.payments.name")}
          leftIcon={<PaymentIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
      )}
      {(hasRole('superadmin')) && (
        <SubMenu
          handleToggle={() => handleToggle("usersMenu")}
          isOpen={state.usersMenu}
          name="ra.resources.config.name"
          icon={<ManageAccountsIcon />}

          dense={dense}
        >
          {hasPermission("list-users") && (
            <MenuItemLink
              to={`/users`}
              primaryText={translate("ra.resources.users.name")}
              leftIcon={<UserIcon />}
              onClick={onMenuClick}
              role="menuitem"
              dense={dense}
              className={open ? classes.submenuopen : ''}
            />
          )}
          {hasPermission("list-roles") && (
            <MenuItemLink
              to={`/roles`}
              primaryText={translate("ra.resources.roles.name")}
              leftIcon={<RoleIcon />}
              onClick={onMenuClick}
              role="menuitem"
              dense={dense}
              className={open ? classes.submenuopen : ''}
            />
          )}
        </SubMenu>
      )}
    </Fragment>
  );
};
