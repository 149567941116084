import { makeStyles } from "@mui/styles";
import { DialogAnimalPrice } from "../../resources/professional-services/list/DialogAnimal";
import { Box } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { customPalette } from "../../themes/customTheme";

const useStyles = makeStyles({
    container:{
        background: "white",
        padding: "16px!important",
        boxSizing: "border-box",
        boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        borderRadius: "4px",
        minHeight: "100px",
        display: "flex",
        "& .icon svg": {
            fontSize: '50px',
            color: `${customPalette.main}`,
          },
    },
    animal: {
        flex: "1 1 auto",
        margin: "0!important",
        border: "none!important",
        padding: "0!important",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& .title": {
      margin: 0,
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      color: "rgba(0, 0, 0, 0.6)",
      textAlign: "right",
      display: "flex",
      flexDirection: "column",
    },
    "& .price": {
      lineHeight: "1.334",
      fontSize: "1.6em",
      paddingLeft: "0!important",
      fontWeight: 600,
      color: "#343434",
      marginBottom: "0",
    },
    "& .editBtn": {
      padding: "4px 8px!important",
      justifyContent: "flex-end",
      maxHeight: "48px",
      alignSelf: "flex-end",
    },
    "& .editIcon": {
      fontSize: "16px!important",
      
    },
  },
});

export const AnimalCard = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
        <Box p="8px" className="icon" display="flex" alignSelf="flex-start">
        <MonetizationOnIcon />
        </Box>
      <DialogAnimalPrice className={classes.animal} disableBtnText={false}/>
    </Box>
  );
};
