import spanishMessages from './spanishMessages';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
  es: spanishMessages,
  en: englishMessages,
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'es',{allowMissing: true});
