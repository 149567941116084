import { defaultTheme } from "react-admin";
import { grey, red } from "@mui/material/colors";

export const customPalette = {
  main: "#364668",
  ligth: "#364668",
  dark: "#364668",
  contrastText: "#fff",
};

export const customTheme = {
  ...defaultTheme,
  palette: {
    primary: customPalette,
    secondary: grey,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      "Fira Sans",
      "Segoe UI",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiButtonBase: {
      // The default props to change
      disableRipple: true // No more ripple, on the whole application 💣!
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '100%',
          "&[role=submenu]": {
            "& svg": {
              color: `${customPalette.main}`,
            },
            "&:hover": {
              background: `${customPalette.main}`,
              color: "white",
              boxSizing: 'border-box',
              "& svg": {
                color: "white",
              },
            },
            "&:focus::after":{
              opacity:'0!important'
            },
            "&.RaMenuItemLink-active": {
              "&:not(.app-bar-menu)": {
                background: `${customPalette.main}`,
                color: "white",
                "& svg": {
                  color: "white",
                },
              },
            },
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "&:not(.app-bar-menu)": {
            "& svg": {
              color: `${customPalette.main}`,
            },
            "&:hover": {
              background: `${customPalette.main}`,
              color: "white",
              "& svg": {
                color: "white",
              },
            },
            "&.RaMenuItemLink-active": {
              "&:not(.app-bar-menu)": {
                background: `${customPalette.main}`,
                color: "white",
                "& svg": {
                  color: "white",
                },
              },
            },
          },
        },
      },
    },
    RaSimpleShowLayout:{
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          "& .MuiToolbar-root:first-of-type":{
            alignItems:'center',
            "& .MuiToolbar-root:first-of-type":{
              marginTop:"8px!important",
              paddingBottom:0,
              alignItems: 'flex-start',
              alignSelf: 'flex-start'
            }
          },
          "& .RaFilterForm-root":{
            alignItems:'center',
          },
          main: {
            marginTop: "20px",
          },
        },
      },
    },
    RaCreate:{
      styleOverrides: {
          root: {
            '& .MuiCardContent-root':{
              padding: "24px",
            }
          },
      },
    },
    RaEdit:{
      styleOverrides: {
          root: {
            '& .MuiCardContent-root':{
              padding: "24px",
            }
          },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "& #main-content": {
            background: "#F5F5F5",
            padding: "16px",
            boxSizing: "border-box",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          "&.RaLogout-icon": {
            display: "none",
          },
        },
      },
    },
    RaFilterForm:{
      styleOverrides:{
        root:{
          '& .filter-field':{
            marginBottom: '4px!important',
          },
          '& .MuiFormControl-root':{
            marginBottom: '0!important',
          }
        }
      }
    }
  },
};
