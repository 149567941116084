import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import { Typography, Card, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useEffect, useRef } from "react";
import { getElementDimensions } from "../../utils/utils";
import { useQuery } from "react-query";
import { Error, Loading, useDataProvider } from "react-admin";

const useStyles = makeStyles({
  container: {
    boxSizing: "border-box",
  },
  card: {
    width: "100%",
    padding: "16px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    "& .recharts-legend-item-text": {
      color: "black!important",
    },
  },
  title: {
    margin: 0,
    fontWeight: 400,
    fontSize: "24px!important",
    lineHeight: "1.5!important",
    color: "#343434",
    marginBottom: "8px",
  },
  grayTitle: {
    margin: 0,
    fontWeight: 300,
    fontSize: "20px!important",
    lineHeight: "1.5!important",
    color: "#818181",
    marginBottom: "8px",
  }
});

const style = {
  textAlign: "left",
};

export const BarGraph = () => {
  const classes = useStyles();
  const targetRef = useRef();
  const [width, setWidth] = useState(350);
  const [rezise, setResize] = useState(false);

  const dataProvider = useDataProvider();
  const {
    data: queried_data,
    isLoading,
    error,
  } = useQuery(["getDashboard", { method: "jobstable" }], () =>
    dataProvider.getDashboard("jobstable")
  );

  useEffect(() => {
    // set width if window resizes
    const handleResize = () => {
      setResize(true);
      const { width } = getElementDimensions(targetRef);
      setWidth(width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!queried_data) return null;

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={classes.container}
    >
      <Card className={classes.card} ref={targetRef}>
        <Box mb=".5em" display="flex" gap="8px" alignItems="center" justifyContent="flex-start" width="100%">
          <Typography className={classes.title}>Trabajos realizados</Typography>
          <Typography className={classes.grayTitle}>últimos 6 meses</Typography>
        </Box>
        {targetRef?.current != undefined && (
          <ComposedChart
            layout="vertical"
            width={!rezise ? targetRef.current.clientWidth - 32 : width - 32}
            height={300}
            data={queried_data.data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" scale="band" />
            <Tooltip />
            <Legend
              payload={[
                {
                  id: "en_proceso",
                  color: "#0088FE",
                  value: "En proceso",
                  type: "square",
                },
                {
                  id: "cobrado_parcialmente",
                  color: "#FFB800",
                  value: "Cobro parcial",
                  type: "square",
                },
                {
                  id: "finalizado",
                  color: "#2AB572",
                  value: "Finalizado",
                  type: "square",
                },
                {
                  id: "no_cobrado",
                  color: "#ff6600",
                  value: "No cobrado",
                  type: "square",
                },
                {
                  id: "vencido",
                  color: "#FF0000",
                  value: "Vencido",
                  type: "square",
                },
              ]}
              wrapperStyle={style}
            />
            <Bar
              dataKey={"en_proceso"}
              stackId="a"
              barSize={20}
              fill={`#0088FE`}
            />
            <Bar
              dataKey={"cobrado_parcialmente"}
              stackId="a"
              barSize={20}
              fill={`#FFB800`}
            />
            <Bar
              dataKey={"finalizado"}
              stackId="a"
              barSize={20}
              fill={`#2AB572`}
            />
            <Bar
              dataKey={"no_cobrado"}
              stackId="a"
              barSize={20}
              fill={`#ff6600`}
            />
            <Bar
              dataKey={"vencido"}
              stackId="a"
              barSize={20}
              fill={`#FF0000`}
            />
          </ComposedChart>
        )}
      </Card>
    </ResponsiveContainer>
  );
};
