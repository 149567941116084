import React from "react";
import LogoHeader from './assets/logo.png';
import LogoText from './assets/logo-text.png';

const Logo = ({ maxWidth, height = "32px" , text = false}) => {
    return (
        <img style={{ maxWidth : "100%"}} height={height} src={text ? LogoText : LogoHeader} alt="logo" />
    );
};

export default Logo;
