import React, { Fragment, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Dialog, Box, Button, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import { Labeled, useListContext } from "react-admin";
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import Close from "@mui/icons-material/Close";
import { Transition } from "./Transition";

const useStyles = makeStyles(() => ({
  paper: {
    width: 680,
    backgroundColor: "white",
    maxWidth: "703px!important",
  },
}));
export default function DatePickerBetween({
  source = "dates_between",
  label = "Rango de fechas",
}) {
  const { filterValues, setFilters } = useListContext();
  const { setValue } = useFormContext();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: filterValues?.date_between
      ? filterValues.date_between?.startDate
      : "",
    endDate: filterValues?.date_between
      ? filterValues.date_between?.endDate
      : "",
  });
  const toggle = () => setOpen(!open);

  useEffect(() => {
    if (dateRange?.startDate != "" && dateRange?.endDate != "") {
      setValue(source, dateRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const handleChange = (values) => {
    let init = values.startDate
      ? moment(new Date(values?.startDate))
          .locale("es-AR")
          .format("yyyy-MM-DD")
          .toLocaleString()
      : "";
    let end = values.endDate
      ? moment(new Date(values?.endDate))
          .locale("es-AR")
          .format("yyyy-MM-DD")
          .toLocaleString()
      : "";
    setDateRange({ startDate: init, endDate: end });
    toggle();
  };

  const handleClose = () => {
    setDateRange({ startDate: "", endDate: "" });
    let fil = { ...filterValues };
    fil = delete fil[source];
    setFilters({ ...fil });
  };

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          minWidth: "200px",
          borderBottom: `1px solid #0000006b`,
          background: "#0000000a",
          minHeight: "48px",
          maxHeight: "48px",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          padding: "4px 8px 0px 8px",
          boxSizing: "border-box",
          "& .RaLabeled-label": {
            fontSize: dateRange?.startDate != "" ? "0.75em" : "1em!important",
            marginBottom: dateRange?.startDate != "" ? "0.2em" : "0!important",
          },
          "& > span:first-of-type": {
            marginBottom: dateRange?.startDate != "" ? "0.2em" : "0!important",
          },
        }}
      >
        <Labeled label={label}>
          <Typography sx={{ fontSize: ".9em" }}>
            {dateRange?.startDate || ""} {dateRange?.startDate ? "-" : " "}{" "}
            {dateRange?.endDate || ""}{" "}
          </Typography>
        </Labeled>
        {dateRange?.startDate != "" && (
          <Button
            sx={{
              padding: "2px",
              minWidth: "24px",
              height: "24px",
              marginLeft: ".5em",
              borderRadius: "50%",
              marginBottom: "5px",
              alignSelf: "flex-end",
            }}
            onClick={() => handleClose()}
          >
            <Close sx={{ padding: 0, color: "#0000008a" }} />
          </Button>
        )}
        <Button
          onClick={() => toggle()}
          sx={{ padding: 0, minWidth: "40px", minHeight: "40px" }}
        >
          <DateRangeIcon sx={{ padding: 0, color: "black" }} />
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={() => toggle}
        fullWidth
        maxWidth="md"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            toggle();
          }
        }}
        classes={{ paper: classes.paper }}
        TransitionComponent={Transition}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
            sx={{ position: "absolute", right: 0, zIndex: 2 }}
          >
            <Close />
          </IconButton>
        </Box>
        <DateRangePicker
          open={open}
          onChange={(range) => handleChange(range)}
        />
      </Dialog>
    </Fragment>
  );
}
