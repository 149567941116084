import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { Create, DateInput, Form, TextInput } from "react-admin";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { customPalette } from "../../themes/customTheme";
import { Transition } from "../../components/Transition";
import { useWatch } from "react-hook-form";
import { isValidDate, validateRequired } from "../../utils/Validations";
import CustomNumberInput from "../../components/CustomNumberInput";

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    background: `${customPalette.main}`,
    width: "100%",
    boxSizing: 'border-box',
    padding: "20px 16px",
    "& .MuiToolbar-root": {
      minHeight: "16px!important",
      padding: "0!important",
    },
    maxHeight:'76px',
  },
  title: {
    marginLeft: "16px",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "30px",
  },
  button: {
    marginRight: "1em",
  },
  input: {
    marginBottom: "1em",
    "& .MuiInputLabel-formControl": {
      left: "2px",
    },
  },
  date: {
    marginTop: "1em",
  },
  comments: {
    margin: "1.5em 0 1em 0",
  },
  buttonSave: {
    backgroundColor: "#fff!important",
    color: `${customPalette.main}!important`,
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#fff!important",
      color: `${customPalette.main}!important`,
      opacity: 1,
    },
  },
});


const FormEstablishment = ({ handleSave, handleClose, data }) => {
  const classes = useStyles();
  const formData = useWatch();
  const handleSubmit = (values) => {
    handleSave(values);
    handleClose();
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "El nombre es requerido";
    }
    if (!values.location) {
      errors.location = "La localidad es requerida";
    }
    if (values.owner_birth_date) {
      if (!isValidDate(values.owner_birth_date)) {
        errors.owner_birth_date = "La fecha es inválida";
      }
    }
    if (values.owner_birth_date) {
      if (!isValidDate(values.owner_birth_date)) {
        errors.owner_birth_date = "La fecha es inválida";
      }
    }
    if (!data && formData?.establishments?.find(i => i?.name == values?.name)){
      errors.name = "El nombre del establecimiento no puede estar repetido";
    }
    return errors;
  };

  return (
    <Create sx={{'.RaCreate-noActions': {marginTop:0}}} component="div">
      <Form onSubmit={handleSubmit} defaultValues={data} validate={validate}>
        <AppBar className={classes.appBar}>
          <Toolbar sx={{display:'flex', justifyContent:'space-between', width: '100%'}}>
            <Box display="flex" alignItems="center">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {!data ? "Agregar establecimiento" : "Editar establecimiento"}
            </Typography>
            </Box>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              className={classes.buttonSave}
            >
              {!data ? "Agregar Establecimiento" : "Guardar Cambios"}
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={"76px 1em 1em 1em"}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "0 auto", maxWidth: "575px" }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: 400,
                marginBottom: "16px",
                marginTop:'32px'
              }}
            >
              Establecimiento
            </Typography>
            <Box display="flex">
              <TextInput
                source="name"
                label="Nombre del establecimiento"
                variant="filled"
                sx={{ width: "360px" }}
                validate={validateRequired}
              />
              <CustomNumberInput
                source="hectares"
                label="Hectáreas"
                variant="filled"
                sx={{ marginLeft: "24px", width: "150px" }}
              />
            </Box>
            <Box display="flex">
              <TextInput
                source="location"
                label="Localidad"
                variant="filled"
                validate={validateRequired}
                sx={{ width: "320px" }}
              />
              <CustomNumberInput
                source="distance"
                label="Distancia (km)"
                variant="filled"
                sx={{ marginLeft: "24px", width: "160px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                margin: "12px 0 16px 0",
              }}
            >
              Propietario
            </Typography>
            <Box display="flex">
              <TextInput
                source="owner_name"
                label="Nombre y Apellido"
                variant="filled"
                sx={{ width: "360px" }}
              />
              <DateInput
                source="owner_birth_date"
                label="Fecha de nacimiento"
                variant="filled"
                sx={{ marginLeft: "24px", width: "150px" }}
              />
            </Box>
            <Box display="flex">
              <CustomNumberInput
                source="owner_phone"
                label="Teléfono"
                variant="filled"
                sx={{ width: "250px" }}
              />
              <TextInput
                type="email"
                source="owner_email"
                label="Email"
                variant="filled"
                sx={{ marginLeft: "24px", width: "250px" }}
              />
            </Box>

            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: 400,
                margin: "12px 0 16px 0",
              }}
            >
              Encargado
            </Typography>
            <Box display="flex">
              <TextInput
                source="in_charge_name"
                label="Nombre y Apellido"
                variant="filled"
                sx={{ width: "360px" }}
              />
              <DateInput
                source="in_charge_birth_date"
                label="Fecha de nacimiento"
                variant="filled"
                sx={{ marginLeft: "24px", width: "150px" }}
              />
            </Box>
            <Box display="flex">
              <CustomNumberInput
                source="in_charge_phone"
                label="Teléfono"
                variant="filled"
                sx={{ width: "250px" }}
              />
              <TextInput
                type="email"
                source="in_charge_email"
                label="Email"
                variant="filled"
                sx={{ marginLeft: "24px", width: "250px" }}
              />
            </Box>
          </Box>
        </Box>
      </Form>
    </Create>
  );
};

export const DialogEstablishment = ({
  open,
  handleClose,
  handleSave,
  data,
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <FormEstablishment
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
      />
    </Dialog>
  );
};
