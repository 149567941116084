import { NumberInput } from "react-admin";

export default function CustomNumberInput({ source, label, validate , ...rest}) {
  return (
    <NumberInput
      source={source}
      label={label}
      onFocus={(e) =>
        e.target.addEventListener(
          "wheel",
          function (e) {
            e.preventDefault();
          },
          { passive: false }
        )
      }
      {...rest}
    />
  );
}
