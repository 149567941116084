import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  Confirm,
  useRecordContext,
  SelectInput,
} from "react-admin";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { Fragment, useState } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { customPalette } from "../../../themes/customTheme";
import Add from "@mui/icons-material/Add";
import { DialogEstablishment } from "../DialogEstablishment";
import { useFormContext } from "react-hook-form";
import { ShowEstablishment } from "../ShowEstablishment";
import { FISCALCONDITION } from "../../constants";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { validate } from "../create/create";
import { validateRequired } from "../../../utils/Validations";
import { ErrorEstablishments } from "../ErrorEstablishment";
import CustomNumberInput from "../../../components/CustomNumberInput";

const PanelTitle = () => {
  return (
    <Typography
      variant="h1"
      sx={{
        marginBottom: "32px",
        fontSize: "32px",
        lineHeight: "30px",
        fontWeight: 400,
      }}
    >
      Editar Cliente
    </Typography>
  );
};

const ArrayEstablishments = () => {
  const record = useRecordContext();
  const [values, setValues] = useState(record?.establishments || []);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const { setValue } = useFormContext();

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  const handleSave = (newitem) => {
    let copy = [...values];
    if (index != null) {
      copy[index] = newitem;
    } else {
      copy.push(newitem);
    }
    setValues(copy);
    setValue("establishments", copy);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    setData(values[index]);
    setIndex(index);
    setOpen(true);
  };
  const handleDelete = () => {
    let copy = [...values];
    copy.splice(index, 1);
    setValues(copy);
    setIndex(null);
    setOpenConfirm(false);
    handleCloseShow();
    setValue("establishments", copy);
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  const handleOpenShow = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setData(values[index]);
    setOpenShow(true);
    setIndex(index);
  };

  const handleCloseShow = () => {
    setData(null);
    setIndex(null);
    setOpenShow(false);
  };

  return (
    <Fragment>
      <Typography sx={{ fontSize: "1.2em", fontWeight: 500, margin: ".5em 0" }}>
        Establecimientos
      </Typography>
      {values?.length > 0 &&
        values?.map((item, index = 0) => (
          <Card
            onClick={(e) => handleOpenShow(e, index)}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "400px",
              margin: ".5em 0",
              "&:hover": { cursor: "pointer" },
            }}
            elevation={2}
          >
            <CardContent>
              <Typography
                sx={{
                  color: `${customPalette.main}`,
                  fontSize: "1.2em",
                  fontWeight: 500,
                }}
              >
                {item?.name}  {item?.hectares ? ` - ${item.hectares}Hc` : ""}
              </Typography>
              <Typography sx={{ color: "#000", fontWeight: 500 }}>
                {item?.location}  {item?.distance ? ` - ${item.distance} km` : ""}
              </Typography>

            </CardContent>
            <CardActions>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifySelf: "right",
                  alignSelf: "flexStart",
                  alignItems: "flex-start",
                  justifyItems: "flex-start",
                }}
              >
                <Button
                  onClick={(e) => handleEdit(e, index)}
                  sx={{ padding: "8px", minWidth: "24px" }}
                >
                  <EditIcon
                    sx={{ color: `${customPalette.main}`, padding: 0 }}
                  />
                </Button>
                <Button
                  onClick={(e) => handleConfirm(e, index)}
                  sx={{ padding: "8px", minWidth: "24px" }}
                >
                  <DeleteOutlinedIcon sx={{ color: "red", padding: 0 }} />
                </Button>
              </Box>
            </CardActions>
          </Card>
        ))}
      <Button
        variant="outlined"
        sx={{ fontWeight: "600", mt: "1em" }}
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        AGREGAR ESTABLECIMIENTO
      </Button>
      <DialogEstablishment
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
      />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el establecimiento?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
      <ShowEstablishment
        open={openShow}
        data={data}
        index={index}
        handleClose={handleCloseShow}
        handleDelete={handleConfirm}
        handleEdit={handleEdit}
        canEdit={true}
      />
    </Fragment>
  );
};

export const ClientEdit = () => {
  return (
    <Edit title=" " redirect="list" actions={null}>
      <SimpleForm
        validate={validate}
        toolbar={<CustomToolbar alwaysEnable={true} />}
      >
        <PanelTitle />
        <TextInput
          source="name"
          label="Nombre y Apellido"
          variant="filled"
          sx={{ minWidth: "350px" }}
          validate={validateRequired}
        />
        <TextInput
          source="cuil_cuit"
          label="CUIL/CUIT"
          variant="filled"
          helperText={"Solo números"}
        />
        <Box display="flex">
          <TextInput
            type="email"
            source="email"
            label="Email"
            variant="filled"
            validate={validateRequired}
          />
          <CustomNumberInput
            sx={{ marginLeft: "1em" }}
            type="phone"
            source="phone"
            label="Teléfono"
            variant="filled"
          />
        </Box>
        <DateInput
          source="birth_date"
          label="Fecha de nacimiento"
          variant="filled"
          sx={{ minWidth: "220px" }}
        />
        <SelectInput
          source="fiscal_condition"
          choices={FISCALCONDITION}
          label="Condición fiscal"
          variant="filled"
          emptyText="Ninguna"
          sx={{ minWidth: "220px" }}
        />

        <ArrayEstablishments />
        <ErrorEstablishments />
        <Typography
          sx={{ fontSize: "1.2em", fontWeight: 500, margin: "32px 0 0 0" }}
        >
          Observaciones
        </Typography>
        <TextInput
          multiline
          source="observations"
          label=""
          rows={4}
          variant="filled"
          sx={{
            width: "520px",
            "& .MuiFilledInput-root": { padding: "16px!important" },
          }}
        />
      </SimpleForm>
    </Edit>
  );
};
