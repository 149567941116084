const spanishMessages = {
  ra: {
    action: {
      add: "Añadir",
      add_filter: "Añadir filtro",
      back: "Ir atrás",
      bulk_actions: "1 item seleccionado |||| %{smart_count} items seleccionados",
      cancel: "Cancelar",
      clear_input_value: "Limpiar valor",
      clone: "Clonar",
      close: "Cerrar",
      close_menu: "Cerrar menú",
      confirm: "Confirmar",
      create: "Crear",
      delete: "Eliminar",
      edit: "Editar",
      expand: "Expandir",
      export: "Exportar",
      list: "Listar",
      open_menu: "Abrir menú",
      refresh: "Refrescar",
      remove: "Borrar",
      remove_filter: "Borrar filtro",
      save: "Guardar",
      search: "Buscar",
      show: "Mostrar",
      sort: "Ordenar",
      undo: "Deshacer",
      unselect: "Deseleccionar",
      create_item: "Crear"
    },
    auth: {
      title: 'Iniciar sesión',
      auth_check_error: "Por favor inicie sesión para continuar",
      logout: "Cerrar Sesión",
      password: "Contraseña",
      sign_in: "Iniciar sesión",
      sign_in_error: "La autenticación falló, por favor, vuelva a intentarlo",
      user_menu: "Perfil",
      username: "Usuario",
      email: "Email",
      forgot_password: "¿Olvidaste tu contraseña?",
    },
    boolean: {
      true: "Sí",
      false: "No",
      null: " "
    },
    input: {
      file: {
        upload_several: "Arrastre algunos archivos para subir o haga clic para seleccionarlos.",
        upload_single: "Arrastre un archivo para subir o haga clic para seleccionarlo."
      },
      image: {
        upload_several: "Arrastre algunas imagénes para subir o haga clic para seleccionarlas.",
        upload_single: "Arrastre alguna imagen para subir o haga clic para seleccionarla."
      },
      references: {
        all_missing: "No se pueden encontrar datos de referencias.",
        many_missing: "Al menos una de las referencias asociadas parece no estar disponible.",
        single_missing: "La referencia asociada no parece estar disponible."
      },
      password: {
        toggle_visible: "Ocultar contraseña",
        toggle_hidden: "Mostrar contraseña"
      }
    },
    message: {
      about: "Acerca de",
      are_you_sure: "¿Está seguro?",
      bulk_delete_content: "¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?",
      bulk_delete_title: "Eliminar %{name} |||| Eliminar %{smart_count} %{name} items",
      delete_content: "¿Seguro que quiere eliminar este item?",
      delete_title: "Eliminar %{name} #%{id}",
      details: "Detalles",
      error: "Se produjo un error en el cliente y su solicitud no se pudo completar",
      invalid_form: "El formulario no es válido. Por favor verifique si hay errores",
      loading: "La página se está cargando, espere un momento por favor",
      no: "No",
      not_found: "O bien escribió una URL incorrecta o siguió un enlace incorrecto.",
      yes: "Sí",
      unsaved_changes: "Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?"
    },
    navigation: {
      next: "Siguiente",
      no_more_results: "El número de página %{page} está fuera de los límites. Pruebe la página anterior.",
      no_results: "No se han encontrado resultados",
      page_out_from_begin: "No puede ir antes de la página 1",
      page_out_from_end: "No puede ir después de la última página",
      page_out_of_boundaries: "Número de página %{page} fuera de los límites",
      page_range_info: "%{offsetBegin} - %{offsetEnd} de %{total}",
      page_rows_per_page: "Filas por página:",
      prev: "Anterior",
      skip_nav: "Saltear navegación"
    },
    sort: {
      sort_by: "Ordenar por %{field} %{order}",
      ASC: "ascendente",
      DESC: "descendente",
    },
    notification: {
      bad_item: "Elemento incorrecto",
      canceled: "Acción cancelada",
      created: "Elemento creado",
      data_provider_error: "Error del proveedor de datos. Consulte la consola para más detalles.",
      deleted: "Elemento borrado |||| %{smart_count} elementos borrados.",
      http_error: "Error de comunicación con el servidor",
      item_doesnt_exist: "El elemento no existe",
      logged_out: "Su sesión ha finalizado, vuelva a conectarse.",
      updated: "Elemento actualizado |||| %{smart_count} elementos actualizados",
      i18n_error: "No se pudieron cargar las traducciones para el idioma especificado"
    },
    page: {
      create: "Crear %{name}",
      dashboard: "Tablero",
      edit: "%{name} #%{id}",
      empty: "Sin %{name} todavía.",
      error: "Algo salió mal",
      invite: "¿Quiere agregar una?",
      list: "Lista de %{name}",
      loading: "Cargando",
      not_found: "No encontrado",
      show: "%{name} #%{id}"
    },
    validation: {
      email: "Debe ser un correo electrónico válido",
      maxLength: "Debe contener %{max} caracteres o menos",
      maxValue: "Debe ser %{max} o menos",
      minLength: "Debe contener %{min} caracteres al menos",
      minValue: "Debe ser al menos %{min}",
      number: "Debe ser un número válido",
      oneOf: "Debe ser uno de: %{options}",
      regex: "Debe coincidir con un formato específico (regexp): %{pattern}",
      required: "Requerido"
    },
    menu:{
      list: 'Listado',
      edit: 'Editar',
      show: 'Ver'
    },
    home:{
      title: "Inicio",
    },
    resources:{
      users:{
        name: "Usuarios",
        create: "Usuario guardado correctamente",
        edit: "Usuario actualizado correctamente",
        errorPassword: "Verifique que las contraseñas conicidan",
        errorPasswordLength: "La contraseña debe tener al menos 6 caracteres",
      },
      roles:{
        name: "Roles",
        create: "Rol guardado correctamente",
        edit: "Rol actualizado correctamente",
      },
      config:{
        name: "Configuración",
        create: "Configuración guardada correctamente",
        edit: "Configuración actualizado correctamente",
      },
      jobs:{
        name: "Trabajos",
        create: "Trabajo guardado correctamente",
        edit: "Trabajo actualizado correctamente",
      },
      clients:{
        name: "Clientes",
        create: "Cliente guardado correctamente",
        edit: "Cliente actualizado correctamente",
      },
      payments:{
        name: "Cobros",
        create: "Cobro guardado correctamente",
        edit: "Cobro actualizado correctamente",
      },
      settings:{
        name: "Mi perfil",
        create: "Datos actualizados correctamente",
      },
      profesionalServices:{
        messageFailDelete: "Error al eliminar el servicio",
        messageDelete: "Servicio eliminado con exito",
      },
    },
    register:{
        title: '¡Bienvenido!',
        name: 'Nombre',
        email: 'E-mail',
        phone: 'Teléfono',
        password: 'Ingrese una contraseña',
        password_confirm: 'Confirme la contraseña',
        password_mismatch: 'Las contraseñas no coinciden',
        sign_in: "confirmar y registrarme",
    },
    forgot:{
      title: 'Recuperar contraseña',
      subtitle: 'Ingrese su email para poder realizar el cambio.',
      email: 'E-mail',
      button: 'Recuperar contraseña',
      password: 'Ingrese una contraseña',
      password_confirm: 'Confirme la contraseña',
    },
    reset:{
      title: 'Cambiar contraseña',
      subtitle: 'Ingrese su nueva contraseña.',
      button: 'Guardar',
    }
  }
};
export default spanishMessages;
