import { Add } from "@mui/icons-material";
import {
  Button,
  Typography,
  Box,
  InputAdornment,
  Divider,
} from "@mui/material";
import { Fragment, useState } from "react";
import {
  Create,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  useGetOne,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  Confirm,
  useGetList,
  useRedirect,
  useNotify,
  required,
  useCreateContext,
} from "react-admin";
import { customPalette } from "../../../themes/customTheme";
import { CustomArrayInput } from "../../../components/CustomArrayInput";
import { useFormContext, useForm, useFormState } from "react-hook-form";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { DialogServices } from "../DialogServices";
import { TableService } from "../TableService";
import { PopUpCliente } from "../../clients/PopUpClientCreate";
import { formatter } from "../../../utils/NumberFormatter";
import { SaveAndCloseButton } from "../SaveAndCloseButton";
import CustomNumberInput from "../../../components/CustomNumberInput";

const useStyles = makeStyles({
  h1: {
    fontWeight: "400!important",
    fontSize: "32px!important",
    lineHeight: "30px!important",
  },
  title: {
    fontWeight: "700!important",
    fontSize: "24px!important",
    lineHeight: "30px!important",
  },
  secondaryTitle: {
    fontWeight: "500!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
  },
});

const slug = (elem) => {
  return elem?.toLowerCase().split(" ").join("_");
};

const getLocations = (establishments) => {
  let arr = [];
  establishments?.forEach((element) => {
    arr.push(element.location);
  });
  let unique = [...new Set(arr.map((data) => data))];
  let res = [];
  unique.forEach((elem) => {
    res.push({ id: slug(elem), name: elem });
  });
  return res;
};

const SelectLocation = ({ id }) => {
  const { data: client } = useGetOne("clients", { id: id });
  const establishments = client?.establishments || [];
  const locations = getLocations(establishments);
  const { setValue } = useFormContext();
  const handleChange = (value) => {
    setValue(
      "location",
      establishments?.find((i) => i.id == value)?.location || undefined
    );
  };

  return (
    <Box display="flex" width="100%">
      <SelectInput
        source="establishment_id"
        label="Establecimiento"
        choices={establishments}
        sx={{ marginRight: "24px", minWidth: "266px" }}
        onChange={(e) => handleChange(e.target.value)}
      />
      <SelectInput
        sx={{ minWidth: "266px" }}
        source="location"
        label="Localidad"
        choices={locations}
        optionValue="name"
      />
    </Box>
  );
};

const CustomClient = () => {
  const classes = useStyles();
  const client = useWatch({ name: "client_id" });
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const { clearErrors } = useForm();

  const handleCreate = (id) => {
    clearErrors("client_id");
    setValue("client_id", id, { shouldValidate: true, shouldTouch: true });
  };

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ marginBottom: "1em", minWidth: "556px" }}
      >
        <Typography className={classes.title}>Cliente</Typography>
        <Button
          sx={{ fontWeight: 500 }}
          startIcon={<Add sx={{ color: `${customPalette.main}` }} />}
          onClick={() => setOpen(true)}
        >
          Nuevo Cliente
        </Button>
      </Box>
      <ReferenceInput
        source="client_id"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="name"
          label="Nombre y Apellido"
          filterToQuery={(q) => ({ qname: q })}
          sx={{ minWidth: "556px" }}
          validate={[required()]}
        />
      </ReferenceInput>
      {client && <SelectLocation id={client} />}
      <PopUpCliente
        open={open}
        handleClose={() => setOpen(false)}
        handleCreated={handleCreate}
      />
    </Fragment>
  );
};

const Total = () => {
  const { setValue } = useFormContext();
  const expenses = useWatch({ name: "expenses" });
  const services = useWatch({ name: "services" });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (services) {
      let res = services?.reduce((acc, i) => {
        return acc + i?.kg;
      }, 0);
      setValue("kg", res);
    } else {
      setValue("kg", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  const getTotal = () => {
    let expenses_acc =
      expenses?.reduce((acc, item) => {
        return acc + Number(item?.amount || 0);
      }, 0) || 0;
    let services_acc =
      services?.reduce((acc, item) => {
        return acc + Number(item?.amount || 0);
      }, 0) || 0;
    return expenses_acc + services_acc;
  };

  useEffect(() => {
    let result = getTotal();
    setValue("total", result);
    setTotal(result);
  }, [expenses, services]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        border: "1px solid #BFBFBF",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          color: "#818181",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        TOTAL DEL SERVICIO
      </Typography>
      <Typography
        sx={{
          color: "#343434",
          fontSize: "26px",
          lineHeight: "30px",
          fontWeight: 700,
        }}
      >
        {`$${formatter.format(total)}`}
      </Typography>
    </Box>
  );
};

const Customtoolbar = () => {
  let navigate = useNavigate();
  const {saving} = useCreateContext();
  const {dirtyFields, isSubmitting} = useFormState();
  const isDirty = Object.keys(dirtyFields).length > 0;
  return (
    <Toolbar
      sx={{
        padding: "16px 24px",
        boxSizing: "border-box",
        background: "#F0F0F0",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button
        startIcon={<Close />}
        onClick={() => navigate(-1)}
        disabled={saving}
      >
        Cancelar
      </Button>
      <Box>
        <SaveButton
          variant="outlined"
          icon={<></>}
          disabled={saving}
          sx={{
            marginRight: "1em"
          }}
        />
        <SaveAndCloseButton disabledByForm={saving || !isDirty || isSubmitting}/>
      </Box>
    </Toolbar>
  );
};

const Services = () => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { setValue } = useFormContext();
  const { data: prof_services } = useGetList("professional-services", {sort: { field: "name"}});

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  const handleSave = (newitem) => {
    let copy = [...values];
    if (index !== null) {
      copy[index] = newitem;
    } else {
      copy.push(newitem);
    }
    setValues(copy);
    setValue("services", copy);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    if (index !== null) {
      setData(values[index]);
      setIndex(index);
      setOpen(true);
    }
  };
  const handleDelete = () => {
    let copy = [...values];
    copy.splice(index, 1);
    setValues(copy);
    setIndex(null);
    setOpenConfirm(false);
    setValue("services", copy);
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index - 1);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <Typography
        className={classes.title}
        sx={{ marginRight: "24px", marginBottom: "24px" }}
      >
        Servicios
      </Typography>
      <TableService
        rows={values}
        services={prof_services}
        handleEdit={handleEdit}
        handleConfirm={handleConfirm}
      />
      <Button
        variant="outlined"
        size="medium"
        sx={{ fontWeight: 500 }}
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        Agregar Servicio
      </Button>
      <DialogServices
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        services={prof_services}
        data={data}
      />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el establecimiento?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};

const Fields = ({ getSource }) => {
  const total = useWatch({ name: "total" });
  const { setValue } = useFormContext();
  const { clearErrors} = useForm();
 

  const handleChange = (e, label) => {
    clearErrors("veterinary_fees"); //dont work
    let value = e.target.value;
    if (label == "percentage") {
      let res = total * (value / 100);
      setValue(
        `${getSource("amount")}`,
        res == 0 ? undefined : res?.toFixed(2)
      );
    }
    if (label == "amount" && total) {
      let res = (value * 100) / total;
      setValue(
        `${getSource("percentage")}`,
        res == 0 ? undefined : res?.toFixed(2)
      );
    }
  };

  return (
    <Fragment>
      <CustomNumberInput
        source={getSource("percentage")}
        label="Porcentaje"
        sx={{ marginLeft: "1em", minWidth: "118px" }}
        max={100}
        onChange={(e) => handleChange(e, "percentage")}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <CustomNumberInput
        source={getSource("amount")}
        label="Monto"
        sx={{ marginLeft: "1em", minWidth: "150px" }}
        max={total}
        onChange={(e) => handleChange(e, "amount")}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </Fragment>
  );
};

const VeterinaryFees = () => {
  const classes = useStyles();

  return (
    <CustomArrayInput
      title="Colaboradores"
      source="veterinary_fees"
      classTitle={classes.title}
      addButtonText="Agregar Honorario"
      deleteWithText={true}
      maxWidth="600px"
      deleteColor={`${customPalette.main}`}
    >
      <FormDataConsumer>
        {({ getSource }) => (
          <Box display="flex">
            <TextInput
              source={getSource("name")}
              label="Nombre"
              sx={{ minWidth: "220px" }}
            />
            <Fields getSource={getSource} />
          </Box>
        )}
      </FormDataConsumer>
    </CustomArrayInput>
  );
};

export const validate = (values) => {
  const errors = {};
  let veterinary_fees_sum = values.veterinary_fees?.reduce(
    (acc, item) => (acc += item?.percentage || 0),
    0
  );
  if (veterinary_fees_sum > 100)
    errors.veterinary_fees = "Los colaboradores no deberían sumar más del 100%";
  if (!values?.client_id) {
    errors.client_id = "El cliente es requerido";
  }
  if (!values?.establishment_id) {
    errors.establishment_id = "El establecimiento es requerido";
  }
  if (values?.observations?.length > 255) {
    errors.observations =
      "La observación es demasiado larga máximo 255 caracteres";
  }
  return errors;
};

export const JobsCreate = () => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.resources.jobs.create", { type: "info" });
    redirect("/jobs");
  };

  return (
    <Create title=" " mutationOptions={{ onSuccess }}>
      <SimpleForm
        toolbar={<Customtoolbar />}
        validate={validate}
        mode="all"
        reValidateMode="onChange"
      >
        <Typography className={classes.h1} sx={{ marginBottom: "1em" }}>
          Nuevo trabajo
        </Typography>
        <CustomClient />
        <Divider flexItem sx={{ margin: "4px 0 24px 0" }} />
        <Services />
        <Box mb="24px" mt="24px">
          <CustomArrayInput
            title="Gastos"
            classTitle={classes.secondaryTitle}
            source="expenses"
            addButtonText="Agregar Gasto"
            deleteWithText={true}
            maxWidth="500px"
            deleteColor={`${customPalette.main}`}
          >
            <FormDataConsumer>
              {({ getSource }) => (
                <Box display="flex">
                  <TextInput source={getSource("name")} label="Gasto" />
                  <CustomNumberInput
                    source={getSource("amount")}
                    label="Monto"
                    sx={{ marginLeft: "1em" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </CustomArrayInput>
        </Box>
        <Total />
        <Divider flexItem sx={{ margin: "24px 0" }} />
        <VeterinaryFees />
        <Divider flexItem sx={{ margin: "24px 0" }} />

        <Typography
          sx={{
            fontSize: "24px",
            lineHeight: "30px",
            fontWeight: 500,
            marginBottom: "16px",
          }}
        >
          Observaciones
        </Typography>
        <TextInput
          multiline
          source="observations"
          label=""
          rows={4}
          defaultValue={""}
          variant="filled"
          sx={{
            width: "520px",
            "& .MuiFilledInput-root": { padding: "16px!important" },
            marginBottom: "32px",
          }}
        />
      </SimpleForm>
    </Create>
  );
};
