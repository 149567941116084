import { useState } from "react";
import { Typography, Box } from "@mui/material";
import {
  Datagrid,
  List,
  NumberField,
  TopToolbar,
  TextField,
  Confirm,
  useRecordContext,
  useNotify,
  useRefresh,
  FunctionField,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { CustomPagination } from "../../../components/Pagination";
import { DialogAnimalPrice } from "./DialogAnimal";
import { PopUpService } from "./PopUpService";
import { Fragment } from "react";
import { dataProvider } from "../../../providers/dataProvider";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles({
  customAlign: {
    "& .MuiTableCell-root": {
      textAlign: "left",
    },
    "& .MuiTableCell-root:last-child": {
      textAlign: "right",
    },
  },
});

const ProfessionalActions = () => {
  return (
    <TopToolbar
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "24px",
          color: "#232323",
        }}
      >
        Servicios profesionales
      </Typography>
      <Box display="flex">
        <PopUpService />
        <DialogAnimalPrice />
      </Box>
    </TopToolbar>
  );
};

const DeleteService = () => {
  const [open, setOpen] = useState();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleDelete = () => {
    dataProvider
      .delete("professional-services", { id: record.id })
      .then((resp) => {
        if (resp?.data) {
          setOpen(false);
          notify("ra.resources.profesionalServices.messageDelete", {
            type: "success",
          });
          refresh();
        } else {
          notify("ra.resources.profesionalServices.messageFailDelete", {
            type: "warning",
          });
        }
      });
  };

  const handleCloseConfirm = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        sx={{ color: "red" }}
        startIcon={<DeleteIcon />}
      ></Button>
      <Confirm
        isOpen={open}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el servicio?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};

const Kg = () => {
  const record = useRecordContext();
  if (record?.only_price) return null;
  return <NumberField source="quantity" label="Kg novillo" />;
};

export const ProfessionalList = () => {
  const classes = useStyles();
  return (
    <List
      title=" "
      filters={null}
      empty={false}
      actions={<ProfessionalActions />}
      sort={{ field: "name", order: "ASC" }}
      pagination={<CustomPagination />}
    >
      <Datagrid
        rowClick={null}
        bulkActionButtons={false}
        className={classes.customAlign}
      >
        <TextField source="name" label="Servicio" />
        <Kg label="Kg novillo" />
        <FunctionField
          render={(val) => (val?.only_price ? "Si" : "-")}
          label="Precio fijo"
        />
        <NumberField
          source="price"
          label="Precio"
          locales={"es-AR"}
          options={{ style: "currency", currency: "ARS" }}
        />
        <NumberField source="services_count" label="Servicios realizados" />
        <Box>
          <PopUpService isEdit={true} />
          <DeleteService />
        </Box>
      </Datagrid>
    </List>
  );
};
