import React, { Fragment } from "react";
import logo from "../../assets/logo_export.png";
import { makeStyles } from "@mui/styles";
import { Labeled } from "react-admin";
import { Typography, Box } from "@mui/material";
import { customPalette } from "../../themes/customTheme";
import { formatter } from "../../utils/NumberFormatter";
import { TableService } from "./TableService";
import { TableExpense } from "./TableExpense";

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "32px",
    boxSizing: "border-box",
    textAlign: "left",
    position: "relative",
  },
  logo: {
    with: "100%",
    display: " flex",
    justifyContent: "center",
    marginTop: "8px",
    marginBottom: "16px",
    "& img": {
      width: "80px",
      Height: "auto",
    },
  },
  title: {
    color: `${customPalette.main}`,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    marginTop: "16px",
  },
  subtitleText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#232323",
    marginTop: "8px",
  },
  cuil: {
    fontSize: "12px",
    fonstWeight: 400,
    lineHeight: "12px",
    marginTop: "3px",
    marginBottom: "16px",
    color: "#343434",
  },
  total: {
    color: "#343434!important",
    fontWeight: "700!important",
    fontSize: "14px!important",
    lineHeight: "18px!important",
  },
  text: {
    fontSize: "12px!important",
    fontWeight: "400!important",
    lineHeight: "14px!important",
    color: "#000000!important",
  },
  subtitle: {
    fontSize: "12px!important",
    lineHeight: "16px!important",
    fontWeight: "500!important",
    margin: "16px 0 8px 0!important",
  },
  observations: {
    color: "#818181!important",
    maxWidth: "650px!important",
    fontSize: "14px!important",
    fontWeight: "400!important",
    lineHeight: "20px!important",
  },
});

const Job = ({ data }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <span className={classes.title}>DETALLE DEL TRABAJO</span>
      <Box marginTop="8px" display="flex">
        <Labeled
          label="TOTAL DEL TRABAJO"
          sx={{
            marginBottom: 0,
            marginRight: "12px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.total}>
            ${formatter.format(data?.total || 0)}
          </Typography>
        </Labeled>
        <Labeled
          label="KG TOTALES"
          sx={{
            marginRight: "12px",
            marginBottom: 0,
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {data?.kg_total}
          </Typography>
        </Labeled>
        <Box
          sx={{
            padding: "8px",
            boxSizing: "border-box",
            minWidth: "175px",
            maxWidth: "250px",
            borderRadius: "5px",
            border: "1px solid #BFBFBF",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: `${customPalette.main}`,
              fontSize: "11px",
              lineHeight: "12px",
              fontWeight: 500,
              textTransform: "capitalize",
            }}
          >
            {data?.client?.name || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              lineHeight: "11px",
              fontWeight: 500,
            }}
          >
            {data?.establishment?.name || ""} -{data?.location || ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Veterinarian = ({ veterinarian, setting }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <span className={classes.title}>VETERINARIO</span>
      <Box display="flex" flexDirection="column">
        <span className={classes.subtitleText}>{veterinarian?.name || ""}</span>
        <span className={classes.cuil}>Cuit {setting?.cuil_cuit || ""}</span>
      </Box>
      <Box display="flex" borderBottom="1px solid #C4C4C4" maxWidth="650px">
        <Labeled
          label="TELÉFONO"
          sx={{
            marginBottom: "16px",
            marginRight: "24px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {setting?.user?.phone || ""}
          </Typography>
        </Labeled>
        <Labeled
          label="EMAIL"
          sx={{
            marginBottom: "16px",
            marginRight: "24px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {setting?.user?.email || ""}
          </Typography>
        </Labeled>
        <Labeled
          label="DIRECCIÓN"
          sx={{
            marginBottom: "16px",
            "& .RaLabeled-label": { marginBottom: 0 },
          }}
        >
          <Typography className={classes.text}>
            {setting?.address_name || ""} {setting?.address_number || ""}{" "}
            {setting?.address_floor || ""} {setting?.address_door || ""},
            {setting?.location || ""}
          </Typography>
        </Labeled>
      </Box>
    </Fragment>
  );
};

const PrintComponentJob = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { veterinarian, setting, data, services } = props;
  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.logo}>
        <img src={logo} alt="LOGO GESVET" />
      </div>
      <Veterinarian veterinarian={veterinarian} setting={setting} />
      <Job data={data} />
      <Typography className={classes.subtitle}>Servicios</Typography>
      <TableService
        services={services}
        rows={data?.services}
        hiddeAction={true}
      />
      <Typography className={classes.subtitle}>Gastos</Typography>
      <TableExpense 
        rows={data?.expenses} 
        hiddeAction={true} 
      />
      <Typography className={classes.subtitle}>Observaciones</Typography>
      <Typography className={classes.observations}>
        {data?.observations || ""}
      </Typography>
    </div>
  );
});
export default PrintComponentJob;
