import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Error, Loading, useDataProvider } from "react-admin";
import { useQuery } from "react-query";
import HelpIcon from "@mui/icons-material/Help";
import { formatter } from "../../utils/NumberFormatter";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const useStyles = makeStyles({
  container: {
    padding: "16px",
    boxSizing: "border-box",
    height: "100%",
    maxHeight: "530px",
    minWidth: "380px",
  },
  title: {
    margin: 0,
    fontWeight: 400,
    fontSize: "24px!important",
    lineHeight: "1.5!important",
    color: "#343434",
  },
  btn: {
    fontWeight: "400!important",
    opacity: 0.9,
  },
  active: {
    fontWeight: "bold!important",
    opacity: 1,
  },
  btnContainer: {
    display: "flex",
    gap:"8px",
  },
  listContainer: {
    position: "relative",
    overflow: "auto",
    maxHeight: 290,
  },
  listLi: {
    alignItems: "flex-start!important",
  },
  listItem: {
    marginTop: "6px!important",
  },
  bold:{
    "& span":{
      fontWeight:800
    }
  }
});

const debtHelperText = `La deuda se calcula a partir de los trabajos cerrados
que estén vencidos, aún sin cobrar o con cobros parciales hechos, no contenpla trabajos en progreso`;


function compare_total(a, b) {
  return a.current_account - b.current_account;
}

function compare_name(a, b) {
  const nombreA = a.name.toUpperCase();
  const nombreB = b.name.toUpperCase();

  if (nombreA < nombreB) {
    return -1;
  }
  if (nombreA > nombreB) {
    return 1;
  }
  return 0;
}

const inverse = (order) => {
  if (order == "DESC") {
    return "ASC";
  }
  return "DESC";
};

export const ClientList = () => {
  const classes = useStyles();
  const [options, setOptions] = useState({ field: "debt_account", order: "DESC" });
  const [list, setList] = useState();
  const dataProvider = useDataProvider();
  const {
    data: elements,
    isLoading,
    error,
  } = useQuery(["getDashboard", { method: "clientsdebt" }], () =>
    dataProvider.getDashboard("clientsdebt")
  );

  useEffect(() => {
    let res;
    if (typeof elements === "object") {
      let copy = JSON.parse(JSON.stringify(elements?.data));
      if (options?.field == "debt_account") {
        res = copy?.sort(compare_total);
      } 
      if (options.field == "name"){
        res = copy?.sort(compare_name);
      }
      if (options.order == "DESC") {
        res = res.reverse();
      }
      setList(res);
    }
  }, [options, elements]);

  const handleClick = (field, order) => {
    if (options.field == field) {
      setOptions({ field: field, order: inverse(options.order) });
    } else {
      setOptions({ field: field, order: order });
    }
  };
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!elements) return null;

  return (
    <Card className={classes.container}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.title}>Clientes con deuda</Typography>
        <Tooltip title={debtHelperText} sx={{ marginLeft:".3em"}}>
          <HelpIcon/>
        </Tooltip>
      </Box>
        <Typography variant="overline">Ordenar por</Typography>
      <Box className={classes.btnContainer}>
        <Button
          onClick={() => handleClick("debt_account", options.order == "ASC" ? "DESC" : "ASC")}
          className={`${classes.btn} ${options.field == "debt_account" ? classes.active :''}`}
          endIcon={ options.field == "debt_account" ? options.order == "ASC" ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/> : <i style={{ width: "20px" }}></i>}
        >
          Monto 
        </Button>
        <Button
          onClick={() => handleClick("name", options.order == "ASC" ? "DESC" : "ASC")}
          className={`${classes.btn} ${options.field == "name" ? classes.active :''}`}
          endIcon={ options.field == "name" ? options.order == "ASC" ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/> : <i style={{ width: "20px" }}></i>}
        >
          Nombre 
        </Button>
      </Box>
      <List className={classes.listContainer}>
        {list &&
          list?.length > 0 &&
          list.map((item, key) => (
            <ListItem key={key} className={classes.listLi}>
              <ListItemText
                className={`${classes.listItem} ${classes.bold}`}
                primary={item.name}
              />
              <ListItemText
                className={classes.listItem}
                primary={`$${formatter.format(item.debt_account||0)}`}
              />
            </ListItem>
          ))}
      </List>
    </Card>
  );
};
