import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    color: "#FF0000!important",
    borderColor: "#FF0000!important",
    "& .MuiButton-startIcon": {
      margin: 0,
    },
    padding: "5px",
    minWidth: "48px",
    minHeight: "48px",
  },
  buttonCustom: {
    "& .MuiButton-startIcon": {
      margin: 0,
    },
    padding: "5px",
    minWidth: "48px",
    minHeight: "48px",
  },
});

export const CustomDeleteButton = ({
  onClick,
  color,
  withText,
  noBorder = true,
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      sx={{
        border: `${
          noBorder ? "none!important" : `1px solid ${color}!important`
        }`,
      }}
      startIcon={
        <DeleteIcon
          sx={
            color
              ? {
                  color: `${color}!important`,
                }
              : { color: "inherit" }
          }
        />
      }
      className={!color ? classes.button : classes.buttonCustom}
      variant="outlined"
    >
      {withText ? "Eliminar" : ""}
    </Button>
  );
};
