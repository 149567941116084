import { List, Datagrid, TextField } from "react-admin";
import { TextInput, TopToolbar, CreateButton } from "react-admin";
import { CustomPagination } from "../../../components/Pagination";
const userFilters = [
  <TextInput label="Buscar por nombre o CUIT" source="search-custom" alwaysOn variant="filled" resettable />,
];

const ListActions = () => (
  <TopToolbar>
      <CreateButton variant="contained" size="medium" label="Nuevo Usuario"/>
  </TopToolbar>
);

export const UserList = () => {
  return (
    <List
      empty={false}
      title=" "
      sort={{ field: "name", order: "ASC" }}
      filters={userFilters}
      actions={<ListActions />}
      pagination={<CustomPagination />}
    >
      <Datagrid title="Usuarios" rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" label="Nombre" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Telefono" />
        <TextField source="settings.cuil_cuit" label="CUIL/CUIT" />
        <TextField source="services_count" label="Servicios realizados"/>
      </Datagrid>
    </List>
  );
};
