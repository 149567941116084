import {
  Card,
  Typography,
  List,
  ListItemText,
  ListItem,
  IconButton,
  Box
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { customPalette } from "../../themes/customTheme";
import { useDataProvider } from "react-admin";
import { useEffect, useState } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const useStyles = makeStyles({
  card: {
    padding: "16px",
    boxSizing: "border-box",
    height: "100%",
  },
  title: {
    margin: 0,
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: "rgba(0, 0, 0, 0.6)",
  },
  item: {
    padding: "8px 0!important",
  },
  icon: {
    fontSize: "20px!important",
    color: `${customPalette.main}`,
  },
  text: {
    "& .MuiListItemText-primary": {
      fontWeight: 500,
    },
    "& .MuiListItemText-root": {
      margin: "0!important",
    },
    margin: "0!important",
  },
  list: {
    position: "relative",
    overflow: "auto",
    maxHeight: 420,
  },
});

export const BirthDayList = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const dataProvider = useDataProvider();

  const getBirthdays = async () => {
    let res = await dataProvider.getDashboard("birthdays");
    if (res?.data) { setData(res.data); }
  };

  useEffect(() => {
    getBirthdays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>PRÓXIMOS CUMPLEAÑOS</Typography>
      {!data || data?.length == 0 ? (
        <Box height={"100%"} display="flex" justifyContent={"center"} alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center">
          <CalendarMonthIcon sx={{fontSize: '5em', color: 'gray'}}/>
          <Typography>No se encontraron cumpleaños, para el próximo mes.</Typography>
          </Box>
        </Box>
      ) : (
        <List className={classes.list}>
          {data?.map((item, index) => (
            <ListItem
              key={index}
              className={classes.item}
              secondaryAction={
                <Link to={`/clients/${item.client_id}/show`}>
                  <IconButton edge="end" aria-label="show">
                    <LaunchIcon className={classes.icon} />
                  </IconButton>
                </Link>
              }
            >
              <ListItemText
                className={classes.text}
                primary={`${item?.name} ${
                  item?.birthday
                    ? moment(new Date(item?.birthday))
                        .locale("es-AR")
                        .format("DD/MM")
                        .toLocaleString()
                    : ""
                }`}
                secondary={item?.from ? `${item.from}` : null}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
};
