import { useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../Logo";
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Form,
  Notification,
  useTranslate,
  useLogin,
  useNotify,
  PasswordInput,
  TextInput,
  Link,
} from "react-admin";
import { customPalette } from "../themes/customTheme";
import loginBg from "../assets/login-bg.jpg";
import grey from "@mui/material/colors/grey";
import { validateEmail, validateRequired } from "../utils/Validations";

const useStyles = makeStyles({
  body: {
    width: "100%",
    minHeight: "100vh",
    background: `url(${loginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "75%",
    backgroundSize: "cover",
  },
  main: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: "100%",
    maxWidth: 400,
    marginTop: "6em",
    padding: "32px",
    background: "rgba(255,255,255,1)!important",
    borderRadius: "10px",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: `${customPalette.main}`,
    "&:hover": {
      backgroundColor: `${customPalette.main}`,
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: grey[500],
  },
  head: {
    padding: "0 1em 0em 1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  form: {
    padding: "0",
  },
  actions: {
    padding: "0!important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  spacing: {
    marginRight: "1em",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#000", // Text color
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: `${customPalette.main}`,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on hover
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on focus
    },
  },
  title: {
    margin: "32px 0 16px 0",
    color: `${customPalette.main}`,
    fontSize: "20px",
    fontWeight: 400,
    textAlign: "center",
    lineHeight: "26px",
  },
  link: {
    marginTop: "16px!important",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
  },
});

const Login = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning",
          {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          }
        );
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.head}>
            <Logo height="139px" text={true} />
            <h1 className={classes.title}>{translate("ra.auth.title")}</h1>
          </div>
          <Box className={classes.form}>
            <TextInput
              name="username"
              source="username"
              label={translate("ra.auth.email")}
              disabled={loading}
              variant="filled"
              className={classes.input}
              fullWidth
              validate={validateEmail}
            />
            <PasswordInput
              name="password"
              source="password"
              variant="filled"
              label={translate("ra.auth.password")}
              disabled={loading}
              className={classes.input}
              fullWidth
              validate={validateRequired}
            />
          </Box>
          <CardActions className={classes.actions}>
            <Button
              className={classes.icon}
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress
                  size={25}
                  thickness={2}
                  className={classes.spacing}
                />
              )}
              {translate("ra.auth.sign_in")}
            </Button>
            <Link to="/recuperar-password" className={classes.link}>
              {translate("ra.auth.forgot_password")}
            </Link>
          </CardActions>
        </Card>
        <Notification />
      </Box>
    </Form>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CustomLogin = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Login {...props} />;
    </div>
  );
};

export default CustomLogin;
