import { CustomRoutes } from "react-admin";
import { Route } from "react-router";
import CustomRegister from "../components/CustomRegister";
import CustomRecover from "../components/CustomRecover";
import CustomReset from "../components/CustomReset";
import { AccountConfig } from "../resources/config/config";

export const PublicRoutes = {
  SIGNUP: "registrarse",
  RECOVER: "recuperar-password",
  RESET: "reset-password",
};

export const noLayout = () =>(
  <CustomRoutes noLayout>
  <Route path={`/${PublicRoutes.SIGNUP}`} element={<CustomRegister />} />
  <Route path={`/${PublicRoutes.RECOVER}`} element={<CustomRecover />} />
  <Route path={`/${PublicRoutes.RESET}`} element={<CustomReset />} />
</CustomRoutes>);

export const customRoutes = () =>(
<CustomRoutes>
  <Route path={"/configuracion"} element={<AccountConfig />} />
</CustomRoutes>
);


