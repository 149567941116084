import { Fragment, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  Confirm,
  useNotify,
  useRedirect,
  SelectInput,
} from "react-admin";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  isValidDate,
  validateEmail,
  validateRequired,
  validateRequiredNumbers,
} from "../../../utils/Validations";
import { useFormContext } from "react-hook-form";
import { customPalette } from "../../../themes/customTheme";
import { DialogEstablishment } from "../DialogEstablishment";
import { ShowEstablishment } from "../ShowEstablishment";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { FISCALCONDITION } from "../../constants";
import { ErrorEstablishments } from "../ErrorEstablishment";
import { validateCUITCUIL } from "../../../utils/Validations";
import CustomNumberInput from "../../../components/CustomNumberInput";

const PanelTitle = () => {
  return (
    <Typography
      variant="h1"
      sx={{
        marginBottom: "32px",
        fontSize: "32px",
        lineHeight: "30px",
        fontWeight: 400,
      }}
    >
      Nuevo Cliente
    </Typography>
  );
};

const ArrayEstablishments = () => {
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const { setValue } = useFormContext();

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  const handleSave = (newitem) => {
    let copy = [...values];
    if (index != null) {
      copy[index] = newitem;
    } else {
      copy.push(newitem);
    }
    setValues(copy);
    setValue("establishments", copy);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    setData(values[index]);
    setIndex(index);
    setOpen(true);
  };
  const handleDelete = () => {
    let copy = [...values];
    copy.splice(index, 1);
    setValues(copy);
    setIndex(null);
    setOpenConfirm(false);
    handleCloseShow();
    setValue("establishments", copy);
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  const handleOpenShow = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setData(values[index]);
    setOpenShow(true);
    setIndex(index);
  };

  const handleCloseShow = () => {
    setOpenShow(false);
  };

  return (
    <Fragment>
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "30px",
          fontWeight: 500,
          marginTop: "12px",
        }}
      >
        Establecimientos
      </Typography>
      {values?.length > 0 &&
        values?.map((item, index) => (
          <Card
            onClick={(e) => handleOpenShow(e, index)}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "400px",
              margin: ".5em 0",
              "&:hover": { cursor: "pointer" },
            }}
            elevation={2}
          >
            <CardContent>
              <Typography
                sx={{
                  color: `${customPalette.main}`,
                  fontSize: "1.2em",
                  fontWeight: 500,
                }}
              >
                {item?.name} {item?.hectares ? ` - ${item.hectares}Hc` : ""}
              </Typography>
              <Typography sx={{ color: "#000", fontWeight: 500 }}>
                {item?.location}{" "}
                {item?.distance ? ` - ${item.distance} km` : ""}
              </Typography>
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifySelf: "right",
                  alignSelf: "flexStart",
                  alignItems: "flex-start",
                  justifyItems: "flex-start",
                }}
              >
                <Button
                  onClick={(e) => handleEdit(e, index)}
                  sx={{ padding: "8px", minWidth: "24px" }}
                >
                  <Edit sx={{ color: `${customPalette.main}`, padding: 0 }} />
                </Button>
                <Button
                  onClick={(e) => handleConfirm(e, index)}
                  sx={{ padding: "8px", minWidth: "24px" }}
                >
                  <DeleteOutlinedIcon sx={{ color: "red", padding: 0 }} />
                </Button>
              </Box>
            </CardActions>
          </Card>
        ))}
      <Button
        variant="outlined"
        sx={{ fontWeight: "600", mt: "16px" }}
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        AGREGAR ESTABLECIMIENTO
      </Button>
      <DialogEstablishment
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
      />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el establecimiento?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
      <ShowEstablishment
        open={openShow}
        data={data}
        index={index}
        handleClose={handleCloseShow}
        handleDelete={handleConfirm}
        handleEdit={handleEdit}
        canEdit={true}
      />
    </Fragment>
  );
};

const cuilCuitErrorMessage = "El cuil/cuit ingresado no es correcto";

export const validate = (values) => {
  const errors = {};
  if (!values?.name) {
    errors.name = "El nombre es requerido";
  }
  if (values?.cuil_cuit && values.cuil_cuit?.length < 11) 
    errors.cuil_cuit = "Los códigos deben tener al menos 11 dígitos";
  else if (values.cuil_cuit && !validateCUITCUIL(values.cuil_cuit)){
    errors.cuil_cuit = cuilCuitErrorMessage;
  }
  if (!values?.phone) {
    errors.phone = "El télefono es requerido";
  }
  if (values?.birth_date) {
    if (!isValidDate(values.birth_date)) {
      errors.birth_date = "La fecha es inválida";
    }
  }
  if (!values?.email) {
    errors.email = "El email es requerido";
  }
  if (!values?.establishments || values?.establishments?.length == 0) {
    errors.establishments = "Debe ingresal al menos un establecimiento";
  }
  if (values?.observations?.length > 255) {
    errors.observations =
      "La observación es demasiado larga máximo 255 caracteres";
  }
  return errors;
};

export const SimpleFields = () => {
  return (
    <Fragment>
      <PanelTitle />
      <TextInput
        source="name"
        label="Nombre y Apellido"
        variant="filled"
        sx={{ minWidth: "350px" }}
        validate={validateRequired}
      />
      <Box display="flex">
        <TextInput
          source="cuil_cuit"
          label="CUIL/CUIT"
          variant="filled"
          helperText={"Solo números"}
        />
      </Box>
      <Box display="flex">
        <TextInput
          type="email"
          source="email"
          label="Email"
          variant="filled"
          validate={validateEmail}
        />
        <CustomNumberInput
          sx={{ marginLeft: "1em" }}
          type="phone"
          source="phone"
          label="Teléfono"
          variant="filled"
          validate={validateRequiredNumbers}
        />
      </Box>
      <DateInput
        source="birth_date"
        label="Fecha de nacimiento"
        variant="filled"
        sx={{ minWidth: "220px" }}
      />
      <SelectInput
        source="fiscal_condition"
        choices={FISCALCONDITION}
        label="Condición fiscal"
        variant="filled"
        emptyText="Ninguna"
        sx={{ minWidth: "220px" }}
      />
      <ArrayEstablishments />
      <ErrorEstablishments />
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "30px",
          fontWeight: 500,
          margin: "32px 0 16px 0",
        }}
      >
        Observaciones
      </Typography>
      <TextInput
        multiline
        source="observations"
        label=""
        rows={4}
        variant="filled"
        sx={{
          width: "520px",
          "& .MuiFilledInput-root": { padding: "16px!important" },
        }}
      />
    </Fragment>
  );
};

export const ClientCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.resources.clients.create");
    redirect("/clients");
  };
  return (
    <Create title=" " redirect="list" mutationOptions={{ onSuccess }}>
      <SimpleForm validate={validate} toolbar={<CustomToolbar />}>
        <SimpleFields />
      </SimpleForm>
    </Create>
  );
};
