import { Fragment, useState, useEffect } from "react";
import { Confirm, useRecordContext, useRefresh } from "react-admin";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { TablePayments } from "./TablePayments";
import { dataProvider } from "../../providers/dataProvider";

const useStyles = makeStyles({
  title: {
    fontWeight: "500!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
  },
});

export const Payments = () => {
  const classes = useStyles();
  const { payments } = useRecordContext();
  const [values, setValues] = useState(payments || []);
  const [index, setIndex] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const refresh = useRefresh();

  useEffect(() => {
    if (payments) {
      setValues(payments);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [payments]);

  const handleClose = () => {
    setIndex(null);
  };

  const handleDelete = async () => {
    let copy = [...values];
    let res = await dataProvider.delete("jobpayment", {
      id: values[index]?.id,
    });
    if (res) {
      copy.splice(index, 1);
      setValues(copy);
      setIndex(null);
      setOpenConfirm(false);
      handleClose();
      refresh();
    }
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <Box display="flex" mb="16px" mt="32px">
        <Typography className={classes.title} sx={{ marginRight: "24px" }}>
          Cobros
        </Typography>
      </Box>
      <TablePayments rows={values} handleConfirm={handleConfirm} />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el Cobro?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};
