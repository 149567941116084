import { SaveButton } from "react-admin";

const SaveEdit = ({
  resourceName,
  resourcefetch = null,
  value = "name",
  labelSave = "GUARDAR CAMBIOS",
  ...props
}) => {
  return <SaveButton label={labelSave} {...props} />;
};

export default SaveEdit;
