import { stringify } from "query-string";

import { apiUrl, httpClient } from "../utils/httpUtils";

export const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sortBy: field,
      sortType: order,
      page: page,
      size: perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      return {
        data: json,
      };
    });
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sortBy: field,
      sortType: order,
      page: page,
      size: perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: async (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ error, json }) => {
      if (!json) {
        return error;
      }
      return { data: json };
    }),

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  getAnimalPrice: async () => {
    return httpClient(`${apiUrl}/animal-prices`).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getProfesionalServices: async () => {
    return httpClient(`${apiUrl}/professional-services/all`).then(
      ({ json }) => {
        return {
          data: json,
        };
      }
    );
  },
  createAnimalPrice: async (body) => {
    return httpClient(`${apiUrl}/animal-prices`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then(({ json }) => ({
      data: { ...body, id: json.id },
    }));
  },
  getSettings: async () => {
    return httpClient(`${apiUrl}/settings`).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getJobsPending: async (id) => {
    return httpClient(`${apiUrl}/jobs/pending/${id}`).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getClientJobsPayments: async (id) => {
    return httpClient(`${apiUrl}/clients/${id}/jobspayments`).then(
      ({ json }) => {
        return {
          data: json,
        };
      }
    );
  },
  getDashboard: async (method) => {
    return httpClient(`${apiUrl}/dashboard/${method}`).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  updatePayment: async (id, body) => {
    return httpClient(`${apiUrl}/jobpayment/update-payments/${id}`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
};
