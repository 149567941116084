import { Close, Edit, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  TextField as MUIText,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useStore, useDataProvider, useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@mui/styles";
import { formatter } from "../../../utils/NumberFormatter";

const useStyles = makeStyles({
  container: {
    border: "1px solid #BFBFBF",
    background: "#fff",
    padding: ".2em .6em",
    marginLeft: "1em",
    borderRadius: "5px",
    display: "flex",
  },
  price: {
    fontWeight: 600,
    color: "black",
    fontSize: "1.2em",
    paddingLeft: "6px",
  },
  icon:{
    fontSize: "18px!important",
    marginRight: "0px!important",
    marginLeft: "10px!important",
    marginBottom: "2px",
  }
});

export const DialogAnimalPrice = ({ className = "" , disableBtnText = true}) => {
  const classes = useStyles();
  const [priceKG, setPriceKG] = useStore("priceKG", 0);
  const [values, setValues] = useState();
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClose = () => {
    setOpen(false);
  };
  async function getPrice() {
    dataProvider.getAnimalPrice().then(({ data }) => {
      setValues({ price: data?.price || 0 });
      setPriceKG(data?.price || 0);
    });
  }
  useEffect(() => {
    getPrice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResponse = ({ data: value }) => {
    if (value?.id) {
      setPriceKG(values.price);
      notify("Precio actualizado", { type: "info" });
    } else {
      notify("Error al actualizar el precio", { type: "error" });
    }
    refresh();
  };

  const handleChange = (value) => {
    setValues({ price: value });
  };

  const handleSave = () => {
    if (values?.price) {
      dataProvider
        .createAnimalPrice(values)
        .then((response) => handleResponse(response));
      handleClose();
    } else {
      notify("El precio es requerido", { type: "error" });
    }
  };
  return (
    <Fragment>
      <Box className={`${classes.container} ${className}`}>
        <Typography
          sx={{ color: "#343434", fontSize: ".9em" }}
          className="title"
        >
          PRECIO KG DE NOVILLO{" "}
          <span className={`${classes.price} price`}>
            ${formatter.format(priceKG)}
          </span>
        </Typography>
        <Button
          sx={{ padding: "0!important", minWidth: "24px", background: "none" }}
          className="editBtn"
          onClick={() => setOpen(true)}
        >
          {!disableBtnText ? "Editar" : ""}
          <Edit
            className={`${classes.icon} editIcon`}
          />
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ padding: "24px 24px 16px 24px" }}>
          Precio KG de novillo
        </DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <MUIText
            type="number"
            source="price"
            size="small"
            sx={{ width: "100%" }}
            label="Precio segun KG/carne"
            variant="filled"
            value={values?.price}
            onChange={(e) => handleChange(e.target.value)}
          />
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "32px 24px 24px 24px",
            boxSizing: "border-box",
          }}
        >
          <Button
            onClick={handleClose}
            startIcon={<Close />}
            sx={{ marginRight: "1em" }}
          >
            CANCELAR
          </Button>
          <Button onClick={handleSave} variant="contained" startIcon={<Save />}>
            GUARDAR
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
