import { Add } from "@mui/icons-material";
import { Button, Typography, Box } from "@mui/material";
import { Fragment, useState } from "react";
import { Confirm, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { DialogServices } from "./DialogServices";
import { TableService } from "./TableService";
import { useEffect } from "react";
import { dataProvider } from "../../providers/dataProvider";

const useStyles = makeStyles({
  title: {
    fontWeight: "500!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
  },
});

export const Services = () => {
  const classes = useStyles();
  const { services,status} = useRecordContext();
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { setValue } = useFormContext();
  const [pservices,setPServices] = useState([]);

  const getPservices = async () =>{
    let res = await dataProvider.getProfesionalServices();
    if (res?.data){
      setPServices(res.data);
    }
  }

  useEffect(()=>{
    getPservices();
    // eslint-disable-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    if (services){
      setValues(services);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  },[services])

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  const handleSave = (newitem) => {
    let copy = [...values];
    if (index != null) {
      copy[index] = newitem;
    } else {
      copy.push(newitem);
    }
    setValues(copy);
    setValue("services", copy);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    setData(values[index]);
    setIndex(index);
    setOpen(true);
  };
  const handleDelete = () => {
    let copy = [...values];
    copy.splice(index, 1);
    setValues(copy);
    setIndex(null);
    setOpenConfirm(false);
    handleClose();
    setValue("services", copy);
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <Box display="flex" mb="16px" mt="32px">
        <Typography className={classes.title} sx={{ marginRight: "24px" }}>
          Servicios
        </Typography>
        {status == "en_proceso" && <Button
          variant="outlined"
          size="small"
          sx={{ fontWeight: 500, fontSize: "12.3px", lineHeight: "14px" }}
          startIcon={<Add />}
          onClick={() => setOpen(true)}
        >
          Agregar Servicio
        </Button>}
      </Box>
      <TableService
        rows={values}
        services={pservices}
        handleEdit={handleEdit}
        handleConfirm={handleConfirm}
      />
      <DialogServices
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        services={pservices}
        data={data}
      />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el servicio?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};
