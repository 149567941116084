import { useFormState} from "react-hook-form";
import { Typography } from "@mui/material";

export const ErrorJob = () => {
    const { errors } = useFormState();
    return errors?.job_selected ? (
      <Typography
        sx={{
          color: "#f44336",
          fontWeight: 400,
          fontSize: "0.75rem",
          lineHeight: "1.66",
          textAlign: "left",
          marginTop: "4px",
          marginRight: "14px",
          marginBottom: 0,
          marginLeft: "14px",
        }}
      >
        {errors?.job_selected?.message}
      </Typography>
    ) : null;
  };