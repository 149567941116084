import {
  Box,
  Typography,
  Dialog,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import {
  SelectInput,
  DateInput,
  Form,
  Labeled,
  AutocompleteInput,
  Create,
} from "react-admin";
import { customPalette } from "../../themes/customTheme";
import { Save } from "@mui/icons-material";
import { ANIMALS } from "../constants";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { formatter } from "../../utils/NumberFormatter";
import { Transition } from "../../components/Transition";
import { isValidDate } from "../../utils/Validations";
import { convertDateToString } from "../../utils/utilsDateFormat";
import { dataProvider } from "../../providers/dataProvider";
import CustomNumberInput from "../../components/CustomNumberInput";

const useStyles = makeStyles({
  title: {
    fontWeight: "700!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
    flex: 1,
  },
  buttonSave: {
    backgroundColor: "#fff!important",
    color: `${customPalette.main}!important`,
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#fff!important",
      color: `${customPalette.main}!important`,
      opacity: 1,
    },
  },
  form: {
    width: "100%",
    maxWidth: "478px",
  },
});

const Resume = ({ services }) => {
  const { setValue } = useFormContext();
  const professional_service_id = useWatch({ name: "professional_service_id" });
  const animals_quantity = useWatch({ name: "animals_quantity" });
  const percentage = useWatch({ name: "percentage" });
  const adjust = useWatch({ name: "adjust" });
  const service_price = useWatch({ name: "service_price" });
  const amount = useWatch({ name: "amount" });
  const kg = useWatch({ name: "kg" });

  const [adjustment, setAdjustment] = useState(0);

  const getInitialPrice = () => {
    return services?.find((i) => i.id == professional_service_id)?.price;
  };
  const getKg = () => {
    return services?.find((i) => i.id == professional_service_id)?.quantity;
  };
  const isOnlyPrice = () => {
    return services?.find((i) => i.id == professional_service_id)?.only_price;
  }


  const getPrice = (amount) => {
    let res = amount;
    if (animals_quantity) {
      res = res * animals_quantity;
    }
    if (adjust && percentage) {
      if (adjust == "increase") {
        res = res + res * (percentage / 100);
      } else if (adjust == "discount") {
        res = res - res * (percentage / 100);
      }
    }
    return res;
  };

  useEffect(() => {
    if (professional_service_id) {
      let initial_price = getInitialPrice();
      let calc = initial_price * animals_quantity * (percentage / 100);
      if (isNaN(calc)) {
        setAdjustment(0);
      } else {
        setAdjustment(calc);
      }

      if (initial_price) {
        setValue("service_price", initial_price);
      }
      let total = getPrice(initial_price);
      if (total) {
        setValue("amount", total);
      }
      let kg = getKg();
      setValue("kg", kg);
      setValue("only_price", isOnlyPrice());
    }
    if (adjust == null || adjust == "") {
      setValue("percentage", 0);
    } else {
      if (!professional_service_id) {
        setValue("kg", 0);
        setValue("amount", 0);
        setValue("service_price", 0);
      }
    }
  }, [professional_service_id, percentage, adjust, animals_quantity]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box
      sx={{
        border: "1px solid #BFBFBF",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#343434",
          marginBottom: "8px",
        }}
      >
        RESUMEN DEL SERVICIO
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Labeled
          label="Servicio"
          sx={{
            marginRight: "24px",
            fontWeight: 400,
            fontSize: "14px",
            lineFeight: "20px",
            color: "#818181",
            marginBottom: 0,
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "30px",
              color: "#343434",
            }}
          >
            {`$${service_price || 0}`}{" "}
          </Typography>
          {/* TODO agregar kg del servicio y guardar los kg actuales en historico, guardar KG  */}
        </Labeled>
        {!isOnlyPrice() ? (
          <Labeled
            label="Kg animal"
            sx={{
              marginRight: "24px",
              fontWeight: 400,
              fontSize: "14px",
              lineFeight: "20px",
              color: "#818181",
              marginBottom: 0,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
                color: "#343434",
              }}
            >{`${kg || 0}`}</Typography>
          </Labeled>
        ) : null}
        {adjustment > 0 ? <Adjust price={adjustment} /> : null}
        <Labeled
          label="Subtotal"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineFeight: "20px",
            color: "#818181",
            marginBottom: 0,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "30px",
              color: "#343434",
            }}
          >{`$${formatter.format(amount || 0)}`}</Typography>
        </Labeled>
      </Box>
    </Box>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.date) {
    errors.date = "La fecha es requerida";
  }
  if (values.date) {
    if (!isValidDate(values.date)) {
      errors.date = "La fecha es inválida";
    }
  }
  if (!values.professional_service_id) {
    errors.professional_service_id = "El servicio es requerido";
  }
  // if (!values.animal_type) {
  //   errors.animal_type = "El tipo de animal es requerido";
  // }
  if (!values.animals_quantity) {
    errors.animals_quantity = "La cantidad es requerida";
  }
  if (values.percentage && !values.adjust) {
    errors.adjust = "Seleccione un tipo de ajuste";
  }
  if (!values.percentage && values.adjust) {
    errors.percentage = "Ingrese un porcentaje";
  }
  return errors;
};

const Adjust = ({ price = 0 }) => {
  const adjust = useWatch({ name: "adjust" });

  return (
    <Labeled
      label={
        adjust == "increase"
          ? "Recargo"
          : adjust == "discount"
            ? "Descuento"
            : "Ajuste"
      }
      sx={{
        marginRight: "24px",
        fontWeight: 400,
        fontSize: "14px",
        lineFeight: "20px",
        color: "#818181",
        marginBottom: 0,
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "30px",
          color: "#343434",
        }}
      >
        {price.toFixed(2)}
      </Typography>
    </Labeled>
  );
};

const AnimalTypeInput = ({services}) => {
  const professional_service_id = useWatch({ name: "professional_service_id" });
  const isOnlyPrice = () => {
    return services?.find((i) => i.id == professional_service_id)?.only_price;
  }
  return (
    <SelectInput
      source="animal_type"
      label="Tipo de animal"
      variant="filled"
      emptyText=" "
      sx={{ marginTop: 0, marginBottom: 0, minWidth: "200px" }}
      choices={ANIMALS}
      disabled={isOnlyPrice()}
    />
  )
}

const PriceKg = ({ priceKg, services }) => {
  const { setValue } = useFormContext();
  const professional_service_id = useWatch({ name: "professional_service_id" });
  const isOnlyPrice = () => {
    return services?.find((i) => i.id == professional_service_id)?.only_price;
  }

  const handleChange = (value) => {
    if (priceKg && value != priceKg) {
      let adjust = "increase";
      let percentage = 0;
      percentage = (value * 100) / priceKg - 100;
      if (percentage < 0) {
        adjust = "discount";
        percentage = -1 * percentage;
      }
      setValue("adjust", adjust);
      setValue("percentage", percentage.toFixed(2));
    } else {
      if (priceKg && value == priceKg) {
        setValue("percentage", 0);
        setValue("adjust", null);
      }
    }
    setValue("price_kg_res", value);
  };

  if (isOnlyPrice()) return null;

  return (
    <Box>
      <CustomNumberInput
        source="price_kg_res"
        label="Precio Kg carne"
        onChange={(e) => handleChange(e.target.value)}
        defaultValue={priceKg}
        min={0}
      />
    </Box>
  );
};

const FormServices = ({ handleSave, handleClose, data, services }) => {
  const classes = useStyles();
  const [priceKg, setPriceKG] = useState();
  const [loading, setLoading] = useState(false);

  async function getPrice() {
    dataProvider.getAnimalPrice().then(({ data }) => {
      setPriceKG(data?.price || 0);
    });
  }
  useEffect(() => {
    getPrice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSubmit = (values) => {
    setLoading(true);
    handleSave(values);
    handleClose();
  };

  return (
    <Create sx={{ ".RaCreate-noActions": { marginTop: 0 } }}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={data}
        className={classes.form}
        validate={validate}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{
            padding: "16px 16px 22px 16px",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" className={classes.title}>
            {!data ? "Agregar Servicio" : "Editar Servicio"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            disabled={loading}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={"0 16px"}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ margin: "0 auto", maxWidth: "575px" }}
          >
            <Box display="flex">
              <DateInput
                source="date"
                label="Fecha realización"
                variant="filled"
                defaultValue={convertDateToString(new Date())}
              />
            </Box>

            <AutocompleteInput
              source="professional_service_id"
              label="Tipo de servicio"
              choices={services}
              optionValue="id"
              optionText="name"
              emptyText="Ninguna"
              sx={{ maxWidth: "300px", marginTop: 0, marginBottom: 0 }}
            />
            <PriceKg priceKg={priceKg} services={services} />
            <Box display="flex" alignItems="center">
              <AnimalTypeInput services={services}/>
              <CustomNumberInput
                source="animals_quantity"
                label="Cantidad"
                variant="filled"
                sx={{ marginLeft: "16px", marginBottom: "0px", width: "196px" }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <SelectInput
                source="adjust"
                label="Ajuste"
                variant="filled"
                emptyText="Sin ajuste"
                sx={{ marginTop: 0, marginBottom: 0, minWidth: "200px" }}
                choices={[
                  { id: "discount", name: "Descuento" },
                  { id: "increase", name: "Recargo" },
                ]}
              />
              <CustomNumberInput
                source="percentage"
                label="Porcentaje"
                variant="filled"
                sx={{ marginLeft: "16px", marginBottom: "2px", width: "196px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Resume services={services} />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{ padding: "16px", boxSizing: "border-box" }}
        >
          <Button
            onClick={handleClose}
            startIcon={<CloseIcon sx={{ color: `${customPalette.main}` }} />}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button 
            type="submit"
            variant="contained"
            startIcon={<Save />}
            disabled={loading}
          >
            {!data ? "Agregar Servicio" : "Guardar Cambios"}
          </Button>
        </Box>
      </Form>
    </Create>
  );
};

export const DialogServices = ({
  open,
  handleClose,
  handleSave,
  data,
  services,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="xs"
    >
      <FormServices
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
        services={services}
      />
    </Dialog>
  );
};
