import { Admin } from "react-admin";
import adminProps from "./config/admin";
import { Resources } from './router/Resources';
import { customRoutes, noLayout } from "./router/customRoutes";
import { Dashboard } from "./views/dashboard/dashboard";

const App = () => {
  return (
    <Admin {...adminProps} dashboard={Dashboard}>
      {() => Resources()}
      {customRoutes()}
      {noLayout()}
    </Admin>
  );
};

export default App;
