export const hasPermission = (name) => {
  const jsonpermissions = localStorage.getItem('permissions');
  const permissions = JSON.parse(jsonpermissions);
  if (permissions && (permissions?.value.some(permission => permission.name === '*' || permission.name === name))) {
    return true;
  }
  return false;
}

export const hasRole = (name) => {
  const jsonuser = localStorage.getItem('user');
  const roles = JSON.parse(jsonuser)?.roles || [];
  if (roles?.includes(name)) {
    return true;
  }
  return false;
}
