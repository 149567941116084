/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Card, CardActions, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Notification,
  useTranslate,
  useNotify,
  PasswordInput,
  TextInput,
  useRedirect,
  required,
  email,
  LinearProgress,
  FormDataConsumer,
  SimpleForm,
  Link,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { customPalette } from "../themes/customTheme";
import { grey } from "@mui/material/colors";
import loginBg from "../assets/login-bg.jpg";
import Logo from "../Logo";
import CustomNumberInput from "./CustomNumberInput";

const useStyles = makeStyles((theme) => ({
  body:{
    width: '100%',
    minHeight: '100vh',
    background: `url(${loginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "75%",
    backgroundSize: "cover",
  },
  main: {
    display: "flex",
    width: '100%',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: '100%',
    maxWidth: 400,
    marginTop: "6em",
    padding: "32px",
    background: "rgba(255,255,255,1)!important",
    borderRadius: "10px",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: `${customPalette.main}`,
    "&:hover": {
      backgroundColor: `${customPalette.main}`,
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: grey[500],
  },
  head:{
    padding: "0 1em 0em 1em",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  form: {
    padding: "0",
  },
  actions: {
    padding: "0!important",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spacing: {
    marginRight: "1em",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#000", // Text color
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: `${customPalette.main}`,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on hover
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on focus
    },
  },
  title: {
    margin: "24px 0 16px 0",
    color: `${customPalette.main}`,
    fontSize: "20px",
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: "22px",
  },
  link:{
    marginTop: '1em!important',
  }
}));

const SimpleFields = ({ handleSubmit, loading }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.head}>
                <Logo height="139px" text={true} />
                <h1 className={classes.title}>
                  {translate("ra.register.title")}
                </h1>
              </div>
              <div className={classes.form}>
                <TextInput
                  name="name"
                  source="name"
                  label={translate("ra.register.name")}
                  disabled={loading}
                  className={classes.input}
                  validate={required()}
                  fullWidth
                />
                <TextInput
                  name="email"
                  source="email"
                  label={translate("ra.register.email")}
                  disabled={loading}
                  className={classes.input}
                  validate={[email(), required()]}
                  fullWidth
                />
                <CustomNumberInput
                  name="phone_number"
                  source="phone_number"
                  label={translate("ra.register.phone")}
                  disabled={loading}
                  className={classes.input}
                  fullWidth
                />
                <PasswordInput
                  name="password"
                  source="password"
                  label={translate("ra.register.password")}
                  disabled={loading}
                  className={classes.input}
                  validate={required()}
                  fullWidth
                />
                <PasswordInput
                  name="password_confirm"
                  source="password_confirm"
                  label={translate("ra.register.password_confirm")}
                  disabled={loading}
                  className={classes.input}
                  validate={required()}
                  fullWidth
                />
              </div>
              <CardActions className={classes.actions}>
                <Button
                  className={classes.icon}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(formData);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      className={classes.spacing}
                    />
                  )}
                  {translate("ra.register.sign_in")}
                </Button>
                <Link className={classes.link} to="/login" >Iniciar sesión</Link>
              </CardActions>
            </Card>
            <Notification />
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

const Register = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const { search } = useLocation();
  const [client, setClient] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      const client_id = query.get("client_id");
      if (client_id) {
        setClient(Number(client_id));
      }
    }
  }, [search]);

  async function getClient() {
    setLoading(true);

    try {
      const url = process.env.REACT_APP_API_HOST_ADMIN + `/clients/${client}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setLoading(false);
          if (!res.ok) {
            return null;
          }
          return res.json();
        })
        .then((json) => {
          if (json) {
            setData(json);
          } else {
            setData(null);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (client) {
      getClient();
    }
  }, [client]);

  if (!client && !data) return <LinearProgress />;

  const handleSubmit = async (values) => {
    setLoading(true);
    if (
      values?.password === values?.password_confirm &&
      values?.password?.length >= 6
    ) {
      try {
        const url = process.env.REACT_APP_API_HOST_ADMIN + `/clients/${client}`;
        fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (!json?.error) {
              notify("Sus datos han sido guardados correctamente", "info");
              setTimeout(redirect("login"), 5000);
            } else {
              setLoading(false);
              notify(json?.error, "warning");
            }
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      setLoading(false);
      if (values?.password?.length <= 6) {
        notify(translate("ra.validation.minPassword", { min: 6 }), "warning");
      } else {
        notify(translate("ra.validation.wrong_password"), "warning");
      }
    }
  };

  return (
    <div className={classes.body}>
      <SimpleForm initialValues={data} toolbar={null}>
        <SimpleFields handleSubmit={handleSubmit} loading={loading} />
      </SimpleForm>
    </div>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CustomRegister = (props) => <Register {...props} />;

export default CustomRegister;
