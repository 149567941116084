/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Card, CardActions, CircularProgress, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Notification,
  useTranslate,
  useNotify,
  TextInput,
  useRedirect,
  required,
  email,
  FormDataConsumer,
  PasswordInput,
  SimpleForm,
  minLength,
} from "react-admin";
import AppBarOnly from "../layouts/AppBarOnly";
import { useLocation } from "react-router-dom";
import { customPalette } from "../themes/customTheme";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "660px",
    background: `linear-gradient(${customPalette.main} 50%, transparent 50%)`,
    backgroundRepeat: "no-repeat",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: '0 auto'
  },
  card: {
    maxWidth: 400,
    marginTop: "6em",
    padding: "32px 16px",
    background: "rgba(255,255,255,1)!important",
    borderRadius: "10px",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: `${customPalette.main}`,
    "&:hover": {
      backgroundColor: `${customPalette.main}`,
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: grey[500],
  },
  form: {
    padding: "0",
  },
  actions: {
    padding: "0 1em 1em 1em!important",
  },
  spacing: {
    marginRight: "1em",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#000", // Text color
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: `${customPalette.main}`,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on hover
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on focus
    },
  },
  title: {
    margin: "0 0 .2em 0",
    color: "#363636",
    fontSize: "26px",
    lineHeight: "30px",
  },
  subtitle: {
    fontSize: "14px",
    lineHeight: "16px",
  },
}));

const SimpleFields = ({ handleSubmit, loading }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.form}>
                <h1 className={classes.title}>
                  {translate("ra.reset.title")}
                </h1>
                <span className={classes.subtitle}>
                  {translate("ra.reset.subtitle")}
                </span>
              </div>
              <div className={classes.form}>
                <TextInput
                  name="email"
                  source="email"
                  label={translate("ra.forgot.email")}
                  disabled={true}
                  className={classes.input}
                  validate={[email(), required()]}
                  fullWidth
                />
                 <PasswordInput
                  name="password"
                  source="password"
                  label={translate("ra.forgot.password")}
                  disabled={loading}
                  className={classes.input}
                  validate={[minLength(6,"La contraseña debe tener al menos 6 caracteres"),required()]}
                  fullWidth
                />
                <PasswordInput
                  name="password_confirmation"
                  source="password_confirmation"
                  label={translate("ra.forgot.password_confirm")}
                  disabled={loading}
                  className={classes.input}
                  validate={required()}
                  fullWidth
                />
              </div>
              <CardActions className={classes.actions}>
                <Button
                  className={classes.icon}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(formData);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      className={classes.spacing}
                    />
                  )}
                  {translate("ra.reset.button")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

const Reset = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { search } = useLocation();
  const [ apitoken,setApiToken] = useState();
  const [value,setValue] = useState();
  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      const token = query.get("token");
      const email = query.get('email');
      if (email){
        setValue({email: email});
      }
      if (token) {
        setApiToken(token);
      }
      else{
        setApiToken(localStorage.getItem('token'));
      }
    }
  }, [search]);


  const handleSubmit = async (values) => {
    setLoading(true);
    if (values?.email) {
      try {
        const url = process.env.REACT_APP_API_HOST_ADMIN + `/auth/reset-password`;
        const body = JSON.stringify({...values, token : apitoken});
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:body,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (!json?.error) {
              if (json?.data){
                notify(json.data, "info");
                setLoading(false);
                setTimeout(redirect("/login"), 8000);
              }
              else {
                setLoading(false);
                const message = Object.values(json.message).join(" ");
                notify(message,'info');
              }
            } else {
              setLoading(false);
              notify(json?.error, "warning");
            }
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      setLoading(false);
      notify(translate("ra.validation.email"), "warning");
    }
  };
  
  if (!value) return <LinearProgress />;
 
  return (
    <div className={classes.body}>
      <AppBarOnly />
      <SimpleForm defaultValues={value} toolbar={null} redirect={undefined}>
        <SimpleFields handleSubmit={handleSubmit} loading={loading} />
      </SimpleForm>
    </div>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CustomReset = (props) => <Reset {...props} />;

export default CustomReset;
