import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { customPalette } from "../../themes/customTheme";
import { formatter } from "../../utils/NumberFormatter";
import { PAYMENTMETHOD } from "../constants";
import moment from "moment";

export const TablePayments = ({ rows, hiddeAction = false }) => {
  if (!rows || rows?.length == 0) return null;
  return (
    <TableContainer
      sx={{
        "& .MuiTable-root": {
          "& .MuiTableHead-root": {
            backgroundColor: "#FBFBFF",
            "& .MuiTableCell-root": {
              color: `${customPalette.main}`,
              fontWeight: "500!important",
            },
          },
          "& .MuiTableRow-root:last-child > .MuiTableCell-root": {
            border: "none!important",
          },
          "& .MuiTableCell-root": {
            color: `${customPalette.main}`,
            fontSize: "12px!important",
            lineHeight: "14px!important",
          },
        },
        marginBottom: "16px",
      }}
    >
      <Table
        sx={{ minWidth: "582px", width:"100%" }}
        size="small"
        aria-label="Tabla Cobros"
      >
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Concepto</TableCell>
            <TableCell>Forma de Cobro</TableCell>
            <TableCell align="left">Monto</TableCell>
            <TableCell>Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index = 0) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{`${
                moment(row.date).format("DD/MM/YYYY") || ""
              }`}</TableCell>
              <TableCell>{row.concept == "job" ? "Trabajo" : row.concept == "payment" ? "Cobro" : "Resto Cobro"}</TableCell>
              <TableCell>
                {PAYMENTMETHOD.find((i) => i.id == row?.payment_method)?.name ||
                  ""}
              </TableCell>
              <TableCell align="left">{`$${formatter.format(
                row.amount || 0
              )}`}</TableCell>
              <TableCell>{`$${formatter.format(row.subtotal || 0)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
