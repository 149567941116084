/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Card, CardActions, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Notification,
  useTranslate,
  useNotify,
  TextInput,
  required,
  email,
  FormDataConsumer,
  SimpleForm,
  Link,
} from "react-admin";
import { customPalette } from "../themes/customTheme";
import { grey } from "@mui/material/colors";
import loginBg from "../assets/login-bg.jpg";
import Logo from "../Logo";

const useStyles = makeStyles((theme) => ({
  body:{
    width: '100%',
    minHeight: '100vh',
    background: `url(${loginBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "75%",
    backgroundSize: "cover",
  },
  main: {
    display: "flex",
    width: '100%',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: '100%',
    maxWidth: 400,
    marginTop: "6em",
    padding: "32px",
    background: "rgba(255,255,255,1)!important",
    borderRadius: "10px",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    backgroundColor: `${customPalette.main}`,
    "&:hover": {
      backgroundColor: `${customPalette.main}`,
    },
  },
  hint: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
    color: grey[500],
  },
  head:{
    padding: "0 1em 0em 1em",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  form: {
    padding: "0",
  },
  actions: {
    padding: "0!important",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spacing: {
    marginRight: "1em",
  },
  input: {
    "& .MuiInputBase-input": {
      color: "#000", // Text color
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: `${customPalette.main}`,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "#000", // Semi-transparent underline
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on hover
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: `${customPalette.main}`, // Solid underline on focus
    },
  },
  title: {
    margin: "32px 0 16px 0",
    color: `${customPalette.main}`,
    fontSize: "20px",
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: "26px",
  },
  link:{
    marginTop: "16px!important",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
  }
}));

const SimpleFields = ({ handleSubmit, loading }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.head}>
                <Logo height="139px" text={true} />
                <h1 className={classes.title}>
                  {translate("ra.forgot.title")}
                </h1>
              </div>
              <div className={classes.form}>
                <TextInput
                  name="email"
                  source="email"
                  label={translate("ra.register.email")}
                  disabled={loading}
                  className={classes.input}
                  variant="filled"
                  validate={[email(), required()]}
                  fullWidth
                />
              </div>
              <CardActions className={classes.actions}>
                <Button
                  className={classes.icon}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  onClick={(e) => {
                    handleSubmit(formData);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      className={classes.spacing}
                    />
                  )}
                  {translate("ra.forgot.button")}
                </Button>
                <Link className={classes.link} to="/login" >{translate("ra.auth.title")}</Link>
              </CardActions>
            </Card>
            <Notification />
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

const Recover = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (values) => {
    setLoading(true);
    if (values?.email) {
      try {
        const url =
          process.env.REACT_APP_API_HOST_ADMIN + `/auth/forgot-password`;
        const body = JSON.stringify(values);
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        })
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            if (!json?.error) {
              notify(
                "Se ha enviado un email para cambiar la contraseña",
                "info"
              );
              setLoading(false);
            } else {
              setLoading(false);
              notify(json?.error, "warning");
            }
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      setLoading(false);
      notify(translate("ra.validation.email"), "warning");
    }
  };

  return (
    <div className={classes.body}>
      <SimpleForm toolbar={null} className={classes.form}>
        <SimpleFields handleSubmit={handleSubmit} loading={loading} />
      </SimpleForm>
    </div>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const CustomRecover = (props) => <Recover {...props} />;

export default CustomRecover;
