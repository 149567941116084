import { Add } from "@mui/icons-material";
import { Button, Typography, Box } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { Confirm, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { TableExpense } from "./TableExpense";
import { DialogExpense } from "./DialogExpense";

const useStyles = makeStyles({
  title: {
    fontWeight: "500!important",
    fontSize: "22px!important",
    lineHeight: "30px!important",
  },
});

export const Expenses = () => {
  const classes = useStyles();
  const { expenses , status} = useRecordContext();
  const [values, setValues] = useState(expenses || []);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [data, setData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { setValue } = useFormContext();

  useEffect(()=>{
    if (expenses){
      setValues(expenses);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  },[expenses])

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };
  const handleSave = (newitem) => {
    let copy = [...values];
    if (index != null) {
      copy[index] = newitem;
    } else {
      copy.push(newitem);
    }
    setValues(copy);
    setValue("expenses", copy);
  };

  const handleEdit = (e, index) => {
    e.stopPropagation();
    setData(values[index]);
    setIndex(index);
    setOpen(true);
  };
  const handleDelete = () => {
    let copy = [...values];
    copy.splice(index, 1);
    setValues(copy);
    setIndex(null);
    setOpenConfirm(false);
    handleClose();
    setValue("expenses", copy);
  };

  const handleConfirm = (e, index) => {
    e.stopPropagation();
    setIndex(index);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = (e) => {
    e.stopPropagation();
    setIndex(null);
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <Box display="flex" mb="16px" mt="32px">
        <Typography className={classes.title} sx={{ marginRight: "24px" }}>
          Gastos
        </Typography>
        {status == "en_proceso" && <Button
          variant="outlined"
          size="small"
          sx={{ fontWeight: 500, fontSize: "12.3px", lineHeight: "14px" }}
          startIcon={<Add />}
          onClick={() => setOpen(true)}
        >
          Agregar Gasto
        </Button>}
      </Box>
      <TableExpense
        rows={values}
        handleEdit={handleEdit}
        handleConfirm={handleConfirm}
      />
      <DialogExpense
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        data={data}
      />
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={"Esta seguro que desea eliminar el gasto?"}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};
