import { Layout, useSidebarState } from "react-admin";
import { Menu } from "./Menu";
import { CustomAppBar } from "../components/CustomAppBar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  sidebarOpen: {
    "& .RaLayout-contentWithSidebar": {
      "& .MuiDrawer-docked .MuiPaper-root": {
        width: "200px!important",
      },
      "& .RaSidebar-fixed": {
        minWidth: "200px!important",
      },
    },
  },
  sidebarClose: {
    "& .RaLayout-contentWithSidebar": {
      "& .RaSidebar-paper": {
        width: "58px!important",
        "& .MuiSvgIcon-root": {
          marginRight: "20px",
        },
      },
      "& .RaSidebar-fixed": {
        width: "58px!important",
      },
    },
  },
});

export const CustomLayout = (props) => {
  const classes = useStyles();
  const [open] = useSidebarState();
  return (
    <>
      <Layout
        {...props}
        menu={Menu}
        appBar={CustomAppBar}
        className={open ? classes.sidebarOpen : classes.sidebarClose}
      />
      {/* {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )} */}
    </>
  );
};
