import {
  SimpleForm,
  TextInput,
  Edit,
  required,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  email,
} from "react-admin";
import { hasPermission } from "../../../utils/permissions";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { validateCUITCUIL } from "../../../utils/Validations";
import CustomNumberInput from "../../../components/CustomNumberInput";

const useStyles = makeStyles({
  input: {
    maxWidth: "240px",
  },
  inputShort: {
    maxWidth: "180px",
  },
  inputLong: {
    maxWidth: "300px",
  },
  create: {
    "& .RaCreate-main.RaCreate-noActions": {
      marginTop: 0,
    },
  },
});

const cuilCuitErrorMessage = "El cuil/cuit ingresado no es correcto";

const validate = (values) =>{
  let errors = {settings:{}};
  if (!values?.name) {
    errors.name = "El nombre es requerido";
  }
  if (!values?.email && !email(values.email)) {
    errors.email = "El email es requerido";
  }
  if (values?.settings?.cuil_cuit?.length < 11) 
    errors.settings.cuil_cuit = "Los códigos deben tener al menos 11 dígitos";
  else if (values?.settings?.cuil_cuit && !validateCUITCUIL(values.settings.cuil_cuit)){
    errors.settings.cuil_cuit = cuilCuitErrorMessage;
  }
  if (values?.password != values?.password_confirmation){
    errors.password_confirmation = "Las contraseñas no coinciden";
  }
  if (values?.password && values?.password?.length < 6){
    errors.password = "La contraseña debe tener al menos 6 dígitos";
  }
  if (hasPermission("edit-roles") && !values?.roles){
    errors.roles = "El rol es requerido";
  }
  return errors;
}

export const UserEdit = () => {
  const classes = useStyles();
  return (
    <Edit title=" " redirect="list" >
      <SimpleForm
        redirect="list"
        variant="filled"
        margin="dense"
        toolbar={<CustomToolbar allowDelete={true}/>}
        validate={validate}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#343434",
            fontSize: "1.4em",
            marginBottom: "1em",
            fontWeight: 600,
          }}
        >
          Editar
        </Typography>
        <Box display="flex" flexDirection="column">
          <TextInput
            source="name"
            label="Nombre y Apellido"
            variant="filled"
            validate={required()}
            className={classes.inputLong}
          />
          <TextInput
            source="settings.cuil_cuit"
            label="CUIL/CUIT"
            className={classes.input}
            variant="filled"
          />
          {hasPermission("edit-roles") && (
            <ReferenceInput label="Rol" source="roles" reference="roles">
              <SelectInput
                optionText="title"
                label="Rol"
                validate={required()}
                className={classes.inputShort}
                variant="filled"
              />
            </ReferenceInput>
          )}
          <Typography
            sx={{
              color: "#343434",
              fontSize: "1.2em",
              marginTop: "1em",
              marginBottom: ".8em",
              fontWeight: 600,
            }}
          >
            Contacto
          </Typography>
          <CustomNumberInput
            source="phone"
            label="Teléfono"
            variant="filled"
            className={classes.input}
          />
          <Box display="flex">
            <TextInput
              source="settings.address_name"
              label="Calle"
              variant="filled"
              sx={{ marginRight: "2em" }}
              className={classes.input}
            />
            <TextInput
              source="settings.address_number"
              label="N°"
              variant="filled"
              sx={{ marginRight: "2em", maxWidth: "100px" }}
            />
            <TextInput
              source="settings.address_floor"
              label="Piso"
              variant="filled"
              sx={{ marginRight: "2em", maxWidth: "80px" }}
            />
            <TextInput
              source="settings.address_door"
              label="Dpto."
              variant="filled"
              sx={{ maxWidth: "80px" }}
            />
          </Box>
          <TextInput
            source="settings.location"
            label="Localidad"
            variant="filled"
            className={classes.input}
            sx={{ marginBottom: "1em" }}
          />
        </Box>
        <Typography
          sx={{
            color: "#343434",
            fontSize: "1.2em",
            marginTop: "1em",
            marginBottom: ".8em",
            fontWeight: 600,
          }}
        >
          Inicio de sesión
        </Typography>
        <TextInput
          source="email"
          label="Email"
          variant="filled"
          validate={required()}
          className={classes.input}
        />
        <PasswordInput
          source="password"
          label="Contraseña"
          variant="filled"
          className={classes.input}
        />
        <PasswordInput
          source="password_confirmation"
          label="Repetir contraseña"
          variant="filled"
          className={classes.input}
        />
      </SimpleForm>
    </Edit>
  );
};
