import * as React from 'react';
import { Box, Card, CardActions, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import { hasPermission } from '../../utils/permissions';
import { customPalette } from '../../themes/customTheme';

const useStyles = makeStyles(theme=>({
  card: {
    backgroundColor: `${customPalette.main}!important`,
    color: '#fff!important',
    padding: 20,
    margin: '16px 0',
  },
  actions: {
    padding: 0,
    flexWrap: 'wrap',
    '& a': {
      marginTop: '16px',
    },
  },
  margin:{
    marginLeft: '0!important',
    "@media (min-width: 400px)":{
      marginLeft: '16px!important'
    }
  }
}));

const CustomButton = styled(Button)({
  color: `${customPalette.main}!important`,
  backgroundColor: 'white!important',
  fontWeight: '700!important',
  opacity: 0.9,
  padding: '6px 12px!important',
  '&:hover': {
    opacity: 1,
    backgroundColor: 'white'
  },
});

const CustomCard = styled(Card)(({ theme }) => ({
  color: 'white',
  backgroundColor: `${customPalette.main}`,
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <CustomCard className={classes.card}>
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h4" component="h1" gutterBottom>
            ¡Bienvenido a GesVet!
          </Typography>
          {hasPermission('see-dashboard-panel') && <>
            <CardActions className={classes.actions}>
              <CustomButton
                color="primary"
                startIcon={<AddIcon />}
                href="#/jobs/create"
              >
                Nuevo Trabajo
              </CustomButton>
              <CustomButton
                color="primary"
                href="#/payments/create"
                startIcon={<AddIcon />}
                className={classes.margin}
              >
                Nuevo Cobro
              </CustomButton>
            </CardActions>
          </>}
        </Box>
      </Box>
    </CustomCard>
  );
};

export default Welcome;
