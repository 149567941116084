import {
  Box,
  CardContent,
  Card,
  CardHeader,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { customPalette } from "../themes/customTheme";
import { formatter } from "../utils/NumberFormatter";
import { Status } from "../resources/jobs/Status";

const useStyle = makeStyles({
  aside: {
    minWidth: "196px",
    marginLeft: "24px",
  },
  card: {
    borderRadius: "5px",
  },
  color: {
    color: `${customPalette.main}`,
  },
  info: {
    color: `${customPalette.main}`,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    margin: "4px 0",
    textTransform: "capitalize",
  },
  date: {
    textDecoration: "none",
    cursor: "pointer",
    color: "#343434",
  },
});

export const AsideItems = () => {
  const classes = useStyle();
  const record = useRecordContext();
  const items = record?.client?.jobs || [];
  if (!items || items?.lenght == 0) return null;

  return (
    <Box className={classes.aside}>
      <Card className={classes.card}>
        <CardHeader
          sx={{ padding: "24px" }}
          title="Historial de trabajos"
          titleTypographyProps={{
            style: {
              fontWeight: 500,
              fontSize: "26px",
              lineHeight: "30px",
            },
          }}
        />
        <CardContent
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: 0,
            paddingBottom: "64px!important",
          }}
        >
          {items?.length > 0 ? (
            <Stepper orientation="vertical">
              {items
                ?.slice(0)
                ?.reverse()
                ?.map((item, index) => (
                  <Step key={index} active expanded>
                    <StepLabel
                      StepIconComponent={() => (
                        <AssignmentIcon className={classes.color} />
                      )}
                      sx={{ padding: index == 0 ? "0 0 24px 0" : "24px 0" }}
                    >
                      <Box position="relative">
                        <Box
                          display="flex"
                          flexDirection="column"
                          position="absolute"
                          top="-15px"
                        >
                          <Link
                            to={`/jobs/${item.id}/show`}
                            className={`${classes.date}`}
                          >
                            {new Date(item.created_at).getDate()}{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {new Date(item.created_at).toLocaleString(
                                "es-AR",
                                {
                                  month: "long",
                                }
                              )}
                            </span>{" "}
                            {new Date(item.created_at).getFullYear()}
                          </Link>
                          <span className={classes.info}>{`$${formatter.format(
                            item?.total
                          )}`}</span>
                          <Box>
                            <Status data={item?.status} />
                          </Box>
                        </Box>
                      </Box>
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
};
