import { Chip, Typography } from "@mui/material";
import {
  BooleanInput,
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { ActionsCreate } from "../../../components/ActionsCreate";
import { makeStyles } from "@mui/styles";
import { CustomPagination } from "../../../components/Pagination";
import { ConfirmDeleteButton } from "../../../components/ConfirmDeleteButton";
import { formatter } from "../../../utils/NumberFormatter";

const useStyles = makeStyles({
  customAlign: {
    "& .MuiTableCell-root": {
      textAlign: "left",
    },
  },
})

const filters = [
    <TextInput label="Buscar por nombre o establecimiento" source="search-custom" style={{minWidth: '300px'}} alwaysOn resettable/>,
    <BooleanInput label="Eliminado" source="with_trashed" defaultValue={true} />
  ];

const CustomEstablishments = () =>{
  const { establishments }= useRecordContext();
  const data = establishments?.length > 0 ? establishments : null;
  if (!data) return null;
  return (
      <Typography component="span" variant="body2">{data[0]?.name} {data?.length > 1 && <Chip size="small" label={`+${data.length - 1}`} sx={{borderRadius: '5px',color:'black', background: '#F0F0F0'}} />}</Typography>
  )
};

const getLocales = (establishments) =>{
  let arr = [];
  establishments?.forEach((element) => {
    arr.push(element.location);
  });
  let unique = [...new Set(arr.map((data) => data))];
  return unique;
}

const CustomLocales = () =>{
  const { establishments }= useRecordContext();
  const data = establishments?.length > 0 ? getLocales(establishments) : null;
  if (!data || data?.length == 0) return null;
  return (
      <Typography component="span" variant="body2">{data[0]} {data?.length > 1 && <Chip size="small" label={`+${data.length - 1}`} sx={{borderRadius: '5px',color:'black', background: '#F0F0F0'}} />}</Typography>
  )
};

const AccountStatus = () =>{
  const record = useRecordContext();
  return (
    <FunctionField
            sx={{
              color:
                record?.current_account > 0
                  ? "#FF0000"
                  : record?.current_account == 0
                  ? "#000000"
                  : "#0d8708",
              fontWeight: 500,
            }}
            render={(record) =>
              `$${formatter.format(record?.current_account || 0)}`
            }
          />
  )
}

export const ClientList = () => {
  const classes = useStyles();
  return (
    <List
      title=" "
      filters={filters}
      empty={false}
      sort={{ field: "name", order: "ASC" }}
      actions={<ActionsCreate label="Nuevo Cliente" filter={true}/>}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}  className={classes.customAlign}>
        <TextField source="name" label="Nombre"/>
        <CustomEstablishments label="Establecimientos"/>
        <CustomLocales label="Localidad"/>
        <AccountStatus label="Cuenta corriente"/>
        <ConfirmDeleteButton resource="clients" element="el cliente"/>
      </Datagrid>
    </List>
  );
};
