import { Button } from "@mui/material";
import { useState } from "react";
import { useListContext, useNotify } from "react-admin";
import { apiUrl } from '../utils/httpUtils';

const ExportExcelButton = () => {
  const notify = useNotify();
  let { filterValues } = useListContext();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    let params = JSON.stringify(filterValues);
    const url = `${apiUrl}/jobs/export?filter=${params}`;
    const token = localStorage.getItem('token');

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/xlsx',
      Authorization: `Bearer ${token}`,
      'Content-Disposition': 'attachment',
    };

    fetch(url, { headers })
      .then(res => res.ok && res.blob())
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        if (file) {
          const a = document.createElement('a');
          a.href = file;
          let date = new Date().toLocaleString('es-AR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          });
          a.download = date + ` - Trabajos.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          notify('Reporte generado con éxito', { type: 'success' });
        }
      })
      .catch(e => {
        notify(`Error en reporte: ${e}`, { type: 'error' });
      })
      .finally(() => setLoading(false));
    };

  return (
    <Button
      variant="outlined"
      disabled={loading}
      onClick={handleClick}
    >Exportar</Button>
  )
}

export default ExportExcelButton;
