import { Button } from "@mui/material";
import { useRecordContext, Confirm, useNotify, useRefresh, useRedirect } from "react-admin";

import { Fragment, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataProvider } from "../providers/dataProvider";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';


export const ConfirmDeleteButton = ({ resource, element, warnDangerousMove = false, label = ''}) => {
  const [openConfirm, setOpenConfirm] = useState();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const textContent = warnDangerousMove 
    ? `Esta operación NO SE PUEDE DESHACER y borrará todo lo relacionado a ${element}`
    : `Esta seguro que desea ${record?.deleted_at ? "recuperar": "eliminar"} ${element}?`;

  const handleDelete = async (e) => {
    e.stopPropagation();
    let res = await dataProvider.delete(resource, { id: record?.id });
    if (!res.data.error) {
      notify(`${element} fue ${record?.deleted_at ? "recuperado" : "eliminado"} con éxito`,{type:"info"});
      redirect(resource);
      refresh();
    }else{
      notify(res.data.error,{type: "warning"});
    }
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleClick = (e) => {
    e.stopPropagation();
    setOpenConfirm(true);
  };

  return (
    <Fragment>
      <Button
        onClick={(e) => handleClick(e)}
        startIcon={record?.deleted_at ? <AddCircleRoundedIcon/> : <DeleteIcon />}
        variant={warnDangerousMove && "outlined"}
        color={warnDangerousMove ? "error" : "primary"}
      >
       {label} 
      </Button>
      <Confirm
        isOpen={openConfirm}
        title={"Confirmar acción"}
        content={textContent}
        onConfirm={handleDelete}
        onClose={handleCloseConfirm}
      />
    </Fragment>
  );
};
