import {
  Edit,
  SimpleForm,
  TextInput,
  required
} from "react-admin";
import Abilities from "../abilities";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { ErrorAbilities } from "../ErrorAbilities";

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = "El nombre es requerido";
  }
  if (!values.abilities) {
    errors.abilities = "Seleccione una habilidad";
  } else if (values.abilities?.length < 1) {
    errors.abilities = "Seleccione al menos una habilidad";
  }
  return errors;
};

export const RoleEdit = () => (
  <Edit title=" ">
    <SimpleForm toolbar={<CustomToolbar alwaysEnable/>} validate={validate}>
      <TextInput source="title" label="Nombre"  style={{width:'300px'}}  validate={required()}/>
      <Abilities source="abilities" />
      <ErrorAbilities />
    </SimpleForm>
  </Edit>
);
