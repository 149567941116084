import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

export const CustomAddButton = ({onClick,label}) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<AddIcon />}
      variant="outlined"
      sx={{ fontWeight: '500'}}
    >
      {label ? label : "Agregar"}
    </Button>
  );
};
