import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-admin";
import { customPalette } from "../../themes/customTheme";
import { formatter } from "../../utils/NumberFormatter";
import LaunchIcon from "@mui/icons-material/Launch";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "created_at",
    disablePadding: false,
    label: "Fecha",
  },
  {
    id: "total",
    disablePadding: false,
    label: "Total trabajo",
    align: "left",
  },
  {
    id: "amount",
    disablePadding: false,
    label: "Abonado",
    align: "left",
  },
  {
    id: "establishment",
    disablePadding: false,
    label: "Establecimiento",
  },
  {
    id: "location",
    disablePadding: false,
    label: "Localidad",
  },
  {
    id: "action",
    disablePadding: false,
    label: " ",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export function ShowTableJob({ rows }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_at");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        sx={{
          "& .MuiTable-root": {
            "& .MuiTableHead-root": {
              backgroundColor: "#FBFBFF",
              "& .MuiTableCell-root": {
                color: `${customPalette.main}`,
                fontWeight: "600!important",
              },
            },
            "& .MuiTableRow-root:last-child > .MuiTableCell-root": {
              border: "none!important",
            },
            "& .MuiTableCell-root": {
              color: `${customPalette.main}`,
            },
          },
          marginBottom: "16px",
        }}
      >
        <Table
          sx={{ minWidth: "721px", maxWidth: "721px" }}
          aria-labelledby="tableTitle"
          size={"small"}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                    {moment(row?.created_at?.toLocaleString().substr(0, 10)).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                    ${formatter.format(row.job.total || 0)}
                    </TableCell>
                    <TableCell align="left">
                    ${formatter.format(row.amount || 0)}
                    </TableCell>
                    <TableCell align="left">
                      {row?.job?.establishment?.name}
                    </TableCell>
                    <TableCell align="left">
                      {row?.job?.establishment?.location}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <Box>
                        <Link
                          to={`/jobs/${row.job.id}`}
                          target="_blank"
                          sx={{
                            color: `${customPalette.main}`,
                            padding: 0,
                            marginRight: "8px",
                          }}
                          aria-label="Ver trabajo"
                        >
                          <LaunchIcon sx={{ width: "18px", height: "18px" }} />
                        </Link>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
