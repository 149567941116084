import { AppBar, UserMenu, MenuItemLink ,Logout} from "react-admin";
import { Typography ,Box} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from "../assets/logo.png";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    position: 'absolute',
    left:'50%',
    maxHeight: "45px",
    maxWidth: "145px",
    marginBottom: 5,
    marginTop: 5,
  },
  user:{
    '& button.MuiButtonBase-root.MuiIconButton-root':{
      borderRadius: '0!important',
    }
  }
});

const MyUserMenu = ({ user }) => {
  const classes = useStyles();
  return (
  <UserMenu
    label={"Usuario"}
    icon={
      <span style={{ marginTop: '1px',fontSize: "12px", color: "#black" }}>{user ? <Box display="flex" alignItems="center"><AccountCircleIcon/><Typography sx={{fontSize: '14px', marginLeft:'2px'}}>{user?.name}</Typography></Box> : <AccountCircleIcon/>}</span>
    }
    className={classes.user}
  > 
   <MenuItemLink className="app-bar-menu" to="/professional-services" primaryText="Servicios profesionales" />
   <MenuItemLink className="app-bar-menu" to="/settings" primaryText="Mi Perfil" />
   <Logout/>
  </UserMenu>)
  };

export const CustomAppBar = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <AppBar  color="inherit" userMenu={<MyUserMenu user={user} />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={logo} alt="logo" className={classes.logo} />
      <span className={classes.spacer} />
    </AppBar>
  );
};
