import { Create, required, SimpleForm, TextInput, useNotify, useRedirect } from "react-admin";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Abilities from "../abilities";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { ErrorAbilities } from "../ErrorAbilities";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px!important",
    lineHeight: "32px!important",
    fontWeight: '500!important',
    margin: "0 0 16px 0!important"
  },
}));

const PanelTitle = ()=> {
  const classes = useStyles();
  return <Typography className={classes.heading}>Nuevo Rol</Typography>;
};

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = "El nombre es requerido";
  }
  if (!values.abilities) {
    errors.abilities = "Seleccione una habilidad";
  } else if (values.abilities?.length < 1) {
    errors.abilities = "Seleccione al menos una habilidad";
  }
  return errors;
};

export const RoleCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('ra.resources.roles.create');
    redirect("/roles");
  };
return (
  <Create title=" " redirect="list" mutationOptions={{ onSuccess }}>
    <SimpleForm validate={validate} toolbar={<CustomToolbar/>}>
      <PanelTitle />
      <TextInput source="title" label="Nombre" style={{width:'300px'}} validate={required()}/>
      <Abilities source="abilities" />
      <ErrorAbilities />
    </SimpleForm>
  </Create>
)
}

