import { makeStyles } from "@mui/styles";
import { STATUS } from "../constants";
import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const useStyles = makeStyles({
  en_proceso: {
    background: "#0088FE!important",
    borderRadius: "500px!important",
    color: "white!important",
  },
  finalizado: {
    background: "#2AB572!important",
    color: "white!important",
  },
  no_cobrado: {
    background: "#ff6600!important",
    borderRadius: "500px!important",
    color: "white!important",
  },
  vencido:{
    background: "#FF0000!important",
    color:"white!important"
  },
  cobrado_parcialmente:{
    background: "#FFB800!important",
    color:"black!important"
  }
});

export const Status = ({ data }) => {
  const record = useRecordContext();
  const state = data ? data : record?.status;
  const classes = useStyles();
  if (!state) return null;
  return (
    <Chip
      size="small"
      className={classes[`${state}`]}
      component="span"
      label={`${STATUS.find((i) => i.id == state)?.name}`}
    />
  );
};
