import {
  Datagrid,
  DateField,
  List,
  NumberField,
  SelectField,
  TextInput,
  SelectInput,
  Labeled,
  useRecordContext,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  useNotify
} from "react-admin";
import { Fragment, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Chip,
  Button,
} from "@mui/material";
import { PAYMENTMETHOD } from "../../constants";
import DatePickerBetween from "../../../components/DatePickerBetween";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { formatter } from "../../../utils/NumberFormatter";
import { customPalette } from "../../../themes/customTheme";
import { ShowTableJob } from "../ShowTableJob";
import { Transition } from "../../../components/Transition";
import LaunchIcon from "@mui/icons-material/Launch";
import { CustomPagination } from "../../../components/Pagination";
import { dataProvider } from "../../../providers/dataProvider";
import { useEffect } from "react";
import { ConfirmDeleteButton } from "../../../components/ConfirmDeleteButton";
import { ActionsCreate } from "../../../components/ActionsCreate";
import moment from "moment";

const useStyles = makeStyles({
  customAlign: {
    "& .MuiTableCell-root": {
      textAlign: "left",
    },
  },
});

const CustomClient = () => {
  const { client } = useRecordContext();
  return (
    <Typography component="span" variant="body2">
      {client?.name}
    </Typography>
  );
};

const QuickFilter = ({ label }) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const filters = [
  <TextInput label="Buscar por cliente" source="search-custom" alwaysOn resettable/>,
  <DatePickerBetween label="Fechas" alwaysOn />,
  <SelectInput
    label="Tipo"
    source="payment_method"
    choices={PAYMENTMETHOD}
    emptyText="Sin estado"
    alwaysOn
  />,
  <ReferenceInput
  source="client_id"
  label="Nombre y Apellido"
  reference="clients"
  filter={{with_trashed: true}}
  >
    <AutocompleteInput
      optionText="name"
      label="Nombre y Apellido"
      filterToQuery={q => ({"qname": q})}
      sx={{ minWidth: "220px" , "& .MuiInputBase-root":{maxHeight: "48px", paddingTop:"14px", fontSize:"0.9em"}}}
    />
  </ReferenceInput>,
  <BooleanInput source="not_used"  label="Sin usar"/>,
  <BooleanInput label="Eliminado" source="with_trashed" defaultValue={true} />,
  <QuickFilter source="ids" label="Id"/>
];

const transform = (values) =>{
  let res = [];
  res = [...res, ...values?.jobpayment];
  res.forEach((item) =>{
    let aux = values?.jobs?.find(i => i.id == item.job_id);
    item.job = aux;
  });
  return res;
}

const PaymentContent = ({ data, id }) => {
  const [payment, setPayment] = useState();
  const [detail,setDetail] = useState([]);
  const notify = useNotify();

  const fetchData = async () => {
    let res = await dataProvider.getOne("payments", { id: id });
    if (res?.data) {
      setDetail(transform(res.data));
      setPayment(res.data);
    }
  };
  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);
  const ischeq = () => {
    return data?.payment_method == "ch" || data?.payment_method == "che";
  };

   const handleClick = async() =>{
     let res = await dataProvider.updatePayment(data?.id,{client_id: data?.client_id});
     if (res?.data){
       setDetail(transform(res.data));
       setPayment(res.data);
     }
     notify("Cobro actualizado con exito",{type:"info"});
   }

  if (!payment) return null;
  return (
    <Fragment>
      <Box
        display="flex"
        width="550px"
        justifyContent="space-between"
        margin="16px 0"
      >
        <Labeled
          label="MONTO"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: `#818181`,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "20px",
              color: `${customPalette.main}`,
            }}
          >
            ${formatter.format(payment?.amount)}
          </Typography>
        </Labeled>
        <Labeled
          label="USADO"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: `#818181`,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "20px",
              color: `${customPalette.main}`,
            }}
          >
            ${formatter.format(payment?.amount_used)}
          </Typography>
        </Labeled>
        <Labeled
          label="FECHA DE CREACIÓN"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: `#818181`,
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: `${customPalette.main}`,
            }}
          >
            {moment(payment?.date).format("DD/MM/YYYY")}
          </Typography>
        </Labeled>
        <Labeled
          label="FORMA DE PAGO"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: `#818181`,
          }}
        >
          <Fragment>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: `${customPalette.main}`,
              }}
            >
              {PAYMENTMETHOD.find((i) => i.id == payment?.payment_method)?.name}{" "}
              {ischeq() ? `N° ${payment?.receipt_number || '--'}` : ""}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: `${customPalette.main}`,
              }}
            >
              {ischeq() && payment.payment_date
                ? `${moment(payment?.payment_date).format("DD/MM/YYYY")}`
                : ""}
            </Typography>
          </Fragment>
        </Labeled>
        <Box>
        {payment?.amount != payment.amount_used && 
          <Button variant="outlined" onClick={()=>{handleClick()}}>
            Usar Resto
          </Button>
        }
        </Box>
      </Box>
      <Typography
        sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "20px" }}
      >
        Detalles
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "20px",
          color: "#818181",
        }}
      >
        {data?.detail}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          marginTop: "32px",
          marginBottom: "16px",
        }}
      >
        Trabajos
      </Typography>
      {detail && detail?.length > 0 && <ShowTableJob rows={detail} showSelected={false} />}
    </Fragment>
  );
};

const PopUpPaymentShow = ({ data, show, handleClose }) => {
  const onclick = (e) => {
    e.stopPropagation();
    handleClose();
  };
  if (!show || !data) return null;
  return (
    <Dialog
      PaperProps={{ elevations: "0" }}
      open={show}
      onClose={(e) => onclick(e)}
      fullWidth
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { maxWidth: "769px" } }}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ paddingBottom: 0 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "22px",
              lineHeight: "30px",
              textTransform: "capitalize",
            }}
          >
            {data?.client?.name}
          </Typography>
          <IconButton onClick={(e) => onclick(e)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <PaymentContent id={data?.id} data={data} />
      </DialogContent>
    </Dialog>
  );
};

const CustomShowButton = ({ showPopUp }) => {
  const record = useRecordContext();
  return (
    <Tooltip title="Ver cobro">
      <IconButton onClick={() => showPopUp(record)}>
        <LaunchIcon sx={{ color: `${customPalette.main}` }} />
      </IconButton>
    </Tooltip>
  );
};


export const PaymentList = () => {
  const classes = useStyles();
  const [show, setShow] = useState();
  const [data, setData] = useState();

  const showPopUp = (record) => {
    setData(record);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Fragment>
      <List
        title=" "
        filters={filters}
        empty={false}
        className={classes.customAlign}
        actions={<ActionsCreate label="Nuevo Cobro" filter={true}/>}
        pagination={<CustomPagination />}
        sort={{ field: "date", order: "ASC" }}
      >
        <Datagrid
          bulkActionButtons={false}
          onClick={(e) => {
            e.stopPropagation(e);
          }}
        >
          <DateField source="date" label="Fecha" />
          <CustomClient label="Cliente" />
          <SelectField
            source="payment_method"
            choices={PAYMENTMETHOD}
            label="Tipo"
          />
          <NumberField
            source="amount"
            label="Monto"
            locales={"es-AR"}
            options={{ style: "currency", currency: "ARS" }}
          />
           {/* <NumberField
            source="amount_used"
            label="Monto usado"
            locales={"es-AR"}
            options={{ style: "currency", currency: "ARS" }}
          /> */}
          <DateField source="payment_date" label="Fecha de cobro" />
          <CustomShowButton showPopUp={showPopUp} />
          <ConfirmDeleteButton  resource={"payments"} element="el cobro"/>
        </Datagrid>
      </List>
      <PopUpPaymentShow show={show} data={data} handleClose={handleClose} />
    </Fragment>
  );
};
