import { Box, Typography, Button } from "@mui/material";
import { useState, Fragment, useEffect } from "react";
import {
  Edit,
  Form,
  Labeled,
  Link,
  NumberField,
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
  TextInput,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Collaborators } from "../Collaborators";
import { Expenses } from "../Expenses";
import { Services } from "../Services";
import { Status } from "../Status";
import { useFormContext } from "react-hook-form";
import { AsideItems } from "../../../components/AsideItems";
import { formatter } from "../../../utils/NumberFormatter";
import PrintJob from "../Print";
import moment from "moment";
import { SaveAndCloseButton } from "../SaveAndCloseButton";
import { Payments } from "../Payments";

const Header = () => {
  const services = useWatch({ name: "services" });
  const status = useWatch({ name: "status" });
  const due_date = useWatch({ name: "due_date" });
  const [kg, setKg] = useState(0);
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const formvalues = useWatch();
  const { setValue } = useFormContext();
  const expenses = useWatch({ name: "expenses" });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getTotal = () => {
    let expenses_acc =
      expenses?.reduce((acc, item) => {
        return acc + Number(item?.amount || 0);
      }, 0) || 0;
    let services_acc =
      services?.reduce((acc, item) => {
        return acc + Number(item?.amount || 0);
      }, 0) || 0;
    return expenses_acc + services_acc;
  };

  useEffect(() => {
    let result = getTotal();
    setValue("total", result);
    setTotal(result);
  }, [expenses, services]); // eslint-disable-line react-hooks/exhaustive-deps

  const postSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    update(
      "jobs",
      { id: formvalues?.id, data: formvalues },
      {
        onSuccess: () => {
          notify("ra.resources.jobs.edit");
          refresh();
          setLoading(false);
        },
      }
    );
  };

  useEffect(() => {
    if (services) {
      let res = services?.reduce((acc, i) => {
        if (!i?.only_price){
          return acc + i?.kg;
        }
        return acc;
      }, 0);
      setKg(res);
      setValue("kg_total", res);
    } else {
      setKg(0);
      setValue("kg_total", 0);
    }
  }, [services]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            minWidth="135px"
            sx={{ marginRight: "24px" }}
          >
            <Labeled label="TOTAL DEL TRABAJO" sx={{ marginBottom: "8px" }}>
              <Typography
                sx={{ fontWeight: 700, fontSize: "20px", lineHeight: "30px" }}
              >{`$${formatter.format(total)}`}</Typography>
            </Labeled>
            <Box>
              <Status data={status} />
            </Box>
          </Box>
          <Labeled label="KG TOTALES" sx={{ marginRight: "24px" }}>
            <Typography
              component="span"
              sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "22px" }}
            >
              {kg ? kg : 0}
            </Typography>
          </Labeled>
          {due_date && (
            <Labeled label="FECHA VENCIMIENTO">
              <Typography
                component="span"
                sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "22px" }}
              >
                {due_date ? moment(due_date).format("DD/MM/YYYY") : ""}
              </Typography>
            </Labeled>
          )}
        </Box>
        <Box>
          <PrintJob />
          {"en_proceso" == status && (
            <>
              <Button
                variant="contained"
                size="medium"
                onClick={(e) => postSave(e)}
                sx={{
                  margin: "0 16px",
                  "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 },
                }}
                disabled={loading}
              >
                Guardar
              </Button>
              <SaveAndCloseButton
                label="Cerrar Trabajo"
                variant="contained"
                size="medium"
                isEdit={true}
                disabledByForm={loading}
              />
            </>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

const Customer = () => {
  const { client, establishment } = useRecordContext();

  return (
    <Box
      sx={{
        padding: "16px",
        boxSizing: "border-box",
        maxWidth: "466px",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #BFBFBF",
        borderRadius: "5px",
        marginTop: "32px",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Link
          to={`/clients/${client?.id}`}
          target="_blank"
          sx={{
            textDecoration: "underline!important",
            textTransform: "capitalize",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "20px",
          }}
        >
          {client?.name}
        </Link>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            marginTop: "4px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          {establishment?.name
            ? `${establishment?.name} - ${establishment?.location}`
            : ""}
        </Typography>
      </Box>
      <Box>
        <Labeled label="ESTADO ACTUAL">
          <NumberField
            source="client.current_account"
            locales={"es-AR"}
            sx={{
              color:
                client?.current_account > 0
                  ? "#FF0000"
                  : client?.current_account == 0
                  ? "#000000"
                  : "#0d8708",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "20px",
            }}
            options={{
              style: "currency",
              currency: "ARS",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
        </Labeled>
      </Box>
    </Box>
  );
};

const Observations = () => {
  return (
    <Fragment>
      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            fontWeight: "500!important",
            fontSize: "22px!important",
            lineHeight: "30px!important",
            marginRight: "24px",
          }}
        >
          Observaciones
        </Typography>
      </Box>
      <TextInput
        source="observations"
        multiline
        label=""
        rows={4}
        variant="filled"
        sx={{
          width: "520px",
          "& .MuiFilledInput-root": { padding: "16px!important" },
        }}
      />
    </Fragment>
  );
};

export const JobsEdit = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = () => {
    notify("ra.resources.jobs.edit");
    refresh();
  };
  return (
    <Edit
      title=" "
      mutationOptions={{ onSuccess }}
      aside={<AsideItems />}
      redirect={false}
    >
      <Form>
        <Box sx={{ padding: "32px 24px", boxSizing: "border-box" }}>
          <Header />
          <Customer />
          <Services />
          <Expenses />
          <Collaborators />
          <Payments />
          <Observations />
        </Box>
      </Form>
    </Edit>
  );
};
