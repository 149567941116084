import { useState, useEffect, useCallback, Fragment } from "react";
import { useDataProvider, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin:0,
    width: "100%",
    maxWidth: 360,
    padding: "0!important",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  heading: {
    fontSize: "24px!important",
    lineHeight: "30px!important",
    fontWeight: '500!important',
    margin: "12px 0 16px 0!important"
  },
  check:{
    minWidth:'30px!important',
    "& .MuiCheckbox-root":{
      padding: "0 8px 8px 8px !important",
    }
  },
  text:{
    marginTop: '11px!important',
  },
  li:{
    padding: '2px 0 !important',
    "&:hover":{
      backgroundColor:'inherit!important',
    },
    "&:focus":{
      backgroundColor:'inherit!important',
    }
  }
}));

export default function Abilities({ source }) {
  const record = useRecordContext();
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [state, setState] = useState({});
  const dataProvider = useDataProvider();

  const { setValue } = useFormContext();

  const fetchAbilities = useCallback(async () => {
    const { data: allAbilities } = await dataProvider.getList("abilities", {
      filter: {},
      sort: { field: "title", order: "DESC" },
      pagination: { page: 1, perPage: 200 },
    });
    setState((state) => ({
      ...state,
      allAbilities,
    }));
  }, [dataProvider]);

  useEffect(() => {
    fetchAbilities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (record && record.abilities) {
      setChecked(record.abilities);
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setValue(source, newChecked);
  };

  return (
    <Fragment>
      <Typography className={classes.heading}>Permisos</Typography>
      <List className={classes.root}>
        {state.allAbilities &&
          state.allAbilities.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;
            return (
              <ListItem
                key={value.id}
                dense
                button
                onClick={handleToggle(value.id)}
                className={classes.li}
              >
                <ListItemIcon className={classes.check}>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  className={classes.text}
                  primary={value.title}
                  secondary={value.name}
                />
              </ListItem>
            );
          })}
      </List>
    </Fragment>
  );
}
