import { Fragment } from "react";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslate } from "react-admin";
import { customPalette } from "../themes/customTheme";

const useStyles = makeStyles(() => ({
  sidebarIsOpen: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
  sidebarIsClosed: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  },
  selected: { background: 'rgba(0, 0, 0, 0.04)', color: 'white' },
 
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  const header = (
    <MenuItem dense={dense} component="ul" onClick={handleToggle} role="submenu" sx={{ padding:0,borderLeft: '3px solid #fff !important',
    '&:hover': {
      background: `${customPalette.main}`,
      color: 'white',
      borderLeft: `3px solid ${customPalette.main}!important`,
      '& svg': {
        color: 'white!important',
      },
      '& .MuiTypography-root': {
        color: 'white',
      }
    }}}className={`${classes.header} ${isOpen !== undefined && isOpen ? classes.selected : ''}`}>
      <MenuItem sx={{ width:'100%','&:hover': {backgound: 'none'}}}>
      <ListItemIcon className={classes.icon} sx={{minWidth: '40px', marginRight:'4px'}}>
        {isOpen ? <ExpandMoreRoundedIcon /> : icon}
      </ListItemIcon>
      <Typography variant='inherit' color='textSecondary' style={{ fontSize: '1rem' }}>
        {translate(name)}
      </Typography>
      </MenuItem>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement='right'>
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List
          dense={dense}
          component='div'
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
