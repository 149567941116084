import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { customPalette } from "../../themes/customTheme";
import { IconButton, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatter } from "../../utils/NumberFormatter";
import { useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";

export const TableCollaborators = ({ rows, handleConfirm, handleEdit }) => {
  const [acum, setAcum] = useState();
  const { status } = useRecordContext();
  const veterinary_fees = useWatch({ name: 'veterinary_fees' });

  const getTotal = () => {
    let res = rows?.reduce((acc, i) => acc + Number(i?.amount || 0), 0);
    return Number(res);
  };

  const getPercentage = () => {
    let res = rows?.reduce((acc, i) => acc + Number(i?.percentage || 0), 0);
    if (res > 0) {
      return `${res}%`;
    } else {
      return ' - ';
    }
  };

  useEffect(() => {
    setAcum(getTotal());
  }, [rows, veterinary_fees]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!rows || rows?.length == 0) return null;
  return (
    <TableContainer
      sx={{
        "& .MuiTable-root": {
          "& .MuiTableHead-root": {
            backgroundColor: "#FBFBFF",
            "& .MuiTableCell-root": {
              color: `${customPalette.main}`,
              fontWeight: "600!important",
            },
          },
          "& .MuiTableRow-root:last-child > .MuiTableCell-root": {
            border: "none!important",
          },
          "& .MuiTableCell-root": {
            color: `${customPalette.main}`,
          },
        },
        marginBottom: "16px",
      }}
    >
      <Table
        sx={{ minWidth: "636px", maxWidth: "636px" }}
        size="small"
        aria-label="Tabla servicios"
      >
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align="left">Porcentaje %</TableCell>
            <TableCell align="left">Monto</TableCell>
            {status != "finalizado" && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows?.map((row, index = 0) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row?.name || ' - '}</TableCell>
              <TableCell align="left">{row?.percentage ? `${row.percentage}%` : ' - '}</TableCell>
              <TableCell align="left">{`$${formatter.format((row.amount || 0))
                }`}</TableCell>
              {status != "finalizado" && <TableCell align="left">
                {" "}
                <Box>
                  <IconButton
                    sx={{
                      color: `${customPalette.main}`,
                      padding: 0,
                      marginRight: "8px",
                    }}
                    onClick={(e) => handleEdit(e, index)}
                    aria-label="Editar"
                  >
                    <Edit sx={{ width: '18px', height: '18px' }} />
                  </IconButton>
                  <IconButton
                    sx={{ color: `${customPalette.main}`, padding: 0 }}
                    onClick={(e) => handleConfirm(e, index)}
                    aria-label="Eliminar"
                  >
                    <DeleteIcon sx={{ width: '18px', height: '18px' }} />
                  </IconButton>
                </Box>
              </TableCell>}
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "18px",
                color: `${customPalette.main}`,
              }}
            >
              TOTAL
            </TableCell>
            <TableCell align="left" sx={{
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "18px",
              color: `${customPalette.main}`,
            }}>
              {`${getPercentage()}`}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "18px",
                color: `${customPalette.main}`,
              }}
            >{`$${formatter.format(acum)}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
