import { SimpleForm, Create, useCreate, useNotify } from "react-admin";
import { SimpleFields, validate } from "./create/create";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Transition } from "../../components/Transition";

export const PopUpCliente = ({ open, handleClose, handleCreated }) => {
  const [ create ] = useCreate();
  const notify = useNotify();


  const onSubmit = (values) =>{
    create('clients',{ data: values},{onSettled: (data, error) => {
        if (data?.id){
            handleClose();
            handleCreated(data.id);
            
            notify('Cliente creado con exito',{type:'info'});
        }
        if (error){
            notify('Error no se pudo crear el cliente',{type:'error'});
        }
    },});
 
  }

  return (
    <Dialog open={open} fullWidth sx={{"& .MuiPaper-root":{ maxHeight: 'calc(100% - 32px)'}}}  TransitionComponent={Transition}>
      <Box position="relative" width="100%" height="1px">
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            marginRight: "16px",
            position: "absolute",
            right: 0,
            top: "16px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 0 }}>
        <Create
          component="div"
          redirect={null}
          sx={{
            "& .RaCreate-noActions": {
              marginTop: 0,
            },
          }}
        >
          <SimpleForm validate={validate} onSubmit={onSubmit}>
            <SimpleFields />
          </SimpleForm>
        </Create>
      </DialogContent>
    </Dialog>
  );
};
