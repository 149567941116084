import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Button
} from "@mui/material";
import { customPalette } from "../../themes/customTheme";
import moment from 'moment';
import { Transition } from "../../components/Transition";

export const ShowEstablishment = ({
  open,
  handleClose,
  handleEdit,
  handleDelete,
  data,
  index,
  canEdit = false,
}) => {
  if (!data) return null;

  const onedit = (e) => {
    handleEdit(e, index);
  };

  const ondelete = (e) => {
    handleDelete(e, index);
  };
  return (
    <Dialog open={open}  TransitionComponent={Transition}>
      <DialogTitle sx={{ minWidth: "400px" }}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography
            sx={{
              color: `${customPalette.main}`,
              fontSize: "1.2em",
              fontWeight: 500,
            }}
          >
            {data.name} - {data.hectares ? `${data.hectares}Hc` : ""}
          </Typography>
          <Button
            onClick={handleClose}
            sx={{
              padding: "0px",
              marginTop: "4px",
              minWidth: "24px",
              height: "24px",
              "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 },
              "&:hover": { borderRadius: "50%" },
            }}
          >
            <Close
              sx={{
                color: `${customPalette.main}`,
                fontSize: "24px!important",
              }}
            />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ color: "#000", fontWeight: 500 }}>
          {data.location} - {data.distance ? `${data.distance} km` : ""}
        </Typography>
        <Box
          sx={{ display: "flex", boxSizing: "border-box", marginTop: "1em" }}
        >
          {data?.owner_name && (
            <Box width="48%" display="flex" flexDirection="column">
              <Typography sx={{ margin: ".5em 0" }}>Propietario</Typography>
              <Typography sx={{ margin: ".2em 0", fontWeight: 600 }}>
                {data?.owner_name}
              </Typography>
              <Typography sx={{ color: "#818181" }}>
                {data?.owner_email}
              </Typography>
              <Typography sx={{ color: "#818181" }}>
                {data?.owner_phone}
              </Typography>
              <Typography sx={{ color: "#818181" }}>
              {data?.owner_birth_date ? moment(new Date(data?.owner_birth_date)).locale('es-AR').format('yyyy-MM-DD').toLocaleString() : ''}
              </Typography>
            </Box>
          )}
          {data?.in_charge_name && (
            <Box
              width="48%"
              marginLeft={data?.owner_name ? '4%' : 0}
              display="flex"
              flexDirection="column"
            >
              <Typography sx={{ margin: ".5em 0" }}>Encargado</Typography>
              <Typography sx={{ margin: ".2em 0", fontWeight: 600 }}>
                {data?.in_charge_name}
              </Typography>
              <Typography sx={{ color: "#818181" }}>
                {data?.in_charge_email}
              </Typography>
              <Typography sx={{ color: "#818181" }}>
                {data?.in_charge_phone}
              </Typography>
              <Typography sx={{ color: "#818181" }}>
                {data?.in_charge_birth_date ? moment(new Date(data?.in_charge_birth_date)).locale('es-AR').format('yyyy-MM-DD').toLocaleString() : ''}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      {canEdit && (
        <DialogActions sx={{ padding: "0 24px 24px 24px" }}>
          <Button
            variant="outlined"
            size="medium"
            onClick={onedit}
            sx={{ "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 } }}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={ondelete}
            sx={{ marginLeft:'1em!important' ,"& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 } }}
          >
            Eliminar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
