import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
import React, { useRef, Fragment } from "react";
import PrintComponent from "./PrintComponentJob";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { dataProvider } from "../../providers/dataProvider";
import { useGetList } from "react-admin";

const PrintJob = () => {
  const componentRef = useRef();
  const formData = useWatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const [setting, setSetting] = useState();
  async function getSettings() {
    dataProvider.getSettings().then(({ data: res }) => setSetting(res));
  }
  const { data: pservices } = useGetList("professional-services", {sort: { field: "name"}});

  useEffect(() => {
    getSettings();
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Fragment>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="outlined"
            size="medium"
            onClick={(e) => {
              handleClick(e);
            }}
            sx={{
              "& .MuiButton-startIcon": { marginLeft: 0, marginRight: 0 },
            }}
          >
            Exportar
          </Button>
        )}
        content={() => componentRef.current}
      ></ReactToPrint>
      <div style={{ display: "none" }} >
        <PrintComponent
          data={formData}
          veterinarian={user}
          setting={setting}
          services={pservices}
          ref={componentRef}
        />
      </div>
    </Fragment>
  );
};

export default PrintJob;
