import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/logo.png';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  menuButton: {
    marginRight: '2em',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: '45px',
    maxWidth: "145px",
    marginBottom: 5,
    marginTop: 5,
  },
});

export default function AppBarOnly() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color='inherit'>
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
          <img src={logo} alt="logo" className={classes.logo} />
          <span className={classes.spacer} />
        </Toolbar>
      </AppBar>
    </div>
  );
}
