import { useRef, useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getElementDimensions } from "../../utils/utils";
import { Error, Loading, useDataProvider } from "react-admin";
import { useQuery } from "react-query";

const useStyles = makeStyles({
  card: {
    padding: "16px",
    boxSizing: "border-box",
    position: "relative",
    "& .recharts-legend-item-text": {
      color: "black!important",
    },
    "& .recharts-wrapper": {
      paddingTop: "18px",
    },
  },
  title: {
    margin: 0,
    fontWeight: 400,
    fontSize: "24px!important",
    lineHeight: "1.5!important",
    color: "#343434",
    marginBottom: "16px",
  },
});

const style = {
  top: 0,
  right: "60px",
  transform: "translate(0, -50%)",
  lineHeight: "24px",
  display: "flex",
  justifyContent: "flex-end",
};

export const LinearGraph = ({ keys, title }) => {
  const classes = useStyles();
  const targetRef = useRef();
  const [width, setWidth] = useState(450);
  const [rezise, setResize] = useState(false);
  const dataProvider = useDataProvider();
  const {
    data: elements,
    isLoading,
    error,
  } = useQuery(["getDashboard", { method: "priceshistory" }], () =>
    dataProvider.getDashboard("priceshistory")
  );

  useEffect(() => {
    // set width if window resizes
    const handleResize = () => {
      setResize(true);
      const { width } = getElementDimensions(targetRef);
      setWidth(width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!elements) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Card
        className={`${classes.card} `}
        ref={targetRef}
      >
        <Box mb=".5em" alignSelf="flex-start">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        {targetRef?.current != undefined && (
          <LineChart
            width={!rezise ? targetRef.current.clientWidth - 32 : width - 32}
            height={350}
            data={elements?.data}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Legend payload={keys} wrapperStyle={style} />
            {keys?.map((item, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={item.id}
                stroke={item.color}
                yAxisId={item.yAxisId}
              />
            ))}
          </LineChart>
        )}
      </Card>
    </ResponsiveContainer>
  );
};
