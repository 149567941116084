import { Add, Close, Edit, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  TextField as MUIText,
  Switch,
  FormControlLabel,
  
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useEffect, useState } from "react";
import {
  Labeled,
  useCreate,
  useUpdate,
  useStore,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";
import { customPalette } from "../../../themes/customTheme";

const useStyles = makeStyles({
  labeled:{
      marginBottom: "0!important",
      "&.MuiTypography-root": { marginBottom: "0!important" },
      "& .MuiTypography-root": { marginBottom: "0!important" },
    }
});

export const PopUpService = ({ isEdit = false }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const priceKG = useStore("priceKG");
  const [values, setValues] = useState();
  const [price, setPrice] = useState(priceKG[0] || 0);
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading: loadingcreate, error: errorcreate }] =
    useCreate();
  const [update, { isLoading: loadingedit, error: erroredit }] = useUpdate();
  const load = isEdit ? loadingedit : loadingcreate;
  const error = isEdit ? erroredit : errorcreate;

  useEffect(() => {
    if (isEdit) {
      setValues(record);
      setPrice(record?.price);
    } else {
      setValues({ price: priceKG[0] || 0 });
    }
  }, [record?.id, isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
  };

  const getPrice = (quantity) => {
    if (quantity && priceKG[0]) {
      return quantity * priceKG[0];
    } else {
      return priceKG[0] ? priceKG[0] : 0;
    }
  };

  const handleChangeQuantity = (value) => {
    let newobj = { ...values };
    newobj.quantity = value;
    newobj.price = getPrice(value);
    setPrice(newobj.price);
    setValues(newobj);
  };

  const handleChangeName = (value) => {
    let newobj = { ...values };
    newobj.name = value;
    setValues(newobj);
  };

  const handleChangeCheck = (value) => {
    console.log(value);
    let newobj = { ...values };
    newobj.only_price = value;
    setValues(newobj);
  };

  const handleChangePrice = (value) => {
    let newobj = { ...values };
    newobj.price = value;
    setValues(newobj);
    setPrice(value);
  };

  const handleSave = () => {
    if (price > 0) {
      if ( values?.name &&  ((values?.only_price && price) || (!values?.only_price && values?.quantity))) {
        if (!isEdit) {
          create(
            "professional-services",
            { data: {...values, price: price}},
            { onSettled: () => refresh() }
          );
        } else {
          update(
            "professional-services",
            { id: record.id, data: {...values, price: price}, previousData: record },
            { onSettled: () => refresh() }
          );
        }
        handleClose();
        setValues({ price: priceKG[0] || 0 });
      } else {
        notify("Complete los campos requeridos", { type: "error" });
      }
    } else {
      notify(
        "El precio no puede estar vacio para crear el servicio",
        { type: "error" }
      );
    }
  };

  useEffect(() => {
    if (error) {
      let text = isEdit
        ? "Error al editar el servicio"
        : "Error al intentar crear el servicio";
      notify(text, { type: "error" });
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Button
        onClick={() => setOpen(true)}
        label=""
        variant={isEdit ? "" : "contained"}
        disabled={load}
        sx={
          isEdit
            ? { background: "none", color: `${customPalette.main}` }
            : { fontWeight: 500, minWidth: "180px" }
        }
        startIcon={
          isEdit ? <Edit sx={{ color: `${customPalette.main}` }} /> : <Add />
        }
      >
        {isEdit ? "" : "Nuevo Servicio"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ padding: "24px 24px 16px 24px" }}>
          {isEdit ? "Editar Servicio" : "Nuevo Servicio"}{" "}
        </DialogTitle>
        <DialogContent sx={{ padding: "0 24px 32px 24px" }}>
          <MUIText
            label="Nombre*"
            source="name"
            variant="filled"
            size="small"
            value={values?.name}
            onChange={(e) => handleChangeName(e.target.value)}
            sx={{ minWidth: "340px", marginBottom: "1em" }}
          />
          <Box mb=".5em">
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => handleChangeCheck(e.target.checked)}
                  value={values?.only_price}
                />
              }
              label="Precio fijo"
            />
          </Box>
          {!values?.only_price ? (
            <Box display="flex">
              <Box>
                <MUIText
                  label="Precio segun KG/carne*"
                  source="quantity"
                  type="number"
                  size="small"
                  error={values?.quantity < 0 ? true : null}
                  helperText={
                    values?.quantity < 1 && !undefined && !null
                      ? "La cantidad debe ser mayor a 0"
                      : null
                  }
                  value={values?.quantity}
                  onChange={(e) => handleChangeQuantity(e.target.value)}
                  variant="filled"
                  sx={{ maxWidth: "200px", marginBottom: 0 }}
                />
              </Box>
              <Box ml="1em">
                <Labeled
                  label="Precio final"
                  className={classes.labeled}
                >
                  <Typography
                    variant="h1"
                    componente="span"
                    sx={{
                      fontSize: "1.5em",
                      color: "black",
                      marginBottom: "0!important",
                    }}
                  >
                    ${Number(price)?.toFixed(2)}
                  </Typography>
                </Labeled>
              </Box>
            </Box>
          ) : (
            <MUIText
              label="Precio*"
              source="price"
              type="number"
              size="small"
              error={values?.price < 0 ? true : null}
              helperText={
                values?.quantity < 1 && !undefined && !null
                  ? "El precio debe ser mayor a 0"
                  : null
              }
              value={values?.quantity}
              onChange={(e) => handleChangePrice(e.target.value)}
              variant="filled"
              sx={{ maxWidth: "200px" }}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end;",
            padding: "0 24px 24px 24px",
          }}
        >
          <Button
            onClick={handleClose}
            size="medium"
            startIcon={<Close />}
            sx={{ marginRight: "1em" }}
          >
            CANCELAR
          </Button>
          <Button
            onClick={handleSave}
            disabled={load}
            variant="contained"
            size="medium"
            startIcon={<Save />}
          >
            {isEdit ? "Guardar" : "CREAR SERVICIO"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
