import React, { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Welcome from "./Welcome";
import CardWithIcon from "./CardWithIcon";
import { hasPermission } from "../../utils/permissions";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { formatter } from "../../utils/NumberFormatter";
import { dataProvider } from "../../providers/dataProvider";
import { BirthDayList } from "./BirthDayList";
import { CircularGraph } from "./CircularGraph";
import { BarGraph } from "./BarGraph";
import { LinearGraph } from "./LinearGraph";
import { AnimalCard } from "./AnimalCard";
// import { JobList } from "./JobList";
import { ClientList } from "./ClientList";
//import ScaleIcon from "@mui/icons-material/Scale";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  payment: { gridArea: "1 / 1 / 2 / 2" },
  animal: { gridArea: "1 / 2 / 2 / 2" },
  /* kg: { gridArea: "1 / 3 / 1 / 4" }, */
  client: { gridArea: "1 / 4 / 3 / 3" },
  graph: { gridArea: "2 / 1 / 2 / 2" },
  barGraph: { gridArea: "2 / 2 / 3 / 3" },
  linearGraph: { gridArea: "3 / 2 / 3 / 4", display: "flex" },
  jobs: { gridArea: "3 / 1 / 3 / 2" },
  grid: {
    display: "grid",
    gridGap: "1.5em",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "0.25fr  1fr)",
  },
};

const Spacer = () => <span style={{ width: "1em" }} />;

export const Dashboard = () => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [data, setData] = useState();

  const getData = () => {
    dataProvider.getDashboard("").then(({ data: res }) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const keys = [
    {
      id: "kg",
      color: "#2AB572",
      value: "Trabajos cobrados expresados en KG",
      yAxisId: "left",
    }
  ];

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <Welcome />
        {hasPermission("list-dashboard") && (
          <Fragment>
            <div style={styles.singleCol}>
              <CardWithIcon
                to="/"
                icon={RequestQuoteIcon}
                title={"SALDO POR COBRAR"}
                subtitle={`$${formatter.format(data?.total || 0)}`}
              />
              <Spacer />
            </div>
            <div style={styles.singleCol}>
              <AnimalCard />
              <Spacer />
            </div>
            {/* <div style={styles.singleCol}>
              <CardWithIcon
                to="/"
                icon={ScaleIcon}
                title={"KG DEL MES"}
                subtitle={`${data?.kgs || 0} kg`}
              />
              <Spacer />
            </div> */}
            <div style={styles.singleCol}>
              <ClientList />
              <Spacer />
            </div>
            <div style={styles.singleCol}>
              <CircularGraph />
              <Spacer />
            </div>
            <div style={styles.singleCol}>
              <BarGraph />
              <Spacer />
            </div>
            <div style={styles.singleCol}>
              <LinearGraph
                title={"Historial de cobros"}
                keys={keys}
              />
              <Spacer />
            </div>
            <div style={styles.singleCol}>
              <BarGraph />
              <Spacer />
            </div>
            <div style={styles.singleCol}>
              <BirthDayList />
              <Spacer />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      {hasPermission("list-dashboard") && (
        <Fragment>
          <div style={styles.singleCol}>
            <CardWithIcon
              to="/"
              icon={RequestQuoteIcon}
              title={"SALDO POR COBRAR"}
              subtitle={`$${formatter.format(data?.total || 0)}`}
            />
            <Spacer />
          </div>
          <div style={styles.singleCol}>
            <AnimalCard />
            <Spacer />
          </div>
          {/* <div style={styles.singleCol}>
            <CardWithIcon
              to="/"
              icon={ScaleIcon}
              title={"KG DEL MES"}
              subtitle={`${data?.kgs || 0} kg`}
            />
            <Spacer />
          </div> */}
          <div style={styles.singleCol}>
            <ClientList />
            <Spacer />
          </div>
          <div style={styles.singleCol}>
            <CircularGraph />
            <Spacer />
          </div>
          <div style={styles.singleCol}>
            <BarGraph />
            <Spacer />
          </div>
          <div style={styles.singleCol}>
            <LinearGraph
              title={"Historial de cobros"}
              keys={keys}
            />
            <Spacer />
          </div>
          <div style={styles.singleCol}>
            <BarGraph />
            <Spacer />
          </div>
          <div style={styles.singleCol}>
            <BirthDayList />
            <Spacer />
          </div>
        </Fragment>
      )}
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.grid}>
        {hasPermission("view-dashboard") && (
          <Fragment>
            <div style={styles.payment}>
              <CardWithIcon
                to="/"
                icon={RequestQuoteIcon}
                title={"SALDO POR COBRAR"}
                subtitle={`$${formatter.format(data?.total || 0)}`}
              />
            </div>
            <div style={styles.animal}>
              <AnimalCard />
            </div>
            {/* <div style={styles.kg}>
              <CardWithIcon
                to="/"
                icon={ScaleIcon}
                title={"KG DEL MES"}
                subtitle={`${data?.kgs || 0} kg`}
              />
            </div> */}
            <div style={styles.client}>
              <ClientList />
            </div>
            <div style={styles.graph}>
              <CircularGraph />
            </div>
            <div style={styles.barGraph}>
              <BarGraph />
            </div>
            <div style={styles.linearGraph}>
              <LinearGraph
                title={"Historial de cobros"}
                keys={keys}
              />
            </div>
            <div style={styles.jobs}>
              <BirthDayList />
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};
