import { Chip, Typography } from "@mui/material";
import {
  Datagrid,
  List,
  TextInput,
  SelectInput,
  DateField,
  useRecordContext,
  NumberField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Status } from "../Status";
import { STATUS } from "../../constants";
import { ActionsCreate } from "../../../components/ActionsCreate";
import DatePickerBetween from "../../../components/DatePickerBetween";
import { makeStyles } from "@mui/styles";
import { CustomPagination } from "../../../components/Pagination";
import { ConfirmDeleteButton } from "../../../components/ConfirmDeleteButton";
import { useEffect, useState } from "react";
import { dataProvider } from "../../../providers/dataProvider";

const useStyles = makeStyles({
  customAlign: {
    "& .MuiTableCell-root": {
      textAlign: "left",
    },
  },
});

const FilterSelectService = () => {
  const [data, setData] = useState();

  const getData = async () => {
    let res = await dataProvider.getProfesionalServices();
    if (res?.data) {
      setData(res?.data);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data) return null;
  return (
    <SelectInput
      source="professional_service_id"
      label="Servicio"
      emptyText="Ningún"
      choices={data}
      size="small"
    />
  );
};

const filters = [
  <TextInput label="Buscar por cliente" source="search-custom" alwaysOn resettable />,
  <DatePickerBetween label="Fechas" alwaysOn  />,
  <FilterSelectService  source="professional_service_id"  alwaysOn />,
  <SelectInput
    label="Estado"
    source="status"
    choices={STATUS}
    emptyText="Sin estado"
  />,
  <BooleanInput source="not_payd" label="No cobrado" />,
  <ReferenceInput
    source="client_id"
    label="Nombre y Apellido"
    reference="clients"
    filter={{ with_trashed: true }}
  >
    <AutocompleteInput
      optionText="name"
      label="Nombre y Apellido"
      filterToQuery={q => ({"qname": q})}
      sx={{ minWidth: "220px","& .MuiInputBase-root":{maxHeight: "48px", paddingTop:"14px", fontSize:"0.9em"} }}
    />
  </ReferenceInput>,
   <BooleanInput label="Eliminado" source="with_trashed" defaultValue={true} />
];

const CustomClient = () => {
  const { establishment, client } = useRecordContext();
  return (
    <Typography
      component="span"
      variant="body2"
    >{`${establishment?.name}/${client?.name}`}</Typography>
  );
};

const getServices = (services) => {
  let arr = [];
  services?.forEach((element) => {
    arr.push(element?.name);
  });
  let unique = [...new Set(arr.map((data) => data))];
  return unique;
};

const Services = () => {
  const { service_names } = useRecordContext();
  const data = service_names?.length > 0 ? getServices(service_names) : null;
  if (!data || data?.length == 0) return null;
  return (
    <Typography component="span" variant="body2">
      {data[0]}{" "}
      {data?.length > 1 && (
        <Chip
          size="small"
          label={`+${data.length - 1}`}
          sx={{ borderRadius: "5px", color: "black", background: "#F0F0F0" }}
        />
      )}
    </Typography>
  );
};

export const JobsList = () => {
  const classes = useStyles();
  return (
    <List
      title=" "
      filters={filters}
      empty={false}
      actions={<ActionsCreate label="Nuevo Trabajo" filter={true} exportExcel={true} />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<CustomPagination />}
    >
      <Datagrid
        className={classes.customAlign}
        bulkActionButtons={false}
        rowClick="edit"
      >
        <DateField source="created_at" label="Fecha" locales={"es-AR"} />
        <CustomClient label="Cliente" />
        <Services label="Servicios" />
        <NumberField
          source="total"
          label="Total"
          locales={"es-AR"}
          options={{
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          source="paid_amount"
          label="Cobrado"
          locales={"es-AR"}
          options={{
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
        <Status label="Estado" />
        <ConfirmDeleteButton resource={"jobs"} element="el trabajo" />
      </Datagrid>
    </List>
  );
};
