export const ANIMALS = [
    { id: "vacas", name: "Vacas cria" },
    { id: "vaquillona", name: "Vaquillonas" },
    { id: "toros", name: "Toros" },
    { id: "recria", name: "Recría" },
    { id: "invernada", name: "Invernada" },
    { id: "feedlot", name: "Feedlot" },
    { id: "tambo", name: "Tambo" },
    { id: "varios", name: "Varios" },
  ];

  export const PAYMENTMETHOD = [
    {id:'ef',name: 'Efectivo'},
    {id:'ch',name:'Cheque'},
    {id:'che', name:'Cheque Electrónico'},
    {id:'tr', name:'Transferencia'},
];
  
export const STATUS =[
  {id: 'en_proceso', name:'En proceso'},
  {id: 'no_cobrado', name:'No Cobrado'},
  {id: 'finalizado', name:'Finalizado'},
  {id: 'cobrado_parcialmente', name:'Cobro parcial'},
  {id: 'vencido', name:'Vencido'},
]

export const FISCALCONDITION = [
  { id: "ri", name: "Responsable Inscripto" },
  { id: "m", name: "Monotributista" },
  { id: "a", name: "Autónomo" },
];

export const DUE_DATES = [
  { id: 15, name: '15 días' },
  { id: 30, name: '30 días' },
  { id: 45, name: '45 días' },
  { id: 60, name: '60 días' },
  { id: 90, name: '90 días' },
];
