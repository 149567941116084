import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  useNotify,
  useRedirect,
  required,
  email,
} from "react-admin";
import { hasPermission } from "../../../utils/permissions";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import { CustomToolbar } from "../../../components/CustomToolbar";
import {
  validateCUITCUIL,
} from "../../../utils/Validations";

const useStyles = makeStyles({
  input: {
    maxWidth: "240px",
  },
  password: {
    maxWidth: "260px",
  },
  inputLong: {
    maxWidth: "350px",
  },
  create: {
    "& .RaCreate-main.RaCreate-noActions": {
      marginTop: 0,
    },
  },
});

export const UserCreate = () => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.resources.users.create");
    redirect("/users");
  };

  // const onError = (error) => {
  //   console.log(error);
  //   // let resp = "";
  //   // data?.forEach((item) => {
  //   //   let aux = Object.values(item).map((i) => i + ", ");
  //   //   resp += aux;
  //   // });
  //   // notify(resp);
  // };
  //

  const cuilCuitErrorMessage = "El cuil/cuit ingresado no es correcto";

  const validate = (values) => {
    let errors = {settings:{}};
    if (!values?.name) {
      errors.name = "El nombre es requerido";
    }
    if (!values?.email && !email(values.email)) {
      errors.email = "El email es requerido";
    }
    if (values?.settings?.cuil_cuit) {
      if (values?.settings?.cuil_cuit?.length < 11) 
        errors.settings.cuil_cuit = "Los códigos deben tener al menos 11 dígitos";
      else if (!validateCUITCUIL(values.settings.cuil_cuit)) {
        errors.settings.cuil_cuit = cuilCuitErrorMessage;
      }
    }
    if (!values?.password) {
      errors.password = "La contraseña es requerida";
    }
    if (values?.password != values?.password_confirmation) {
      errors.password_confirmation = "Las contraseñas no coinciden";
    }
    if (values?.password && values?.password?.length  < 6) {
      errors.password = "La contraseña debe tener al menos 6 digitos";
    }
    if (hasPermission("edit-roles") && !values?.roles) {
      errors.roles = "El rol es requerido";
    }
    return errors;
  };

  return (
    <Create title=" " redirect="list" mutationOptions={{ onSuccess }}>
      <SimpleForm
        redirect="list"
        variant="filled"
        margin="dense"
        toolbar={<CustomToolbar />}
        validate={validate}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#343434",
            fontSize: "32px",
            lineHeight: "30px",
            marginBottom: "32px",
            fontWeight: 400,
          }}
        >
          Nuevo Usuario
        </Typography>
        <Box display="flex" flexDirection="column">
          <TextInput
            source="name"
            label="Nombre y Apellido"
            variant="filled"
            validate={required()}
            className={classes.inputLong}
          />
          <TextInput
            source="settings.cuil_cuit"
            label="CUIL/CUIT"
            className={classes.input}
            variant="filled"
            helperText={"Solo números"}
          />
          {hasPermission("edit-roles") && (
            <ReferenceInput label="Rol" source="roles" reference="roles">
              <SelectInput
                optionText="title"
                label="Rol"
                variant="filled"
                validate={required()}
                sx={{ marginTop: 0, width: "200px" }}
              />
            </ReferenceInput>
          )}
          <Typography
            sx={{
              color: "#343434",
              fontSize: "24px",
              marginTop: "12px",
              marginBottom: "16px",
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            Contacto
          </Typography>

          <TextInput
            source="phone"
            label="Teléfono"
            variant="filled"
            className={classes.input}
          />
          <Box display="flex">
            <TextInput
              source="settings.address_name"
              label="Calle"
              variant="filled"
              sx={{ marginRight: "2em" }}
              className={classes.input}
            />
            <TextInput
              source="settings.address_number"
              label="N°"
              variant="filled"
              sx={{ marginRight: "2em", maxWidth: "100px" }}
            />
            <TextInput
              source="settings.address_floor"
              label="Piso"
              variant="filled"
              sx={{ marginRight: "2em", maxWidth: "80px" }}
            />
            <TextInput
              source="settings.address_door"
              label="Dpto."
              variant="filled"
              sx={{ maxWidth: "80px" }}
            />
          </Box>
          <TextInput
            source="settings.location"
            label="Localidad"
            variant="filled"
            className={classes.inputLong}
          />
        </Box>
        <Typography
          sx={{
            color: "#343434",
            fontSize: "24px",
            marginTop: "12px",
            marginBottom: "16px",
            fontWeight: 500,
            lineHeight: "30px",
          }}
        >
          Inicio de sesión
        </Typography>
        <TextInput
          source="email"
          label="Email"
          variant="filled"
          validate={required()}
          className={classes.input}
        />
        <PasswordInput
          source="password"
          label="Contraseña"
          variant="filled"
          className={classes.password}
          validate={required()}
        />
        <PasswordInput
          source="password_confirmation"
          label="Repetir contraseña"
          variant="filled"
          className={classes.password}
        />
      </SimpleForm>
    </Create>
  );
};
