import { Fragment } from "react";
import { CustomAddButton } from "./CustomAddButton";
import { CustomDeleteButton } from "./CustomDeleteButton";
import { ArrayInput, SimpleFormIterator } from "react-admin";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  iterator: {
    "& .RaSimpleFormIterator-indexContainer": {
      display: "none!important",
    },
    "& .RaSimpleFormIterator-action": {
      visibility: "visible!important",
      marginTop: "0!important",
      "& > not('.MuiButton-root')":{
        paddingTop: "0!important",
      },
    },
    "& .RaSimpleFormIterator-line .RaSimpleFormIterator-action":{
      marginLeft: '1em',
    },
    "& .RaSimpleFormIterator-line": {
      border: "none!important",
      padding: "0",
    },
  },
});

export const CustomArrayInput = ({
  title,
  source,
  addButtonText,
  children,
  deleteColor,
  maxWidth = "600px",
  deleteWithText = false,
  classTitle
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Typography
        variant="h2"
        className={classTitle ? classTitle : ''}
        sx={ !classTitle ? { fontWeight: 500, fontSize: "22px", lineHeight: "30px" } : {}}
      >
        {title}
      </Typography>
      <ArrayInput
        source={source}
        style={{
          maxWidth: maxWidth,
          marginTop: 0,
          marginBottom:0,
          "& label": { display: "none" },
        }}
        label=""
      >
        <SimpleFormIterator
          className={classes.iterator}
          disableReordering={true}
          disableClear={true}
          getItemLabel={(index) => ""}
          addButton={<CustomAddButton label={addButtonText} />}
          removeButton={
            <CustomDeleteButton
              sx={{ alignItems: "center" }}
              color={deleteColor}
              withText={deleteWithText}
            />
          }
        >
          {children}
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  );
};
