import { Resource } from "react-admin";
import { UserList, UserCreate, UserEdit } from "../resources/users";
import { hasPermission } from "../utils/permissions";
import { NonPermission } from "../components/NonPermission";
import { RoleCreate, RoleEdit, RoleList } from "../resources/roles";
import {
  ClientCreate,
  ClientEdit,
  ClientList,
  ClientShow,
} from "../resources/clients";
import { ProfessionalList } from "../resources/professional-services";
import { SettingsShow } from "../resources/settings";
import { JobsList, JobsCreate, JobsEdit } from "../resources/jobs";
import { PaymentList, PaymentCreate } from "../resources/payments";

export const Resources = () => [
  <Resource
    name="users"
    list={hasPermission("list-users") ? UserList : null}
    create={hasPermission("create-users") ? UserCreate : NonPermission}
    edit={hasPermission("edit-users") ? UserEdit : NonPermission}
  />,
  <Resource
    name="roles"
    list={hasPermission("list-roles") ? RoleList : null}
    create={hasPermission("create-roles") ? RoleCreate : NonPermission}
    edit={hasPermission("edit-roles") ? RoleEdit : NonPermission}
  />,
  <Resource name="abilities" />,
  <Resource name="settings" list={SettingsShow} />,
  <Resource
    name="jobs"
    list={hasPermission("list-jobs") ? JobsList : NonPermission}
    create={hasPermission("create-jobs") ? JobsCreate : NonPermission}
    edit={hasPermission("edit-jobs") ? JobsEdit : NonPermission}
  />,
  <Resource name="payments" 
    list={hasPermission('list-payments') ? PaymentList : NonPermission} 
    create={hasPermission('create-payments') ? PaymentCreate : NonPermission} />,
  <Resource name="services" />,
  <Resource name="animal-prices" />,
  <Resource name="jobpayment" />, 
  <Resource
    name="professional-services"
    list={
      hasPermission("list-professional-services")
        ? ProfessionalList
        : NonPermission
    }
  />,
  <Resource
    name="clients"
    list={hasPermission("list-clients") ? ClientList : null}
    create={hasPermission("create-clients") ? ClientCreate : NonPermission}
    edit={hasPermission("edit-clients") ? ClientEdit : NonPermission}
    show={ClientShow}
  />,
];

export default Resources;
