import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { ActionsCreate } from "../../../components/ActionsCreate";
import { makeStyles } from "@mui/styles";
import { CustomPagination } from "../../../components/Pagination";

const useStyles = makeStyles({
  customAlign: {
    "& .MuiTableCell-root": {
      textAlign: "left",
    },
    "& .MuiTableCell-root:last-child": {
      textAlign: "right",
    },
  },
});

const roleFilters = [
    <TextInput label="Buscar" source="search" alwaysOn />,
    <TextInput label="Nombre" source="title" />,
  ];

export const RoleList = (props) => {
  const classes = useStyles();
  return (
    <List
      title=" "
      filters={roleFilters}
      empty={false}
      {...props}
      sort={{ field: "title", order: "ASC" }}
      actions={<ActionsCreate label="Nuevo Rol"/>}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="edit" className={classes.customAlign} bulkActionButtons={false}>
        <TextField source="title" label="Nombre"/>
        <TextField source="users_count" label="Cantidad Usuarios" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
