import { Typography, Box, Tooltip } from "@mui/material";
import { Fragment, useState } from "react";
import {
  Create,
  DateInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  Labeled,
  useGetOne,
} from "react-admin";
import { CustomToolbar } from "../../../components/CustomToolbar";
import { PAYMENTMETHOD } from "../../constants";
import { useWatch } from "react-hook-form";
import { useEffect } from "react";
import { dataProvider } from "../../../providers/dataProvider";
import { TableJob } from "./TableJob";
import { formatter } from "../../../utils/NumberFormatter";
import { isValidDate, validateNumbers, validateRequired, validateDate } from "../../../utils/Validations";
import { useLocation } from "react-router-dom";
import { ErrorJob } from "../ErrorJob";
import moment from "moment";
import HelpIcon from "@mui/icons-material/Help";
import CustomNumberInput from "../../../components/CustomNumberInput";

const CustomerDetail = ({ client_id }) => {
  const [data, setData] = useState();
  const { data: client } = useGetOne("clients", { id: client_id });

  async function getData() {
    dataProvider
      .getJobsPending(client_id)
      .then(({ data: res }) => setData(res));
  }
  useEffect(() => {
    if (client_id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id]);
  if (!data) return null;
  return (
    <Fragment>
      <Box
        sx={{
          border: "1px solid #BFBFBF",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          boxSizing: "border-box",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" marginBottom="22px">
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "30px",
                textTransform: "capitalize",
              }}
            >
              {client?.name}
            </Typography>
          </Box>
          <Box display="flex">
            {client?.paid_unused > 0 && (
              <Box mr="16px">
                <Labeled label="SALDO">
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      lineHeight: "20px",
                    }}
                  >
                    {client?.paid_unused ? "$" : ""}
                    {formatter.format(client?.paid_unused || 0)}
                  </Typography>
                </Labeled>
              </Box>
            )}
            <Labeled label="ESTADO ACTUAL">
              <Typography
                sx={{
                  color:
                    client?.current_account > 0
                      ? "#FF0000"
                      : client?.current_account == 0
                      ? "#000000"
                      : "#0d8708",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                }}
              >
                {client?.current_account ? "$" : ""}
                {formatter.format(client?.current_account || 0)}
              </Typography>
            </Labeled>
            <Tooltip title="Los trabajos deben estar cerrados para poder ser abonados, sino solo se generará el cobro">
              <HelpIcon />
            </Tooltip>
          </Box>
        </Box>
        {data && data?.length > 0 && (
          <Typography
            sx={{
              color: "#232323",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "20px",
              marginBottom: "12px",
            }}
          >
            Trabajos pendientes de cobro
          </Typography>
        )}
        <TableJob rows={data} />
      </Box>
      <ErrorJob />
    </Fragment>
  );
};

const CustomerSelect = () => {
  const client_id = useWatch({ name: "client_id" });
  return (
    <Fragment>
      <ReferenceInput
        source="client_id" 
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="name"
          label="Nombre y Apellido"
          filterToQuery={q => ({"qname": q})}
          sx={{
            minWidth: "556px",
            "& .RaAutocompleteInput-textField": {
              marginTop: 0,
              marginBottom: 0,
            },
          }}
          validate={validateRequired}
        />
      </ReferenceInput>
      {client_id && <CustomerDetail client_id={client_id} />}
    </Fragment>
  );
};

const Fields = () => {
  const today = moment(new Date()).format("YYYY-MM-DD");
  const payment_method = useWatch({ name: "payment_method" });
  return (
    <Fragment>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "32px",
          lineHeight: "30px",
          marginBottom: "32px",
        }}
      >
        Nuevo cobro
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "30px",
          marginBottom: "16px",
        }}
      >
        Cliente
      </Typography>
      <CustomerSelect />

      <Box display="flex" flexDirection="column">
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "30px",
            marginBottom: "16px",
            marginTop: "32px",
          }}
        >
          Detalle de cobro
        </Typography>

        <CustomNumberInput
          source="amount"
          label="TOTAL A PAGAR"
          validate={validateRequired}
        />
        <DateInput
          source="date"
          label="Fecha de cobro"
          defaultValue={today}
          locale={"es-ar"}
          validate={validateRequired}
          sx={{ marginTop: "16px", width: "160px" }}
        />
        <SelectInput
          source="payment_method"
          label="Forma de pago"
          validate={validateRequired}
          choices={PAYMENTMETHOD}
          sx={{ width: "260px" }}
        />
        {(payment_method == "ch" || payment_method == "che") && (
          <Box display="flex">
            <CustomNumberInput
              source="receipt_number"
              label="N° Cheque"
              validate={validateNumbers }
              sx={{ width: "220px" }}
            />
            <DateInput
              source="payment_date"
              label="Fecha cheque diferido"
              validate={validateDate}
              locale={"es-ar"}
              sx={{ marginLeft: "24px", width: "200px" }}
            />
          </Box>
        )}
        <TextInput
          multiline
          rows={4}
          variant="filled"
          sx={{
            width: "520px",
            "& .MuiFilledInput-root": { padding: "16px!important" },
          }}
          source="detail"
          label="Detalles"
        />
      </Box>
    </Fragment>
  );
};
export const validate = (values) => {
  const errors = {};
  if (!values?.amount) {
    errors.amount = "Ingrese monto a pagar";
  }

  if (!values?.client_id) {
    errors.client_id = "El cliente es requerido";
  }
  if (!values?.payment_method) {
    errors.payment_method = "Seleccione método de cobro";
  }
  if (!values?.date) {
    errors.date = "Fecha de cobro es requerida";
  }
  if (values.date) {
    if (!isValidDate(values.date)) {
      errors.date = "La fecha inválida";
    }
  }
  if (values?.detail?.length > 255) {
    errors.detail = "La observación es demasiado larga máximo 255 caracteres";
  }
  if (values?.payment_method == "ch" || values?.payment_method == "che") {
    if (values.payment_date) {
      if (!isValidDate(values.payment_date)) {
        errors.payment_date = "La fecha inválida";
      }
    }
  }
  return errors;
};

export const PaymentCreate = () => {
  const { state } = useLocation();

  return (
    <Create title=" " redirect={null}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        defaultValues={state ? state : undefined}
        validate={validate}
      >
        <Fields />
      </SimpleForm>
    </Create>
  );
};
